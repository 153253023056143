import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import api from '../../config/api';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import CreateAStreamingRequestImage from '../../assets/images/getStartingPage/CreateAStreamingRequestImage';
import MessageBox from './MessageBox';
import { nanoid } from 'nanoid';

const getMessages = async () => {
  const { data } = await api.get(`/api/message?page=1&size=5`);
  return data;
};

const MessagesPage = () => {
  const { data: messages, isLoading } = useQuery({ queryKey: ['messages'], queryFn: getMessages });
  if (isLoading) return <Loading />;
  return (
    <Box>
      <TopNavigation title={'Messages'} />
      <TopNavigationSpace />

      <Stack mt={2.5} gap={2}>
        {messages.data.length === 0 && (
          <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <CreateAStreamingRequestImage />
            <Typography variant='h5_dark'>No Messages</Typography>
          </Stack>
        )}
        {messages.data.map((message) => (
          <MessageBox message={message} key={nanoid()} />
        ))}
      </Stack>
    </Box>
  );
};

export default MessagesPage;
