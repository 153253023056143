import * as React from 'react';

function EyeIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.268 8.167A2.057 2.057 0 014.912 7c0-1.155.933-2.088 2.088-2.088S9.09 5.845 9.09 7A2.086 2.086 0 017 9.088"
        stroke="#fff"
        strokeWidth={0.875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.243 3.255c-.986-.7-2.094-1.08-3.243-1.08-2.06 0-3.978 1.214-5.314 3.314-.525.823-.525 2.205 0 3.028C3.022 10.617 4.94 11.83 7 11.83c2.06 0 3.978-1.213 5.314-3.313.525-.823.525-2.205 0-3.028"
        stroke="#fff"
        strokeWidth={0.875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
