import * as React from 'react';

const WarningIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={64} height={64} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M56.213 22.88v18.24c0 2.987-1.6 5.76-4.187 7.28l-15.84 9.147a8.44 8.44 0 0 1-8.4 0L11.947 48.4a8.4 8.4 0 0 1-4.186-7.28V22.88c0-2.986 1.6-5.76 4.187-7.28l15.84-9.146a8.44 8.44 0 0 1 8.4 0l15.84 9.146c2.586 1.52 4.186 4.267 4.186 7.28'
      opacity={0.4}
    />
    <path fill='#111' d='M32 36.667c-1.093 0-2-.907-2-2v-14c0-1.094.907-2 2-2s2 .906 2 2v14c0 1.093-.907 2-2 2' />
    <path
      fill='#292D32'
      d='M32 46c-.347 0-.693-.08-1.013-.214a2.8 2.8 0 0 1-.88-.56 4 4 0 0 1-.587-.88 2.6 2.6 0 0 1-.187-1.013c0-.693.267-1.387.774-1.893.266-.24.533-.427.88-.56a2.66 2.66 0 0 1 2.906.56c.24.266.427.533.56.88.134.32.214.666.214 1.013s-.08.694-.214 1.014q-.2.48-.56.88A2.64 2.64 0 0 1 32 46'
    />
  </svg>
);
export default WarningIcon;
