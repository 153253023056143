import React from 'react';
import { Avatar, Box, Button, List, ListItemButton, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import useProfile from '../../hooks/useProfile';
import Loading from '../../components/Loading/Loading';
import RightArrowBrokenIcon from '../../assets/icons/RightArrowBrokenIcon';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import api from '../../config/api';
import { toast } from 'react-toastify';
import getInitials from '../../utils/getInitials';
import EditProfileImageDrawer from './EditProfileImageDrawer';
import resizeImage from '../../utils/resizeImage';
import ReactGA from 'react-ga4';
import { MY_REQUEST_TYPE, MY_TASK_TYPE } from '../../utils/constant';

// const Tag = ({ tag }) => {
//   return (
//     <Box
//       sx={{
//         backgroundColor: '#0000000D',
//         color: '#191919CC',
//         borderRadius: 0.5,
//         p: '4px 8px',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       <Typography variant='caption'>{tag}</Typography>
//     </Box>
//   );
// };

const CustomListItem = ({ title, onClick }) => {
  return (
    <ListItemButton
      sx={{
        p: '0',
        mb: 3,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Typography variant='h6_semibold' sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <RightArrowBrokenIcon />
      </Box>
    </ListItemButton>
  );
};

const ProfilePage = () => {
  const [openEditProfileDrawer, setOpenEditProfileDrawer] = React.useState(false);
  const { data: profile, isLoading } = useProfile();
  const [profileImage, setProfileImage] = React.useState(null);
  ReactGA.send({ hitType: 'pageview', page: '/profile', title: 'Profile Page' });

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

  const handleCloseImagePreview = () => {
    setProfileImage(null);
  };
  const submitProfileImage = async () => {
    try {
      const formData = new FormData();
      formData.append('file', profileImage?.file);
      await api.post('/api/user/profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Profile image updated successfully');
      handleCloseImagePreview();
      window.location.reload();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate(ROUTES.LOGIN);
  };
  const handleImageSelect = async (e) => {
    let allImages = true;
    Object.values(e.target.files).forEach((file) => {
      if (!file.type.includes('image')) {
        allImages = false;
      }
    });
    if (!allImages) {
      toast.error(`Invalid file format`);
      return;
    }
    if (e.target.files[0]) {
      const image = await resizeImage(e.target.files[0]);
      setProfileImage({
        file: image,
        img: URL.createObjectURL(e.target.files[0]),
      });
    }
  };
  const handleSelectImageClick = () => {
    document.getElementById('fileInput').click();
    // setOpenEditProfileDrawer(false);
  };
  const handleProfileIconClick = () => {
    setOpenEditProfileDrawer(true);
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Box sx={{ position: 'relative', pb: 8 }}>
        <TopNavigation />
        <TopNavigationSpace />
        <Stack spacing={1} pb={0.5}>
          <Box
            sx={{
              py: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Avatar
                src={`${S3_BUCKET}/${profile?.data?.profile?.avatarUrl}`}
                sx={{ width: 64, height: 64, cursor: 'pointer', fontSize: '1.5rem', fontWeight: '600' }}
                onClick={handleProfileIconClick}
              >
                {getInitials(profile.data.name)}
              </Avatar>
              <input id='fileInput' type='file' hidden onChange={(e) => handleImageSelect(e)} accept='image/*' />
            </Box>
            <Stack>
              <Typography variant='h5_bold'>{profile?.data?.name}</Typography>
              <Typography variant='h6_semibold' sx={{ color: '#11111180' }}>
                {profile?.data?.profile?.streamCount} Videos Created
              </Typography>
            </Stack>
          </Box>
          {profile?.data?.profile?.description && <Typography>{profile?.data?.profile?.description}</Typography>}
          {/*<Stack spacing={2}>*/}
          {/*  <Typography>{profile?.data?.profile?.description}</Typography>*/}
          {/*  /!*Commented for now no use*!/*/}
          {/*  /!*<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>*!/*/}
          {/*  /!*  {profile?.data?.profile?.tags.map((tag) => (*!/*/}
          {/*  /!*    <Tag key={tag} tag={tag} />*!/*/}
          {/*  /!*  ))}*!/*/}
          {/*  /!*</Box>*!/*/}
          {/*</Stack>*/}
          <Button variant='contained' sx={{ borderRadius: 50 }} onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
            Edit Profile
          </Button>
        </Stack>
        <Stack pt={2}>
          <List component='nav' aria-label='main mailbox folders'>
            <CustomListItem title='Personal Details' onClick={() => navigate(ROUTES.PERSONAL_DETAILS)} />
            <CustomListItem title='My Videos' onClick={() => navigate(ROUTES.MY_VIDEOS)} />
            <CustomListItem
              title='My Tasks'
              onClick={() => navigate(ROUTES.MY_TASK.replace(':taskType', MY_TASK_TYPE.PENDING))}
            />
            <CustomListItem
              title='My Requests'
              onClick={() => navigate(ROUTES.MY_REQUEST.replace(':requestType', MY_REQUEST_TYPE.PENDING))}
            />
            <CustomListItem title='Wallet' onClick={() => navigate(ROUTES.WALLET)} />
            <CustomListItem title='Logout' onClick={handleLogout} />
            <CustomListItem title='Share Feedback' onClick={() => navigate(ROUTES.FEEDBACK)} />
            <CustomListItem title='Refer and Earn' onClick={() => navigate(ROUTES.REFER_AND_EARN)} />
            {/*<CustomListItem title='My Calendar' onClick={(event) => console.log(event)} />*/}
          </List>
        </Stack>
      </Box>
      <CustomDialog open={profileImage} handleClose={handleCloseImagePreview} maxWidth='xs' fullWidth>
        <Stack spacing={3} p={2} width='100%' alignItems='center'>
          <BackgroundImage img={profileImage?.img} width={256} height={378} borderRadius={12} />
          <Stack spacing={2} width='100%' direction='row'>
            <Button fullWidth variant='contained' onClick={submitProfileImage}>
              Upload
            </Button>
            <Button
              fullWidth
              variant='outlined'
              sx={{ borderRadius: 50, color: '#000' }}
              onClick={handleCloseImagePreview}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </CustomDialog>
      <EditProfileImageDrawer
        bottomDrawerState={openEditProfileDrawer}
        setBottomDrawerState={setOpenEditProfileDrawer}
        isProfileImageAvailable={Boolean(profile?.data?.profile?.avatarUrl)}
        handleSelectImageClick={handleSelectImageClick}
      />
    </>
  );
};

export default ProfilePage;
