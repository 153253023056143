import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import PaymentIcon from '../../assets/icons/PaymentIcon';
import BottomDrawer from '../../components/BottomDrawer/BottomDrawer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import PaymentCheckout from '../../components/PaymentCheckout/PaymentCheckout';
import api from '../../config/api';
import { useMutation } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import { taskDetails } from '../../store';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import WalletIcon from '../../assets/icons/WalletIcon';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import CardIcon from '../../assets/icons/CardIcon';
import useGetWallet from '../../hooks/useGetWallet';
import { useForm } from 'react-hook-form';
import CustomRadioButtonNew from '../../components/CustomRadioButtonNew/CustomRadioButtonNew';
import { signal } from '@preact/signals-react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import useSetting from '../../hooks/useSetting';
import InsufficientWalletModal from './InsufficientWalletModal';
import currencyFormat from '../../utils/currencyFormat';

dayjs.extend(customParseFormat);

const paymentDetails = signal({});
const TextLayout = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        variant='h5_bold'
        sx={{
          color: (theme) => theme.palette.lightText.main,
          maxWidth: '150px',
        }}
      >
        {label}
      </Typography>
      <Typography variant='h5_bold'>{value}</Typography>
    </Box>
  );
};

const createTask = async () => {
  try {
    const { data } = await api.post('/api/user/task/payment', paymentDetails.value);

    const paymentId = data.data._id;
    const { data: task } = await api.post('/api/user/task', {
      ...taskDetails?.value,
      paymentId,
    });
    // const taskId = await task?.data?._id;
    // // creating stream
    // await api.post(`api/stream?task=${taskId}`);
    toast.success('Request posted successfully');
    localStorage.removeItem('requestVideoForm');
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.message);
    return e?.response?.data?.message;
  }
};

const ConfirmBottomDrawer = ({ bottomDrawerState, setBottomDrawerState }) => {
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');
  const [openInsufficientModal, setOpenInsufficientModal] = React.useState(false);
  const navigate = useNavigate();
  const { data: setting } = useSetting();
  const PLATFORM_FEE = setting?.data?.platformFeePercentage;
  const amount = taskDetails?.value?.amount;
  const { handleSubmit, control } = useForm({
    defaultValues: {
      paymentMethod: 'wallet',
    },
  });

  const [isChangePaymentMethod, setIsChangePaymentMethod] = React.useState(false);

  const getPaymentIntent = async (amount) => {
    const { data } = await api.post('/api/user/payment-intent', {
      amount: amount,
    });
    return data;
  };

  const { mutate: getPaymentIntentMut, isPending } = useMutation({
    mutationFn: (amount) => getPaymentIntent(amount),
    onSuccess: (data) => {
      paymentDetails.value = {
        ...paymentDetails.value,
        txnID: data?.data?.id,
        currency: 'usd',
        status: 'succeeded',
        fee: parseFloat(parseFloat(amount) * PLATFORM_FEE) / 100,
        amount,
      };
      setClientSecret(data?.data?.client_secret);
      setOpenPaymentModal(true);
    },
  });

  const { mutate: createTaskMut } = useMutation({
    mutationFn: createTask,
    onSuccess: (data) => {
      console.log(data);
      if (!data) navigate(ROUTES.WELCOME);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleClose = () => {
    setOpenPaymentModal(false);
  };
  const { data: wallet, isLoading } = useGetWallet();
  const paymentOptions = [
    {
      value: 'wallet',
      title: 'Wallet',
      subtitle: `Balance: ${currencyFormat(wallet?.data?.totalBalance)}`,
      icon: <WalletIcon />,
    },
    {
      value: 'card',
      title: 'Card',
      subtitle: '',
      icon: <CardIcon />,
    },
  ];

  const onSubmit = async (value) => {
    const { paymentMethod } = value;
    console.log(paymentMethod);
    paymentDetails.value = {
      paymentMethod: paymentMethod,
      amount,
    };
    if (paymentMethod === 'wallet') {
      if (wallet?.data?.totalBalance < amount) {
        setOpenInsufficientModal(true);
        return;
      }
      await createTaskMut();
    } else {
      getPaymentIntentMut(amount);
    }
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <BottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState}>
        {isPending ? (
          <Loading />
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button onClick={() => setBottomDrawerState(false)} sx={{ color: '#000' }}>
                Cancel
              </Button>
            </Box>
            <Stack
              spacing={3}
              justifyContent='center'
              alignItems='center'
              sx={{
                mt: 3,
                width: '100%',
              }}
            >
              <PaymentIcon />
              <Stack spacing={1.5} sx={{ textAlign: 'center', maxWidth: '80%', pb: 2 }}>
                <Typography variant='h3_dark'>{taskDetails.value.title}</Typography>
                <Typography
                  variant='h5'
                  sx={{
                    color: (theme) => theme.palette.lightText.main,
                  }}
                >
                  Confirm and pay securely for your video request
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={3} display={isChangePaymentMethod ? 'none' : 'flex'}>
              <TextLayout label='Task name' value={taskDetails.value.title} />
              <TextLayout label='Schedule' value={dayjs(taskDetails.value.requestAt).format('hh:mm A, DD MMM, YYYY')} />
              <Divider sx={{ width: '100%' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant='h5_dark'
                  sx={{
                    color: (theme) => theme.palette.lightText.main,
                    maxWidth: '150px',
                  }}
                >
                  Amount Due
                </Typography>
                <Typography variant='h5_bold'>${taskDetails.value?.amount}</Typography>
              </Box>
              <Divider sx={{ width: '100%' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <WalletIcon />
                  <Stack>
                    <Typography variant='caption'>Pay using</Typography>
                    <Typography variant='h5_dark'>Wallet</Typography>
                    <Typography variant='caption'>Balance: {currencyFormat(wallet.data.totalBalance)}</Typography>
                  </Stack>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
                  onClick={() => setIsChangePaymentMethod(true)}
                >
                  <Typography variant='caption_semibold'>change</Typography>
                  <RightArrowIcon />
                </Box>
              </Box>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} display={isChangePaymentMethod ? 'flex' : 'none'}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant='h5_dark'
                    sx={{
                      color: (theme) => theme.palette.lightText.main,
                      maxWidth: '150px',
                    }}
                  >
                    Amount Due
                  </Typography>
                  <Typography variant='h5_bold'>${taskDetails.value?.amount}</Typography>
                </Box>
                <Divider sx={{ width: '100%' }} />
                <Typography variant='h5_bold'>Payment Options</Typography>
                <CustomRadioButtonNew name={'paymentMethod'} control={control} options={paymentOptions} />
              </Stack>
              <Button variant='contained' rounded type='submit' sx={{ mt: 2 }} fullWidth>
                Continue with Payment
              </Button>
            </form>
          </>
        )}
      </BottomDrawer>
      <CustomDialog open={openPaymentModal} handleClose={handleClose}>
        <PaymentCheckout
          clientSecret={clientSecret}
          amount={amount}
          callback={createTaskMut}
          handleClose={handleClose}
        />
      </CustomDialog>
      <InsufficientWalletModal
        open={openInsufficientModal}
        handleClose={() => setOpenInsufficientModal(false)}
        setIsChangePaymentMethod={setIsChangePaymentMethod}
      />
    </>
  );
};

export default ConfirmBottomDrawer;
