import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getProfile = async (id) => {
  if (id) {
    const { data } = await api.get(`/api/user/profile?id=${id}`);
    return data;
  }
  const { data } = await api.get('/api/user/profile');
  return data;
};

const useProfile = (id) => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: () => getProfile(id),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 2,
  });
};

export default useProfile;
