import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box } from '@mui/material';
import AddMoneyForm from './AddMoneyForm';

const WalletAddMoneyPage = () => {
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <TopNavigation title='Add' />
        <TopNavigationSpace />
        <AddMoneyForm />
      </Box>
    </>
  );
};

export default WalletAddMoneyPage;
