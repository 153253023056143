import React from 'react';
import api from '../../config/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ROUTES from '../../routes';
import Loading from '../../components/Loading/Loading';
import useGetTask from '../../hooks/useGetTask';
import useGetProposal from '../../hooks/useGetProposal';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';

const MyRequestProposalPage = () => {
  const { proposalId, taskId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const { data: proposal, isLoading: isProposalLoading } = useGetProposal(proposalId);
  const { data: task } = useGetTask(taskId);

  const acceptProposal = async () => {
    try {
      setIsLoading(true);
      await api.get(`/api/user/proposal/accept?id=${proposalId}`);
      setIsLoading(false);
      toast.success('Proposal Accepted');
      navigate(ROUTES.WELCOME);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const rejectProposal = async () => {
    try {
      setIsLoading(true);
      await api.get(`/api/user/proposal/reject?id=${proposalId}`);
      setIsLoading(false);
      toast.success('Proposal Rejected');
      navigate(ROUTES.WELCOME);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  if (isLoading || isProposalLoading) return <Loading />;
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100dvh' }}>
      <TopNavigation title='My Requests' />
      <TopNavigationSpace />
      <Stack
        spacing={2.5}
        sx={{
          py: '20px',
          px: { sm: '24px', xs: '16px' },
          mx: { sm: '-24px', xs: '-16px' },
        }}
      >
        <Typography variant='h4'>{task?.data?.title}</Typography>
        <Box display='flex' alignItems='center' gap={1}>
          <CustomAvatar avatarUrl={proposal?.user?.profile?.avatarUrl} name={proposal?.data?.user?.name} size={64} />
          <Typography variant='h5_bold'>{proposal?.data?.user?.name}</Typography>
        </Box>

        <Typography variant='h6'>{proposal?.data?.message}</Typography>
        <Typography variant='h6'>
          Proposed Amount: <Typography variant='h6_dark'>${proposal?.data?.amount}</Typography>
        </Typography>
        <Typography variant='h6'>
          Stream Date and Time:
          <Typography variant='h6_dark'> {dayjs(task?.data?.requestAt).format('DD MMM, YYYY HH:mm')}</Typography>
        </Typography>
        <Typography variant='h6'>
          Proposed Date and Time:
          <Typography variant='h6_dark'> {dayjs(proposal?.data?.proposeAt).format('DD MMM, YYYY HH:mm')}</Typography>
        </Typography>
      </Stack>
      {task?.data?.requestStreamer ? (
        <></>
      ) : task?.data?.accepted ? (
        <Box
          sx={{
            position: 'absolute',
            bottom: 70,
            display: 'flex',
            width: '100%',
            gap: 1.5,
            justifyContent: 'center',
            textAlign: 'center',
            p: 1,
          }}
        >
          <Typography variant='h6_semibold'>Proposal already accepted</Typography>
        </Box>
      ) : (
        <Stack
          sx={{
            position: 'absolute',
            bottom: 70,
            width: '100%',
          }}
          direction='row'
          justifyContent='space-between'
          spacing={2}
        >
          <Button variant='contained' fullWidth onClick={acceptProposal}>
            Accept Proposal
          </Button>
          <Button variant='outlined' fullWidth onClick={rejectProposal}>
            Reject Proposal
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default MyRequestProposalPage;
