import React from 'react';

function CoinIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none' viewBox='0 0 60 60'>
      <path
        fill='#FDCC0A'
        d='M24.975 44.95c11.032 0 19.975-8.943 19.975-19.975C44.95 13.943 36.007 5 24.975 5 13.943 5 5 13.943 5 24.975 5 36.007 13.943 44.95 24.975 44.95z'
        opacity='0.4'
      ></path>
      <path
        fill='#FDCC0A'
        d='M54.925 39.974c0 8.25-6.7 14.95-14.95 14.95-5.1 0-9.575-2.55-12.275-6.425 10.9-1.225 19.575-9.9 20.8-20.8 3.875 2.7 6.425 7.175 6.425 12.275zM28.625 24.273l-6-2.1c-.6-.2-.725-.25-.725-1.125 0-.65.45-1.175 1.025-1.175h3.75c.8 0 1.45.725 1.45 1.625 0 1.025.85 1.875 1.875 1.875s1.875-.85 1.875-1.875c0-2.875-2.225-5.225-5-5.35v-.125c0-1.025-.85-1.875-1.875-1.875a1.872 1.872 0 00-1.875 1.875v.125H22.9c-2.625 0-4.775 2.2-4.775 4.925 0 2.375 1.05 3.9 3.225 4.65l6.025 2.1c.6.2.725.25.725 1.125 0 .65-.45 1.175-1.025 1.175h-3.75c-.8 0-1.45-.725-1.45-1.625 0-1.025-.85-1.875-1.875-1.875s-1.875.85-1.875 1.875c0 2.875 2.225 5.225 5 5.35v.15c0 1.025.85 1.875 1.875 1.875s1.875-.85 1.875-1.875v-.125h.225c2.625 0 4.775-2.2 4.775-4.925 0-2.375-1.075-3.9-3.25-4.675z'
      ></path>
    </svg>
  );
}

export default CoinIcon;
