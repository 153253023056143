import * as React from 'react';

const WaitForCreatorResponseImage = (props) => (
  <svg width={240} height={240} viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M191.988 206.829H113.26l8.975-75.9c.621-5.253 5.063-9.21 10.34-9.21h57.758c6.236 0 11.074 5.459 10.34 11.668l-8.685 73.442z'
      fill='#414141'
    />
    <path
      d='M186.479 101.946l-17.871-11.253c-.89-.568-2.103-.492-2.822.278a2.174 2.174 0 00.407 3.34c-1.018-.642-3.126-1.992-4.149-.071-.567 1.067-.35 2.36.658 3.01l-1.846-1.184a2.185 2.185 0 00-3.301 1.355c-.23.896.185 1.826.956 2.314l.012.008 1.582 1.002c-1.219-.551-2.781.155-3.068 1.66-.162.852.249 1.718.985 2.185l.003.005 11.162 7.052-3.06 1.907a2.902 2.902 0 00-.765 4.235c.396.505.934.86 1.519 1.021.67.189 1.402.138 2.063-.187l3.297-1.627c2.804-1.496 5.955.166 5.955.166'
      fill='#fff'
    />
    <path
      d='M167.672 119.306a3.333 3.333 0 01-2.611-1.278 3.29 3.29 0 01.865-4.799l2.541-1.583-10.645-6.726a.276.276 0 01-.039-.028c-.856-.562-1.306-1.585-1.121-2.558a2.638 2.638 0 011.372-1.863c.186-.096.38-.169.579-.218l-.293-.186c-.951-.602-1.402-1.7-1.135-2.742.209-.834.773-1.49 1.546-1.8a2.58 2.58 0 012.332.215l.335.215c-.119-.605-.024-1.272.309-1.897.32-.6.763-.979 1.321-1.127.614-.163 1.267-.02 1.865.227a2.575 2.575 0 01.613-2.452c.818-.875 2.241-1.021 3.309-.339l17.868 11.251a.384.384 0 11-.408.65l-17.871-11.253c-.764-.488-1.767-.395-2.336.215a1.788 1.788 0 00.333 2.754.383.383 0 11-.411.648c-.676-.426-1.89-1.192-2.766-.96-.348.092-.622.336-.84.745-.497.936-.276 1.99.526 2.507h.001a.385.385 0 01-.415.646l-1.845-1.184a1.811 1.811 0 00-1.634-.15 1.82 1.82 0 00-1.087 1.274c-.181.709.143 1.489.79 1.897l1.594 1.01a.383.383 0 01-.363.674 1.808 1.808 0 00-1.562.056c-.514.267-.86.738-.971 1.325-.128.679.198 1.399.813 1.789a.39.39 0 01.036.025l11.129 7.032a.386.386 0 01-.002.652l-3.059 1.906a2.482 2.482 0 00-1.147 1.687c-.128.7.049 1.424.482 1.989.336.428.805.743 1.317.885.6.169 1.235.112 1.792-.161l3.296-1.628c2.94-1.567 6.168.1 6.305.172a.384.384 0 01-.358.679c-.03-.015-3.011-1.546-5.595-.167l-3.308 1.633a3.234 3.234 0 01-1.447.341z'
      fill='#010101'
    />
    <path
      d='M167.217 100.511a.378.378 0 01-.206-.061l-4.517-2.882a.384.384 0 11.413-.647l4.516 2.882a.384.384 0 01-.206.708zM164.879 104.174a.378.378 0 01-.206-.061l-4.773-3.045a.383.383 0 11.413-.647l4.772 3.045a.383.383 0 01-.206.708zM169.556 96.844a.379.379 0 01-.206-.06l-4.509-2.877a.384.384 0 11.413-.647l4.509 2.877a.384.384 0 01-.207.707z'
      fill='#010101'
    />
    <path d='M169.186 111.646s1.323-3.221 4.616-3.43l-4.616 3.43z' fill='#fff' />
    <path
      d='M169.186 112.03a.385.385 0 01-.355-.531c.057-.14 1.453-3.445 4.946-3.666a.385.385 0 01.049.767c-3.007.191-4.273 3.162-4.286 3.192a.383.383 0 01-.354.238z'
      fill='#010101'
    />
    <path
      d='M118.241 98.067l17.871-11.252c.89-.568 2.103-.493 2.822.277a2.174 2.174 0 01-.407 3.34c1.018-.642 3.126-1.992 4.149-.07.567 1.067.35 2.36-.658 3.01l1.846-1.184a2.185 2.185 0 013.301 1.354c.23.897-.185 1.826-.956 2.315l-.012.007-1.582 1.002c1.219-.55 2.781.156 3.068 1.66.162.853-.249 1.719-.985 2.185l-.003.006-11.162 7.052 3.06 1.906a2.903 2.903 0 01.765 4.236c-.396.504-.934.86-1.519 1.021a2.885 2.885 0 01-2.063-.187l-3.297-1.628c-2.804-1.496-5.955.167-5.955.167'
      fill='#fff'
    />
    <path
      d='M137.05 115.428a3.28 3.28 0 01-1.444-.335l-3.299-1.628c-2.596-1.385-5.577.146-5.606.162a.384.384 0 01-.357-.679c.136-.072 3.364-1.739 6.314-.166l3.287 1.623c.555.273 1.19.33 1.788.162a2.562 2.562 0 001.321-.888 2.525 2.525 0 00.481-1.986 2.489 2.489 0 00-1.147-1.687l-3.06-1.907a.388.388 0 01-.181-.326c0-.131.067-.254.179-.326l11.132-7.034.033-.024c.616-.39.942-1.109.813-1.79a1.845 1.845 0 00-.972-1.324 1.804 1.804 0 00-1.562-.056.383.383 0 01-.362-.674l1.582-1.003c.659-.416.984-1.195.802-1.901-.149-.597-.545-1.062-1.087-1.278a1.808 1.808 0 00-1.635.152l-1.845 1.183a.383.383 0 11-.415-.646h.001c.802-.517 1.023-1.571.525-2.507-.218-.409-.492-.652-.84-.745-.876-.232-2.09.534-2.742.945l-.009.005-.014.01a.397.397 0 01-.518-.126.37.37 0 01.107-.522c.453-.288.751-.758.819-1.288a1.785 1.785 0 00-.486-1.466c-.569-.61-1.573-.703-2.335-.217l-17.873 11.254a.383.383 0 11-.408-.65l17.871-11.253c1.066-.681 2.488-.536 3.307.34a2.581 2.581 0 01.613 2.452c.601-.248 1.253-.391 1.867-.228.557.148 1.002.527 1.322 1.126.331.626.426 1.292.308 1.898l.334-.214a2.58 2.58 0 012.333-.217c.773.309 1.338.967 1.548 1.803.266 1.038-.185 2.138-1.125 2.732l-.303.192a2.608 2.608 0 011.951 2.08c.185.975-.266 1.999-1.125 2.562-.012.01-.024.018-.036.026l-10.644 6.725 2.54 1.583a3.293 3.293 0 011.497 2.201 3.305 3.305 0 01-.63 2.596 3.332 3.332 0 01-2.615 1.279z'
      fill='#010101'
    />
    <path
      d='M137.504 96.633a.384.384 0 01-.206-.707l4.516-2.883a.384.384 0 11.413.647l-4.517 2.883a.38.38 0 01-.206.06zM139.841 100.297a.383.383 0 01-.206-.707l4.772-3.046a.384.384 0 11.413.647l-4.773 3.046a.385.385 0 01-.206.06zM135.164 92.966a.383.383 0 01-.207-.707l4.509-2.877a.384.384 0 11.413.647l-4.509 2.877a.38.38 0 01-.206.06z'
      fill='#010101'
    />
    <path d='M135.534 107.768s-1.323-3.221-4.616-3.43l4.616 3.43z' fill='#fff' />
    <path
      d='M135.535 108.151a.385.385 0 01-.355-.237c-.013-.031-1.279-3.003-4.284-3.193a.383.383 0 01-.359-.408.382.382 0 01.407-.359c3.493.222 4.889 3.526 4.947 3.667a.383.383 0 01-.356.53z'
      fill='#010101'
    />
    <path
      d='M129.778 125.622l-1.311 24.285c-.164 2.996-2.945 5.157-5.89 4.569-7.39-1.48-20.628-4.154-30.45-6.274a17.023 17.023 0 01-9.208-5.403 16.947 16.947 0 01-2.776-4.323 16.919 16.919 0 01-1.429-5.495 17.084 17.084 0 018.532-16.316l31.02-20.645 10.273 17.285-16.055 12.869 17.294-.552z'
      fill='#FDCC0A'
    />
    <path
      d='M112.484 126.558a.411.411 0 01-.078-.007l-11.937-2.459a.385.385 0 01.155-.752l11.76 2.421 15.653-12.548-9.896-16.65-30.682 20.421a.383.383 0 11-.425-.639l31.017-20.646a.384.384 0 01.543.123l10.274 17.285a.382.382 0 01-.089.495l-16.053 12.871a.39.39 0 01-.242.085z'
      fill='#010101'
    />
    <path
      d='M186.609 136.806l-4.082 70.555h-73.392l10.463-76.183a5.858 5.858 0 014.271-4.854c4.942-1.337 14.642-.548 25.294.009 12.71.661 26.661 1.782 33.569 4.128 2.493.845 4.025 3.717 3.877 6.345z'
      fill='#FDCC0A'
    />
    <path
      d='M113.121 178.723a.384.384 0 01-.381-.437l5.645-41.085a.384.384 0 11.761.105l-5.645 41.085a.386.386 0 01-.38.332z'
      fill='#010101'
    />
    <path
      d='M227.937 136.433a17.037 17.037 0 01-4.205 9.817 17.07 17.07 0 01-9.208 5.403c-14.359 3.104-30.962 5.659-31.341 5.587-11.088-2.09-12.942-27.854-6.607-28.259l18.417 1.638-21.157-13.172 10.453-17.57 35.11 20.239a17.093 17.093 0 018.538 16.317z'
      fill='#FDCC0A'
    />
    <path
      opacity={0.4}
      d='M107.427 144.727a2.795 2.795 0 100-5.59 2.795 2.795 0 000 5.59zM86.253 133.811a4.839 4.839 0 01-4.84 4.839c-.44 0-.865-.056-1.27-.174a17.065 17.065 0 01-1.408-8.691 4.766 4.766 0 012.678-.814 4.84 4.84 0 014.84 4.84zm69.909 70.211a7.662 7.662 0 01-.763 3.34h-13.818a7.67 7.67 0 016.908-11.006c4.237 0 7.673 3.432 7.673 7.666zm71.775-67.589a17.037 17.037 0 01-1.675 6.033 6.159 6.159 0 111.141-11.994c.518 1.904.713 3.923.534 5.961zM213.604 128.968a1.397 1.397 0 100-2.794 1.397 1.397 0 000 2.794zM187.062 113.368a2.05 2.05 0 100-4.098 2.05 2.05 0 000 4.098zM200.312 125.297a3.443 3.443 0 100-6.886 3.443 3.443 0 000 6.886zM190.723 146.951a2.222 2.222 0 10.001-4.445 2.222 2.222 0 00-.001 4.445zM179.478 142.189a2.92 2.92 0 100-5.841 2.92 2.92 0 000 5.841zM215.598 144.728a1.995 1.995 0 100-3.99 1.995 1.995 0 000 3.99zM156.519 166.638a4.698 4.698 0 100-9.396 4.698 4.698 0 000 9.396zM128.092 140.052a3.704 3.704 0 100-7.409 3.705 3.705 0 000 7.409zM166.708 141.928a2.79 2.79 0 100-5.58 2.79 2.79 0 000 5.58zM145.058 150.24a2.756 2.756 0 100-5.512 2.756 2.756 0 000 5.512zM170.271 173.145a3.563 3.563 0 10.001-7.125 3.563 3.563 0 00-.001 7.125zM135.763 170.639a2.615 2.615 0 100-5.23 2.615 2.615 0 000 5.23zM166.836 188.858a2.397 2.397 0 100-4.794 2.397 2.397 0 000 4.794zM122.382 192.196a2.745 2.745 0 100-5.49 2.745 2.745 0 000 5.49zM180.829 198.707a1.656 1.656 0 10.001-3.313 1.656 1.656 0 00-.001 3.313zM124.388 164.338a2.398 2.398 0 10-.001-4.795 2.398 2.398 0 00.001 4.795zM99.952 131.969a1.499 1.499 0 100-2.998 1.499 1.499 0 000 2.998zM101.985 119.906a3.094 3.094 0 100-6.187 3.094 3.094 0 000 6.187zM115.942 113.412a2.822 2.822 0 100-5.644 2.822 2.822 0 000 5.644z'
      fill='#fff'
    />
    <path
      d='M183.162 157.621a.276.276 0 01-.05-.004c-3.71-.7-6.814-4.062-8.739-9.465a.384.384 0 01.724-.257c1.828 5.136 4.726 8.32 8.157 8.968.466-.005 10.262-1.47 21.787-3.678a.383.383 0 11.144.754c-10.402 1.991-21.101 3.682-22.023 3.682zm17.477-22.785a.388.388 0 01-.206-.06l-26.804-17.004a.384.384 0 01-.124-.521L183.96 99.68a.385.385 0 01.522-.137l35.112 20.24a.385.385 0 01.141.525.385.385 0 01-.525.14l-34.783-20.05-10.07 16.926 26.489 16.804a.386.386 0 01.119.531.393.393 0 01-.326.177z'
      fill='#010101'
    />
    <path d='M213.508 207.212H24.022a.384.384 0 110-.769h189.486a.385.385 0 010 .769z' fill='#fff' />
    <path
      d='M14.89 142.708l5.359 44.675c.426 3.533 3.43 6.183 7.007 6.183h74.027c4.225 0 7.507-3.665 7.007-7.831l-5.373-44.69c-.426-3.519-3.43-6.168-7.007-6.168H21.898c-4.24 0-7.507 3.665-7.007 7.831z'
      fill='#414141'
    />
    <path
      d='M108.29 185.735c.501 4.166-2.782 7.832-7.007 7.832h2.576c4.225 0 7.507-3.666 7.007-7.832l-5.373-44.69c-.427-3.519-3.43-6.168-7.007-6.168H95.91c3.577 0 6.58 2.649 7.007 6.168l5.373 44.69zM52.115 166.974H64.22v39.462H45.335l6.78-39.462zM64.22 166.974h2.74l-.58 36.972h12.685l-.234 2.49H64.22v-39.462z'
      fill='#414141'
    />
    <path
      d='M47.049 195.289a.512.512 0 01-.504-.605l5.066-27.803a.513.513 0 01.504-.42H64.22c.283 0 .512.229.512.512v20.168a.511.511 0 01-1.024 0v-19.656H52.543l-4.99 27.383a.513.513 0 01-.504.421zM61.394 157.278c1.296-1.345 1.17-3.569-.28-4.966-1.451-1.397-3.678-1.439-4.974-.094-1.296 1.346-1.17 3.57.28 4.967 1.452 1.397 3.678 1.439 4.974.093zM163.261 126.585l1.177-18.488-17.777 1.067-3.1 16.693.929 2.727c6.371 8.388 19.893-1.378 18.771-1.999z'
      fill='#fff'
    />
    <path
      d='M151.492 132.355c-2.556 0-5.256-.836-7.308-3.538a.373.373 0 01-.057-.108l-.929-2.727a.38.38 0 01-.014-.195l3.1-16.693a.384.384 0 01.355-.313l17.776-1.067a.385.385 0 01.407.408l-1.168 18.339a.532.532 0 01.053.31c-.13 1.074-4.265 3.915-8.438 5.046-1.144.309-2.441.538-3.777.538zm-6.659-3.954c2.051 2.664 5.042 3.667 8.889 2.978 4.571-.819 8.722-3.82 9.188-4.636a.402.402 0 01-.034-.182l1.151-18.055-17.043 1.023-3.027 16.299.876 2.573z'
      fill='#010101'
    />
    <path
      d='M164.187 110.103c5.054-8.56 4.234-19.006 4.234-19.006l-20.323-9.755c-.462.364-12.853 5.3-14.611 20.543-1.442 12.492 7.945 19.169 22.319 15.544'
      fill='#fff'
    />
    <path
      d='M148.418 118.799c-4.476 0-8.258-1.227-11.005-3.632-3.474-3.043-4.963-7.651-4.309-13.328 1.051-9.11 6.181-16.44 14.445-20.64.163-.084.274-.138.321-.17a.412.412 0 01.393-.036l20.323 9.756c.123.06.207.18.217.316.009.106.772 10.665-4.286 19.231a.385.385 0 01-.662-.391c4.537-7.684 4.273-17.023 4.196-18.562l-19.938-9.571-.215.11c-8.027 4.08-13.01 11.198-14.03 20.044-.626 5.414.776 9.792 4.052 12.661 3.975 3.482 10.294 4.357 17.792 2.466a.384.384 0 01.188.746c-2.649.669-5.158 1-7.482 1z'
      fill='#010101'
    />
    <path
      d='M155.961 77.363s6.194-3.027 9.472-.889c3.278 2.14.257 8.05.257 8.05s5.241-1.288 8.379 4.57c3.137 5.858-5.843 15.001-5.843 15.001l-5.88-3.203s-.151 1.087-1.813-.28c-1.663-1.367-.939-6.976-2.858-8.132 0 0-16.457 5.755-24.321-6.499-7.865-12.253 3.965-18.265 15.419-16.53 11.454 1.734 7.188 7.912 7.188 7.912z'
      fill='#414141'
    />
    <path d='M147.995 98.372c1.174-.194 2.388.406 3.078 1.525l-3.078-1.525z' fill='#fff' />
    <path
      d='M151.074 100.282a.386.386 0 01-.327-.183c-.61-.988-1.664-1.516-2.688-1.348a.384.384 0 11-.125-.758c1.33-.219 2.693.447 3.468 1.702a.384.384 0 01-.328.587zm-10.733-1.104c-.273.876-.977 1.441-1.571 1.255-.589-.185-.845-1.05-.566-1.931.279-.88.983-1.445 1.571-1.26.594.19.846 1.056.566 1.936zm8.962 2.991c-.278.88-.981 1.439-1.575 1.253-.589-.184-.841-1.049-.566-1.931.278-.88.982-1.445 1.575-1.253.589.184.841 1.049.566 1.931z'
      fill='#010101'
    />
    <path d='M139.038 95.383c1.174-.194 2.387.406 3.078 1.525l-3.078-1.525z' fill='#fff' />
    <path
      d='M142.117 97.29a.384.384 0 01-.327-.182c-.611-.987-1.664-1.517-2.689-1.348a.384.384 0 01-.125-.758c1.333-.22 2.694.447 3.468 1.702a.384.384 0 01-.327.587z'
      fill='#010101'
    />
    <path
      d='M162.07 101.016s5.71-4.507 8.452-3.16c2.742 1.349.477 11.307-3.032 11.853-3.509.546-6.085-1.121-6.085-1.121'
      fill='#fff'
    />
    <path
      d='M166.058 110.203c-2.824 0-4.767-1.232-4.861-1.294a.383.383 0 11.416-.644c.025.016 2.506 1.579 5.819 1.064 1.787-.278 3.329-3.55 3.786-6.553.358-2.346.019-4.142-.865-4.576-2.069-1.017-6.509 1.904-8.045 3.116a.385.385 0 01-.476-.603c.241-.19 5.935-4.64 8.859-3.202 1.227.603 1.708 2.615 1.287 5.381-.505 3.312-2.188 6.847-4.428 7.195a9.547 9.547 0 01-1.492.116z'
      fill='#010101'
    />
    <path
      d='M167.633 105.289a.384.384 0 01-.328-.584c.328-.54.548-1.155.636-1.781.052-.368.014-.623-.11-.756-.09-.096-.25-.148-.421-.136-.17.012-.358.081-.575.21-.318.19-.598.449-.812.75a.384.384 0 01-.627-.445 3.49 3.49 0 011.045-.965c.321-.194.622-.296.915-.318.401-.027.789.114 1.036.378.295.316.395.769.309 1.387a5.488 5.488 0 01-.741 2.074.376.376 0 01-.327.186z'
      fill='#010101'
    />
    <path d='M142.92 101.621l-1.405 3.745c-.283.753-.568 1.536-.441 2.307.128.771.795 1.527 1.735 1.597' fill='#fff' />
    <path
      d='M142.808 109.655l-.029-.001c-1.175-.087-1.938-1.03-2.085-1.917-.146-.887.176-1.747.46-2.505l1.406-3.745a.384.384 0 11.719.27l-1.405 3.745c-.251.669-.535 1.426-.421 2.109.095.577.612 1.22 1.383 1.276a.384.384 0 01-.028.768zM147.226 112.837c-.165 0-.33-.011-.494-.034a.384.384 0 11.102-.761 3.072 3.072 0 002.072-.474 3.062 3.062 0 001.245-1.723.384.384 0 11.741.202 3.834 3.834 0 01-1.562 2.161 3.836 3.836 0 01-2.104.629z'
      fill='#010101'
    />
    <path
      d='M58.764 94.007c16.847 0 30.504-13.657 30.504-30.504C89.268 46.657 75.611 33 58.764 33c-16.846 0-30.503 13.657-30.503 30.504 0 16.846 13.657 30.503 30.503 30.503z'
      fill='#fff'
    />
    <path d='M58.768 66.438a2.932 2.932 0 100-5.865 2.932 2.932 0 000 5.865z' fill='#010101' />
    <path d='M69.12 74.89L58.037 63.807v-18.07h1.456v17.468L70.148 73.86l-1.03 1.03z' fill='#010101' />
    <path
      d='M58.768 41.65a1.14 1.14 0 100-2.281 1.14 1.14 0 000 2.28zM47.27 44.732a1.14 1.14 0 100-2.281 1.14 1.14 0 000 2.28zM38.852 53.147a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM35.771 64.646a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM38.852 76.143a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM47.27 84.562a1.14 1.14 0 100-2.281 1.14 1.14 0 000 2.28zM58.768 87.644a1.14 1.14 0 100-2.282 1.14 1.14 0 000 2.282zM70.266 84.562a1.14 1.14 0 100-2.281 1.14 1.14 0 000 2.28zM78.683 76.143a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM81.764 64.646a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM78.683 53.147a1.14 1.14 0 100-2.28 1.14 1.14 0 000 2.28zM70.266 44.732a1.14 1.14 0 100-2.281 1.14 1.14 0 000 2.28z'
      fill='#fff'
    />
    <path
      d='M31.928 116.894l-2.219 2.4a2.773 2.773 0 01-3.91.156l-2.401-2.218a2.764 2.764 0 01-.155-3.902l2.218-2.411a2.774 2.774 0 013.91-.155l2.402 2.227a2.765 2.765 0 01.155 3.903zM111.634 58.367l-1.241 1.344a1.551 1.551 0 01-2.188.086l-1.344-1.24a1.545 1.545 0 01-.086-2.183l1.241-1.348a1.551 1.551 0 012.188-.087l1.344 1.247c.626.577.664 1.555.086 2.181zM17.269 51.865l-1.241 1.343a1.551 1.551 0 01-2.188.087l-1.344-1.241a1.545 1.545 0 01-.086-2.183l1.24-1.348a1.551 1.551 0 012.189-.086l1.344 1.246c.626.577.665 1.556.086 2.182zM174.17 58.367l-1.241 1.344a1.551 1.551 0 01-2.188.086l-1.343-1.24a1.544 1.544 0 01-.087-2.183l1.241-1.348a1.551 1.551 0 012.188-.087l1.344 1.247c.626.577.665 1.555.086 2.181z'
      fill='#FDCC0A'
    />
  </svg>
);
export default WaitForCreatorResponseImage;
