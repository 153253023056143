import * as React from 'react';

const GalleryAddIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M36.667 23.167v3.817c0 6.066-3.617 9.683-9.684 9.683H13.017c-4.25 0-7.317-1.783-8.75-4.95l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.467c1.3 1.116 3.4 1.116 4.7 0l6.933-5.95c1.3-1.117 3.4-1.117 4.7 0z'
    />
    <path
      fill='#292D32'
      d='M34.95 13.334h-4.9c-2.117 0-3.383-1.267-3.383-3.384v-4.9c0-.667.133-1.233.366-1.716H13.018c-6.067 0-9.683 3.616-9.683 9.683v13.967c0 1.816.316 3.4.933 4.733l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.466c1.3 1.117 3.4 1.117 4.7 0l6.933-5.95c1.3-1.116 3.4-1.116 4.7 0l2.717 2.334v-10.2c-.483.233-1.05.367-1.717.367'
      opacity={0.4}
    />
    <path
      fill='#292D32'
      d='M15 17.3a3.967 3.967 0 1 0 0-7.933 3.967 3.967 0 0 0 0 7.933M34.95 1.667h-4.9c-2.117 0-3.384 1.266-3.384 3.383v4.9c0 2.117 1.267 3.383 3.384 3.383h4.9c2.116 0 3.383-1.267 3.383-3.383v-4.9c0-2.117-1.267-3.383-3.383-3.383m1.566 6.55a1.08 1.08 0 0 1-.683.3h-2.35l.017 2.316a1.05 1.05 0 0 1-.317.717.98.98 0 0 1-.683.283c-.55 0-1-.45-1-1V8.5l-2.334.017c-.55 0-1-.467-1-1.017s.45-1 1-1l2.334.016V4.184c0-.55.45-1.016 1-1.016s1 .466 1 1.016L33.483 6.5h2.35c.55 0 1 .45 1 1a1.14 1.14 0 0 1-.316.716'
    />
  </svg>
);
export default GalleryAddIcon;
