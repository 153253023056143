import React from 'react';
import { Box } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import TopStreamersComponent from './TopStreamersComponent';

const TopStreamersPage = (props) => {
  return (
    <Box>
      <TopNavigation title={'Top Streamers'} />
      <TopNavigationSpace />
      <TopStreamersComponent top={'50px'} />
    </Box>
  );
};

TopStreamersPage.propTypes = {};

export default TopStreamersPage;
