import React from 'react';
import BottomDrawer from '../../components/BottomDrawer/BottomDrawer';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ContentCopy } from '@mui/icons-material';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { toast } from 'react-toastify';

const SocialShare = ({ open, setOpen, text, url, imageUrl }) => {
  return (
    <BottomDrawer bottomDrawerState={open} setBottomDrawerState={setOpen}>
      <Stack spacing={4}>
        <Typography variant='h5_dark'>Share</Typography>
        <Box display='grid' gridTemplateColumns='repeat(4,minmax(0,1fr))' rowGap={1} columnGap={1}>
          <Button
            disableRipple
            onClick={async () => {
              await navigator.clipboard.writeText(text + '\n' + url);
              toast.success('Copied to clipboard');
            }}
            sx={{ p: 0, display: 'block' }}
          >
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.dark.main,
                  p: 1,
                  borderRadius: 50,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ContentCopy fontSize='40px' sx={{ color: '#fff' }} />
              </Box>
              <Typography variant='caption' sx={{ color: '#000' }}>
                Copy
              </Typography>
            </Stack>
          </Button>
          <WhatsappShareButton url={url} title={text}>
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <WhatsappIcon size={40} round={true} />
              <Typography variant='caption'>Whatsapp</Typography>
            </Stack>
          </WhatsappShareButton>
          <FacebookShareButton url={url} hashtag={text}>
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <FacebookIcon size={40} round={true} />
              <Typography variant='caption'>Facebook</Typography>
            </Stack>
          </FacebookShareButton>
          <TelegramShareButton url={url} title={text}>
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <TelegramIcon size={40} round={true} />
              <Typography variant='caption'>Telegram</Typography>
            </Stack>
          </TelegramShareButton>
          <EmailShareButton subject={'Sharing recorded video'} url={url} body={text} separator={'\n'}>
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <EmailIcon size={40} round={true} />
              <Typography variant='caption'>Email</Typography>
            </Stack>
          </EmailShareButton>
          <TwitterShareButton url={url} title={text}>
            <Stack spacing={0.5} alignItems={'center'} width={'70px'}>
              <XIcon size={40} round={true} />
              <Typography variant='caption'>X</Typography>
            </Stack>
          </TwitterShareButton>
        </Box>
      </Stack>
    </BottomDrawer>
  );
};

export default SocialShare;
