import React, { useRef } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import useInfiniteScrollUtils from '../../utils/useInfiniteScrollUtils';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import ROUTES from '../../routes';

const TopStreamerCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const observer = useRef();
  const navigate = useNavigate();
  const avatarUrl = data?.profile?.avatarUrl;
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  const STREAMER_PROFILE = ROUTES.STREAMER_PROFILE.replace(':id', data?._id);
  return (
    <Stack
      direction='row'
      spacing={1.5}
      mr={3}
      alignItems='center'
      p={2}
      sx={{ borderRadius: 2.5, backgroundColor: (theme) => theme.palette.dark.light }}
      ref={index === totalCount - 1 ? lastElementRef : null}
    >
      <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(STREAMER_PROFILE)}>
        <CustomAvatar avatarUrl={avatarUrl} name={data.name} size={80} borderRadius={1.5} />
      </Box>

      <Stack spacing={1} sx={{ width: '100%' }}>
        <Typography
          variant='h5_semibold'
          textAlign='start'
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(STREAMER_PROFILE)}
        >
          {data.name}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignSelf: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6' sx={{ color: (theme) => theme.palette.lightText.light }}>
            {data?.profile?.streamCount} videos
          </Typography>
          <Button
            variant='contained'
            sx={{ fontSize: '12px', borderRadius: 50 }}
            onClick={() => navigate(`/${data?._id}/request`)}
          >
            Request
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default TopStreamerCard;
