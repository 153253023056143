import React from 'react';
import { Box, Typography } from '@mui/material';
import ROUTES from '../../routes';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import { useNavigate } from 'react-router-dom';
import useGetRecordings from '../../hooks/useGetRecordings';
import LoadingSmall from '../../components/Loading/LoadingSmall';
import VideoDescriptionTag from '../../components/VideoDescriptionTag/VideoDescriptionTag';

const StreamerVideos = ({ steamId }) => {
  const { recordings, isLoading, result, pending } = useGetRecordings(steamId);
  const navigate = useNavigate();

  if (isLoading || pending) return <LoadingSmall />;
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: 1 }} spacing={1}>
      {result.length === 0 && <Typography variant={'h6'}>No Videos</Typography>}
      {result?.map(({ data }, index) => {
        const totalDuration = data?.data?.asset?.reduce((acc, curr) => acc + curr.asset.duration, 0);
        return (
          <Box
            key={data?.data?.asset?.id}
            spacing={0.5}
            onClick={() =>
              navigate(
                ROUTES.RECORDING_PLAY +
                  '?streamId=' +
                  recordings?.data.result[index]?.streamDetails?.liveStreamId +
                  '&taskId=' +
                  recordings?.data.result[index]?._id,
              )
            }
            sx={{
              cursor: 'pointer',
            }}
          >
            <BackgroundImage
              img={data?.data?.asset?.[0]?.asset?.thumbnail_url}
              width={190}
              height={150}
              borderRadius={10}
              border={(theme) => `2px solid ${theme.palette.primary.main}`}
            >
              <VideoDescriptionTag duration={totalDuration} viewers={recordings?.data?.result[index]?.viewers} />
            </BackgroundImage>
            <Typography variant={'h6_bold'}>{recordings?.data?.result[index].title}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default StreamerVideos;
