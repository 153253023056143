import React from 'react';
import Stack from '@mui/material/Stack';
import videoAnimation from '../../assets/animation/videoAnimation.json';
import Lottie from 'lottie-react';

const LoadingSmall = () => {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
        <Lottie
          animationData={videoAnimation}
          loop={true}
          style={{
            width: '50%',
          }}
        />
        {/*<Stack textAlign='center'>*/}
        {/*  <Typography variant='h5_dark'>We're loading video for you, Please Wait!</Typography>*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>
  );
};

export default LoadingSmall;
