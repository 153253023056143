import React from 'react';
import { Box, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuHeader from '../WelcomePage/MenuHeader';
import VideoPlayIcon from '../../assets/icons/VideoPlayIcon';
import ColorfulWalletIcon from '../../assets/icons/ColorfullWalletIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import api from '../../config/api';
import { useQuery } from '@tanstack/react-query';
import NoReferEarnPage from './NoReferEarnPage';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import SocialShare from '../../components/SocialShare/SocialShare';
import ReactGA from 'react-ga4';

const ReferEarnPage = () => {
  ReactGA.send({ hitType: 'pageview', page: ROUTES.REFER_AND_EARN, title: 'Refer and Earn Page' });
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const handleSocialShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
  };
  const getReferEarnData = async () => {
    const { data } = await api.get('/api/user/referral');
    return data;
  };
  const { data: refer, isLoading } = useQuery({
    queryKey: ['refer'],
    queryFn: getReferEarnData,
  });
  const REFERRAL_TEXT = `Hey, Join me on bunpun using my code ${refer?.data?.user?.referralCode} and Earn Rewards. Request on demand live streaming from your favourite users and many more things!`;
  const BUNPUN_URL = `https://app.bunpun.com/?referralCode=${refer?.data?.user?.referralCode}`;
  const navigate = useNavigate();
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const formatRewardType = ({ type, value }) => {
    if (type === 'MIN') {
      return `${value} mins`;
    } else if (type === 'MONEY') {
      return `$${value}`;
    }
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Box sx={{ pb: 8, minHeight: '100svh' }}>
        <TopNavigation title={'Refer and Earn'} />
        <TopNavigationSpace />
        {refer?.data.referral ? (
          <Stack spacing={2} mt={2.5} textAlign='center' alignItems='center'>
            <Typography variant='h3_dark'>Invite Friends</Typography>
            <Typography variant='h5' pb={2.5}>
              Subject : {refer?.data?.referral?.name}
            </Typography>
            <img src={`${S3_BUCKET}/${refer?.data?.referral?.attachment[0]}`} alt='referEarn' />
            <Typography variant='h5' py={2.5}>
              Description : {refer?.data?.referral?.description}
            </Typography>
            <Stack direction='row' justifyContent='space-between' width='100%'>
              <Typography variant='h5' py={2.5}>
                Your Reward:{' '}
                {formatRewardType({
                  type: refer?.data?.referral?.rewardReferral?.type,
                  value: refer?.data?.referral?.rewardReferral?.value,
                })}
              </Typography>
              <Typography variant='h5' py={2.5}>
                Friend’s Reward:{' '}
                {formatRewardType({
                  type: refer?.data?.referral?.rewardReferee?.type,
                  value: refer?.data?.referral?.rewardReferee?.value,
                })}
              </Typography>
            </Stack>
            <Button
              variant='contained'
              fullWidth
              pb={1}
              sx={{ justifyContent: 'space-between', p: '12px 20px' }}
              endIcon={<ShareIcon variant='dark' />}
              onClick={handleSocialShareDialog}
            >
              {refer?.data?.user?.referralCode}
            </Button>
          </Stack>
        ) : (
          <NoReferEarnPage />
        )}
        <Stack spacing={2} mt={2.5} textAlign='center' alignItems='center'>
          <MenuHeader
            title={`Successful Referral (${refer.data.referralCount})`}
            isViewAll={refer.data.referralCount > 0}
            onClick={() => navigate(ROUTES.REFER_AND_EARN_TRANSACTION)}
          />
          <Stack
            spacing={3}
            direction='row'
            alignItems='center'
            textAlign='center'
            width='100%'
            justifyContent='space-between'
            pt={1}
          >
            <Stack spacing={1.5} alignItems='center'>
              <VideoPlayIcon width={32} height={32} />
              <Stack>
                <Typography variant='h5_dark'>Earned {refer?.data?.rewardMin} Free mins!</Typography>
                <Typography variant='caption'>Go live, chat, engage!</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1.5} alignItems='center'>
              <ColorfulWalletIcon width={32} height={32} />
              <Stack>
                <Typography variant='h5_dark'>Earned ${refer?.data?.rewardMoney}</Typography>
                <Typography variant='caption'>To request a stream!</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <SocialShare open={openShareDialog} setOpen={setOpenShareDialog} text={REFERRAL_TEXT} url={BUNPUN_URL} />
    </>
  );
};

export default ReferEarnPage;
