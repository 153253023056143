import * as React from 'react';

function ShowPasswordIcon(props) {
  return (
    <svg
      viewBox='0 0 24 24'
      width={props.width || 24}
      height={props.height || 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.53 9.47a3.576 3.576 0 10-5.06 5.06M5.6 17.76c-1-.86-1.91-1.92-2.71-3.17-.9-1.41-.9-3.78 0-5.19 1.18-1.85 2.62-3.3 4.23-4.27M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73M8.42 19.53c1.14.48 2.35.74 3.58.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-.33-.52-.69-1.01-1.06-1.47M15.51 12.7a3.565 3.565 0 01-2.82 2.82'
        stroke='#292D32'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ShowPasswordIcon;
