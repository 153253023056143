import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import api from '../../config/api';
import ROUTES from '../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

const EditBankAccountForm = ({ bank }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      accNo: bank?.accNo,
      re_accNo: bank?.accNo,
      code: bank?.code,
      accHolderName: bank?.accHolderName,
      bankName: bank?.bankName,
    },
  });
  const onSubmit = async (value) => {
    const { accNo, re_accNo, code, accHolderName, bankName } = value;
    if (accNo !== re_accNo) {
      setError('re_accNo', { message: 'Account Number does not match' });
    } else {
      try {
        setIsLoading(true);

        await api.put(`/api/user/bank/${id}`, {
          bankName,
          accNo,
          code,
          accHolderName,
        });

        setIsLoading(false);
        navigate(ROUTES.EDIT_BANK_ACCOUNT.replace(':id', id));
      } catch (e) {
        setIsLoading(false);
        setError('IFSCCode', { message: 'Invalid IFSC Code' });
      }
    }
  };
  if (isLoading) return <Loading />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <MenuHeader title={'Edit Bank Account'} />
        <Stack spacing={1.5}>
          <CustomNumberField
            name={'accNo'}
            label={'Account Number'}
            control={control}
            rules={{ required: 'Account Number is required' }}
          />
          <CustomNumberField
            name={'re_accNo'}
            label={'Re-enter Account Number'}
            control={control}
            rules={{ required: 'Account Number is required' }}
          />
          <CustomTextField
            name={'code'}
            label={'Bank Code'}
            control={control}
            rules={{ required: 'Bank Code is required' }}
          />
          <CustomTextField
            name={'accHolderName'}
            label={'Account Holder Name'}
            control={control}
            rules={{ required: 'Account Holder Name is required' }}
          />
          <CustomTextField
            name={'bankName'}
            label={'Bank Name'}
            control={control}
            rules={{ required: 'Bank Name is required' }}
          />
        </Stack>
        <Button variant='contained' size='large' fullWidth rounded type='submit'>
          Save Changes
        </Button>
      </Stack>
    </form>
  );
};

export default EditBankAccountForm;
