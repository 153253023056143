import React from 'react';
import Stack from '@mui/material/Stack';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import ChatBox from '../ChatBox/ChatBox';
import CustomTextField from '../CustomTextField/CustomTextField';
import SendIcon from '../../assets//icons/SendIcon';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '../../config/api';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import useGetChats from '../../hooks/useGetChats';

const sendChat = async ({ taskId, message }) => {
  await api.post(`/api/user/task/${taskId}/chat`, { text: message });
};
const getChats = async ({ queryKey, pageParam = 2 }) => {
  console.log('queryKey', queryKey);
  const [_key, taskId] = queryKey;
  const { data } = await api.get(`/api/user/task/${taskId}/chat?page=${pageParam}&size=5`);
  return data;
};

const ChatBottomDrawer = ({ open, setOpen, taskId, type = 'Live' }) => {
  // const { data: chats } = useGetChats(taskId);

  const { data, ...rest } = useGetChats(taskId);

  const chats = useInfiniteQueryReducedResult(data);
  const { control, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();

  const { mutate: sendChatMutate } = useMutation({
    mutationFn: sendChat,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['chats', taskId] });
    },
  });
  console.log(chats);

  const onMessageSend = async (value) => {
    sendChatMutate({ taskId, message: value.message });
    reset({ message: '' });
  };
  return (
    <BottomDrawer bottomDrawerState={open} setBottomDrawerState={setOpen}>
      <Stack spacing={3} sx={{ maxHeight: '20rem', overflowY: 'auto' }}>
        <Typography variant='h5_bold' sx={{ textAlign: 'center' }}>
          Comments
        </Typography>
        <Stack
          spacing={0.6}
          sx={{
            overflowY: 'scroll',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
          }}
        >
          {chats?.map((chat, index) => (
            <ChatBox
              chat={chat}
              name={chat?.user.name}
              avatarUrl={chat?.user?.profile?.avatarUrl}
              taskId={taskId}
              variant={'light'}
              type={type}
              key={index}
              index={index}
              totalCount={chats?.length}
              {...rest}
            />
          ))}
        </Stack>
        <form onSubmit={handleSubmit(onMessageSend)}>
          <CustomTextField
            name={'message'}
            control={control}
            type={'text'}
            endAdornment={
              <InputAdornment position={'end'}>
                <IconButton type={'submit'}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
            backgroundColor='#fff'
            borderColor='#1111114D'
            color='#000'
            borderRadius='8px'
            fontSize='11px'
          />
        </form>
      </Stack>
    </BottomDrawer>
  );
};

export default ChatBottomDrawer;
