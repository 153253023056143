import * as React from 'react';

const VideoPlayIcon = (props) => (
  <svg
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 16}
    height={props.height || 16}
    {...props}
  >
    <path
      fill='#FDCC0A'
      d='M29.334 10.413v11.174c0 4.853-2.894 7.746-7.747 7.746H10.414c-4.854 0-7.747-2.893-7.747-7.746V10.413c0-.68.053-1.333.173-1.933.68-3.667 3.387-5.8 7.52-5.813h11.28c4.134.013 6.84 2.146 7.52 5.813.12.6.174 1.253.174 1.933'
      opacity={0.4}
    />
    <path
      fill='#111'
      d='M29.334 10.413v.067H2.667v-.067c0-.68.053-1.333.173-1.933h7.52V2.667h2V8.48h7.28V2.667h2V8.48h7.52c.12.6.174 1.253.174 1.933M19.255 16.96l-2.773-1.6c-1.027-.587-2.013-.667-2.787-.227-.773.44-1.2 1.347-1.2 2.52v3.2c0 1.173.427 2.08 1.2 2.52.333.187.707.28 1.094.28.533 0 1.106-.173 1.693-.507l2.773-1.6c1.027-.586 1.587-1.413 1.587-2.306 0-.894-.573-1.68-1.587-2.28'
    />
  </svg>
);
export default VideoPlayIcon;
