import * as React from 'react';

const GalleryRemoveIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M36.667 23.167v3.817c0 6.066-3.617 9.683-9.684 9.683H13.017c-4.25 0-7.317-1.783-8.75-4.95l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.467c1.3 1.116 3.4 1.116 4.7 0l6.933-5.95c1.3-1.117 3.4-1.117 4.7 0z'
    />
    <path
      fill='#FDCC0A'
      d='M34.95 13.334h-4.9c-2.117 0-3.383-1.267-3.383-3.384v-4.9c0-.667.133-1.233.366-1.716H13.018c-6.067 0-9.683 3.616-9.683 9.683v13.967c0 1.816.316 3.4.933 4.733l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.466c1.3 1.117 3.4 1.117 4.7 0l6.933-5.95c1.3-1.116 3.4-1.116 4.7 0l2.717 2.334v-10.2c-.483.233-1.05.367-1.717.367'
      opacity={0.4}
    />
    <path fill='#FDCC0A' d='M15 17.3a3.967 3.967 0 1 0 0-7.933 3.967 3.967 0 0 0 0 7.933' />
    <path
      fill='#292D32'
      d='M34.95 1.667h-4.9c-2.117 0-3.384 1.266-3.384 3.383v4.9c0 2.117 1.267 3.383 3.384 3.383h4.9c2.116 0 3.383-1.267 3.383-3.383v-4.9c0-2.117-1.267-3.383-3.383-3.383m.833 7.6c.417.416.417 1.1 0 1.516a1.113 1.113 0 0 1-1.517 0L32.5 9.017l-1.75 1.766a1.11 1.11 0 0 1-1.517 0 1.076 1.076 0 0 1 0-1.516L31 7.5l-1.767-1.75a1.076 1.076 0 0 1 0-1.517 1.076 1.076 0 0 1 1.517 0L32.5 6l1.767-1.767a1.076 1.076 0 0 1 1.516 0c.417.417.417 1.1 0 1.517L34.016 7.5z'
    />
  </svg>
);
export default GalleryRemoveIcon;
