import { Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTextField from '../CustomTextField/CustomTextField';

const CustomTextFeildWithLabel = ({ name, control, label, rules }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6_semibold" sx={{ color: '#1A1B1F66' }}>
        {label}
      </Typography>
      <CustomTextField
        name={name}
        control={control}
        label={label}
        rules={rules}
      />
    </Stack>
  );
};

export default CustomTextFeildWithLabel;
