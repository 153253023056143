import { useQuery } from '@tanstack/react-query';
import api from '../config/api';
import { LIVE_STREAM_STATUS } from '../utils/constant';

const liveStreamStatus = async (streamId) => {
  const { data } = await api.get(`/api/stream/${streamId}/state`);
  return data.data.live_stream.state;
};

const useLiveStreamState = (streamId) => {
  return useQuery({
    queryKey: ['start_live'],
    queryFn: () => liveStreamStatus(streamId),
    refetchOnWindowFocus: false,
    refetchInterval: ({ state }) => {
      const res = state?.data?.data;
      if (res?.live_stream?.state === LIVE_STREAM_STATUS.STOPPED) {
        return false;
      }
      return 2000;
    },
    enabled: !!streamId,
  });
};

export default useLiveStreamState;
