// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.slick-slider {*/
/*    width: 100%;*/
/*}*/

/*.slick-slider li {*/
/*    width: 4px;*/
/*}*/

/*.slick-slider .slick-dots li button:before {*/
/*    font-size: 10px;*/
/*}*/
.swiper-pagination-bullet-active {
    color: #fff;
    background: #fae80a !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/GetStartedPage/style.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB,mBAAmB;AACnB,IAAI;;AAEJ,qBAAqB;AACrB,kBAAkB;AAClB,IAAI;;AAEJ,+CAA+C;AAC/C,uBAAuB;AACvB,IAAI;AACJ;IACI,WAAW;IACX,8BAA8B;AAClC","sourcesContent":["/*.slick-slider {*/\n/*    width: 100%;*/\n/*}*/\n\n/*.slick-slider li {*/\n/*    width: 4px;*/\n/*}*/\n\n/*.slick-slider .slick-dots li button:before {*/\n/*    font-size: 10px;*/\n/*}*/\n.swiper-pagination-bullet-active {\n    color: #fff;\n    background: #fae80a !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
