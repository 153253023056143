import dayjs from 'dayjs';

const generateCalendar = (month = dayjs().month(), year = dayjs().year()) => {
  const firstDay = dayjs().year(year).month(month).startOf('month');
  const lastDay = dayjs().year(year).month(month).endOf('month');
  const days = [];
  const todayDate = dayjs();
  const lastDate = dayjs().add(90, 'day');

  // previous month
  for (let i = 0; i < firstDay.day(); i++) {
    days.push({ currentMonth: false, date: firstDay.day(i) });
  }

  // current month
  for (let i = firstDay.date(); i <= lastDay.date(); i++) {
    days.push({
      currentMonth: true,
      date: firstDay.date(i),
      today: firstDay.date(i).toDate().toDateString() === dayjs().toDate().toDateString(),
    });
  }

  // next month
  const remainingDays = 35 - days.length;
  for (let i = lastDay.date() + 1; i <= lastDay.date() + remainingDays; i++) {
    days.push({ currentMonth: false, date: lastDay.date(i) });
  }

  return days;
};

export default generateCalendar;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
