import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Avatar, Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import getInitials from '../../utils/getInitials';
import ROUTES from '../../routes';

const MyRequestProposalCard = ({ proposal }) => {
  const navigate = useNavigate();
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const MY_REQUEST_PROPOSAL_LINK = ROUTES.MY_REQUEST_PROPOSAL.replace(':proposalId', proposal?._id).replace(
    ':taskId',
    proposal?.task,
  );
  return (
    <Card sx={{ backgroundColor: '#f6f6f6', mb: 1 }}>
      <CardContent>
        <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'flex-start'} width={'100%'}>
          <Avatar
            sx={{ width: '80px', height: '80px', fontSize: '2rem', fontWeight: '600', borderRadius: '12px' }}
            src={`${S3_BUCKET}/${proposal?.user?.profile?.avatarUrl}`}
          >
            {getInitials(proposal?.user.name)}
          </Avatar>
          <Stack spacing={2} width={'100%'}>
            <Stack spacing={1}>
              <Typography variant={'h5_bold'}>{proposal?.user?.name}</Typography>
            </Stack>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography variant='h6'>
                Amount: <Typography variant='h6_dark'>${proposal?.amount}</Typography>
              </Typography>
              <Button
                variant={'contained'}
                sx={{ borderRadius: 50, fontSize: '12px' }}
                onClick={() => navigate(MY_REQUEST_PROPOSAL_LINK)}
              >
                Review
              </Button>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MyRequestProposalCard;
