import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import NoStreamerImage from '../../assets/images/NoStreamerImage';

const NoStreamerCard = () => {
  return (
    <Stack
      spacing={1.5}
      sx={{
        borderRadius: '20px',
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        p: '20px',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '160px', height: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <NoStreamerImage />
      </Box>

      <Typography variant='h5_dark'>No data available yet</Typography>
    </Stack>
  );
};

export default NoStreamerCard;
