import { Dialog } from '@mui/material';
import React from 'react';
import Paper from '@mui/material/Paper';

const CustomDialog = ({ handleClose, open, children, ...rest }) => {
  return (
    // <Container maxWidth='xs' mx={2.5}>
    <Dialog
      onClose={handleClose}
      open={open}
      // hideBackdrop={true}
      sx={{ p: 2 }}
      scroll={'body'}
      PaperProps={{
        sx: {
          width: '100%',
          m: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          // display: 'none',
        },
      }}
      fullWidth
      maxWidth='xs'
      {...rest}
    >
      <Paper
        elevation={3}
        sx={{
          mx: { xs: 1, sm: 2.5 },
          my: 0,
          mb: 2,
          p: '40px 32px',
          borderRadius: 3,
          // p: 2.5,
          backgroundColor: '#fff',
        }}
      >
        {children}
      </Paper>
    </Dialog>
    // </Container>
  );
};

export default CustomDialog;
