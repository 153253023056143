import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EyeIcon from '../../assets/icons/EyeIcon';
import LikeIcon from '../../assets/icons/LikeIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import dayjs from 'dayjs';

const VideoDescriptionTag = ({ duration = null, viewers = null, likes = null, chats = null }) => {
  return (
    <>
      <Stack
        direction='row'
        gap={0}
        sx={{
          position: 'absolute',
          top: 5,
          left: 5,
          // backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backgroundColor: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,47,50,1) 50%, rgba(116,121,122,1) 100%)',
          borderRadius: '10px',
          gap: 1,
          // width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {viewers !== null ? (
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant='caption_bold'>{viewers}</Typography>
          </Stack>
        ) : null}
        {likes !== null ? (
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <LikeIcon size={16} />
            <Typography variant='caption_bold'>{likes}</Typography>
          </Stack>
        ) : null}
        {chats !== null ? (
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <MessageIcon size={16} />
            <Typography variant='caption_bold'>{chats}</Typography>
          </Stack>
        ) : null}
      </Stack>

      {duration !== null ? (
        <Box
          sx={{
            position: 'absolute',
            right: 5,
            bottom: 5,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            p: '3px 4px',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ color: '#fff' }} variant='h6_semibold'>
            {dayjs.duration(duration).format('mm:ss')}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default VideoDescriptionTag;
