import React from 'react';

function FilledHeartIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        fill='red'
        stroke='red'
        d='M14.166 5.793a6.63 6.63 0 01-.22 1.707H2.054a6.627 6.627 0 01-.221-1.707A3.216 3.216 0 015.04 2.566 3.2 3.2 0 017.6 3.853l.4.535.4-.535a3.2 3.2 0 012.56-1.287 3.216 3.216 0 013.206 3.227z'
      ></path>
      <path
        fill='red'
        d='M14.32 8c-1.054 3.333-4.3 5.327-5.907 5.873-.227.08-.6.08-.827 0C5.98 13.327 2.733 11.333 1.68 8h12.64z'
      ></path>
    </svg>
  );
}

export default FilledHeartIcon;
