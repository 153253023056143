import * as React from 'react';

const EditPenIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      stroke='#111'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d='m11.58 6.78.893-.946c.947-1 1.373-2.14-.1-3.534C10.9.914 9.786 1.4 8.84 2.4L3.366 8.194c-.206.22-.406.653-.446.953l-.247 2.16c-.087.78.473 1.313 1.247 1.18l2.146-.367c.3-.053.72-.273.927-.5l2.633-2.786'
    />
    <path
      stroke='#111'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d='M7.927 3.367A4.084 4.084 0 0 0 11.56 6.8M2 14.666h7.333'
    />
    <path
      stroke='#292D32'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.25}
      d='M12 14.666h2'
    />
  </svg>
);
export default EditPenIcon;
