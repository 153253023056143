import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signalingURL: '',
  applicationName: '',
  streamName: '',
  streamInfo: undefined,
  audioBitrate: '64',
  audioCodec: 'opus',
  audioTrack: {},
  audioTrackDeviceId: '',
  videoBitrate: '3500',
  videoTrack: {},
  videoTrack1DeviceId: '',
  videoTrack2DeviceId: '',
  videoCodec: '42e01f',
  videoFrameRate: '30',
  videoFrameSize: 'default',
  userData: undefined,
  publishStart: false,
  publishStarting: false,
  publishStop: false,
  publishStopping: false,
};

export const publishSettingsSlice = createSlice({
  name: 'publishSettings',
  initialState,
  reducers: {
    setPublishSignalingURL(state, action) {
      state.signalingURL = action.payload;
    },
    setPublishApplicationName(state, action) {
      state.applicationName = action.payload;
    },
    setPublishStreamName(state, action) {
      state.streamName = action.payload;
    },
    setPublishStreamInfo(state, action) {
      state.streamInfo = action.payload;
    },
    setPublishAudioBitrate(state, action) {
      state.audioBitrate = action.payload;
    },
    setPublishAudioCodec(state, action) {
      state.audioCodec = action.payload;
    },
    setPublishAudioTrack(state, action) {
      state.audioTrack = action.payload;
    },
    setPublishAudioTrackDeviceId(state, action) {
      state.audioTrackDeviceId = action.payload;
    },
    setPublishVideoBitrate(state, action) {
      state.videoBitrate = action.payload;
    },
    setPublishVideoCodec(state, action) {
      state.videoCodec = action.payload;
    },
    setPublishVideoFrameSizeAndRate(state, action) {
      if (action.payload.videoFrameSize != null) state.videoFrameSize = action.payload.videoFrameSize;
      if (action.payload.videoFrameRate != null) state.videoFrameRate = action.payload.videoFrameRate;
    },
    setPublishVideoTrack(state, action) {
      state.videoTrack = action.payload;
    },
    setPublishVideoTrack1DeviceId(state, action) {
      state.videoTrack1DeviceId = action.payload;
    },
    setPublishVideoTrack2DeviceId(state, action) {
      state.videoTrack2DeviceId = action.payload;
    },
    setPublishUserData(state, action) {
      state.userData = action.payload;
    },
    setPublishFlags(state, action) {
      state.publishStart = action.payload.publishStart;
      state.publishStarting = action.payload.publishStarting;
      state.publishStop = action.payload.publishStop;
      state.publishStopping = action.payload.publishStopping;
    },
    toggleVideoEnabled(state) {
      state.videoTrack.enabled = !state.videoTrack.enabled;
    },
    toggleAudioEnabled(state) {
      state.audioTrack.enabled = !state.audioTrack.enabled;
    },
  },
});

export const {
  setPublishSignalingURL,
  setPublishApplicationName,
  setPublishStreamName,
  setPublishStreamInfo,
  setPublishAudioBitrate,
  setPublishAudioCodec,
  setPublishAudioTrack,
  setPublishAudioTrackDeviceId,
  setPublishVideoBitrate,
  setPublishVideoCodec,
  setPublishVideoFrameSizeAndRate,
  setPublishVideoTrack,
  setPublishVideoTrack1DeviceId,
  setPublishVideoTrack2DeviceId,
  setPublishUserData,
  setPublishFlags,
  toggleVideoEnabled,
  toggleAudioEnabled,
} = publishSettingsSlice.actions;

export default publishSettingsSlice.reducer;
