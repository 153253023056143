import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getProposal = async (proposalId) => {
  try {
    const { data } = await api.get(`/api/user/proposal/${proposalId}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
const useGetProposal = (proposalId) => {
  return useQuery({
    queryKey: ['proposal'],
    queryFn: () => getProposal(proposalId),
  });
};

export default useGetProposal;
