import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getViewers = async (streamId) => {
  const { data } = await api.get(`/api/stream/viewers/${streamId}`);
  return data.data.live_stream.viewers;
};
const useGetViewers = (streamId, isLiveStreamStarted = true) => {
  return useQuery({
    queryKey: ['viewers', streamId],
    queryFn: () => getViewers(streamId),
    refetchInterval: 5000,
    enabled: !!streamId && isLiveStreamStarted,
  });
};
export default useGetViewers;
