import { useMemo } from 'react';

const useInfiniteQueryReducedResult = (data) => {
  return useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      if (page?.data?.totalCount > 0) return [...acc, ...page?.data?.result];
      return [];
    }, []);
  }, [data]);
};

export default useInfiniteQueryReducedResult;
