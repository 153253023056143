import * as React from 'react';
import { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DiscoverActiveIcon from '../../assets/icons/DiscoverActiveIcon';
import HomeActiveIcon from '../../assets/icons/HomeActiveIcon';
import PlayCircleActiveIcon from '../../assets/icons/PlayCircleActiveIcon';
import ProfileCircleActiveIcon from '../../assets/icons/ProfileCircleActiveIcon';
import Container from '@mui/material/Container';
import PlusIcon from '../../assets/icons/PlusIcon';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import CalenderIcon from '../../assets/icons/CalenderIcon';
import HomeInactiveIcon from '../../assets/icons/HomeInactiveIcon';
import DiscoverInactiveIcon from '../../assets/icons/DiscoverInactiveIcon';
import PlayCircleInActiveIcon from '../../assets/icons/PlayCircleInActiveIcon';
import ProfileCircleInactiveIcon from '../../assets/icons/ProfileCircleInactiveIcon';
import { signal } from '@preact/signals-react';
import GoLiveIcon from '../../assets/icons/GoLiveIcon';
import { Box, CircularProgress } from '@mui/material';
import VideoPlayIcon from '../../assets/icons/VideoPlayIcon';
import useGetWallet from '../../hooks/useGetWallet';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';
import LoginModal from '../LoginModal/Login';
import isGuestUser from '../../hooks/isGuestUser';

export const BottomDrawerBox = ({ icon, title, subtitle, path, setBottomDrawerState, setOpenLoginModal }) => {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1.2}
      direction='row'
      sx={{
        p: '16px 20px',
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        cursor: 'pointer',
      }}
      alignItems={'center'}
      onClick={() => {
        if (isGuestUser()) {
          setOpenLoginModal(true);
        } else {
          setBottomDrawerState(false);
          navigate(path);
        }
      }}
    >
      {icon}
      <Stack>
        <Typography variant={'h5_dark'}>{title}</Typography>
        <Typography variant={'caption'} sx={{ color: '#111111B2' }}>
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const isShowBottomDrawer = signal(false);

export default function CustomBottomNavigation() {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [bottomDrawerState, setBottomDrawerState] = React.useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const bottomNavigations = React.useMemo(
    () => [
      {
        icon: pathname === ROUTES.WELCOME ? <HomeActiveIcon /> : <HomeInactiveIcon />,
        label: 'Home',
        value: ROUTES.WELCOME + '?isBottomNavigation',
      },
      {
        icon: pathname.includes('discover') ? <DiscoverActiveIcon /> : <DiscoverInactiveIcon />,
        label: 'Discover',
        value: ROUTES.DISCOVER.replace(':discoverType', 0) + '?isBottomNavigation',
      },
      {
        icon: <PlusIcon />,
        label: '',
      },
      {
        icon: pathname === ROUTES.LIVE_ALL ? <PlayCircleActiveIcon /> : <PlayCircleInActiveIcon />,
        label: 'Live',
        value: ROUTES.LIVE_ALL + '?isBottomNavigation',
      },
      {
        icon: pathname === ROUTES.PROFILE ? <ProfileCircleActiveIcon /> : <ProfileCircleInactiveIcon />,
        label: 'Profile',
        value: ROUTES.PROFILE + '?isBottomNavigation',
      },
    ],
    [pathname],
  );

  const { data: wallet, isLoading } = useGetWallet();

  const bottomDrawerBoxStyle = {
    p: '16px 20px',
    borderRadius: 1,
    border: (theme) => `1px solid ${theme.palette.border.main}`,
    cursor: 'pointer',
  };

  const handleBottomNavigation = (newVal) => {
    if (newVal === 2) {
      setBottomDrawerState(true);
    } else {
      isGuestUser() ? setOpenLoginModal(true) : navigate(newVal);
    }
  };

  return (
    <>
      <Container
        maxWidth='xs'
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          p: '0 !important',
          ml: { sm: '-24px', xs: '-16px' },
          borderTop: (theme) => `0.5px solid ${theme.palette.primary.main}`,
          zIndex: 99,
        }}
      >
        <BottomNavigation
          showLabels
          value={pathname}
          onChange={(event, newValue) => {
            handleBottomNavigation(newValue);
          }}
          sx={{
            '&.MuiBottomNavigationAction-label.Mui-selected': {
              color: '#000 !important',
              '&.MuiBottomNavigationAction-label': {
                color: 'black',
              },
            },
          }}
        >
          {bottomNavigations.map((bottomNavigation) => (
            <BottomNavigationAction key={bottomNavigation.label} {...bottomNavigation} sx={{ color: '#000' }} />
          ))}
        </BottomNavigation>
      </Container>
      <BottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState}>
        <Stack spacing={1.5}>
          <Box
            sx={{ ...bottomDrawerBoxStyle, display: 'flex', justifyContent: 'space-between' }}
            onClick={() => {
              if (isGuestUser()) {
                setOpenLoginModal(true);
              } else {
                logEvent(analytics, 'go_live_click', { name: 'go_live' });
                setBottomDrawerState(false);
                navigate(ROUTES.LIVE_FORM);
              }
            }}
          >
            <Box display='flex' justifyContent='space-between' gap={1.2}>
              <GoLiveIcon />
              <Stack>
                <Typography variant='h5_dark'>Go Live</Typography>
                <Typography variant='caption' sx={{ color: '#111111B2' }}>
                  Go live right now
                </Typography>
              </Stack>
            </Box>
            {wallet?.data?.rewardMin > 0 && (
              <Stack spacing={0.5} justifyContent='center' alignItems='center'>
                <VideoPlayIcon />
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant='caption' sx={{ color: '#111111B2' }}>
                    {Math.trunc(wallet?.data?.rewardMin)} free minutes
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
          <BottomDrawerBox
            icon={<CalenderIcon />}
            title={'Request a Stream'}
            subtitle={'Schedule a live show for the future date'}
            path={ROUTES.REQUEST_VIDEO_FORM}
            setBottomDrawerState={setBottomDrawerState}
            setOpenLoginModal={setOpenLoginModal}
          />
        </Stack>
      </BottomDrawer>
      <LoginModal open={openLoginModal} handleClose={() => setOpenLoginModal(false)} />
    </>
  );
}
