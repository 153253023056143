import React from 'react';
import Stack from '@mui/material/Stack';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import api from '../../config/api';
import { useQuery } from '@tanstack/react-query';
import { Box, Typography } from '@mui/material';
import VideoIcon from './icons/VideoIcon';
import dayjs from 'dayjs';
import WalletIcon from './icons/WalletIcon';
import RequestStreamIcon from './icons/RequestStreamIcon';
import StarIcon from './icons/StarIcon';
import ContactIcon from './icons/ContactIcon';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import ROUTES from '../../routes';
import { MY_TASK_TYPE, NOTIFICATION_TYPE } from '../../utils/constant';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

const getNotificationMessage = ({ notificationType, notification }) => {
  switch (notificationType) {
    case NOTIFICATION_TYPE.WELCOME:
      return (
        <Stack direction='row' spacing={3} alignItems='center' component='a' href={ROUTES.WELCOME}>
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <StarIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              Thank you for joining bunpun, <Typography variant='h5_dark'>Happy bunpun!</Typography>
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.BOOKING_REQUEST_PAYMENT:
      return (
        <Stack direction='row' spacing={3} alignItems='center' component='a' href={ROUTES.WALLET}>
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RequestStreamIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              Your payment of <Typography variant='h5_dark'>${notification.data.task.amount} </Typography>
              for stream <Typography variant='h5_dark'>${notification.data.task.title} </Typography>
              it has been processed.
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.BOOK_INTIMATION_STREAMER:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.SCHEDULE_LIVE.replace(':taskId', notification.data.task._id).replace(
            ':proposalId',
            notification?.data?.proposal?._id,
          )}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.user.name}</Typography> has requested a stream from you.
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.GO_LIVE_PUBLIC:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component={notification.data.task?.streamStatus !== 'STOPPED' && 'a'}
          href={ROUTES.LIVE_PLAY.replace(':taskId', notification.data.task?._id)}
          onClick={() => {
            toast.info(
              `Ohh you missed! This live streaming was ended on ${dayjs(notification.data.task.streamStartTime).format('DD MMM YYYY, hh:mm A')}`,
            );
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.user.name}</Typography> has just started a live stream !
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.STREAM_MESSAGE:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.CHAT_USER.replace(':taskId', notification?.data?.task?._id)}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.user.name}</Typography> has messaged you
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.STREAM_STARTED:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.LIVE_PLAY.replace(':taskId', notification.data.task._id)}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.task.accepted.user.name}</Typography> has started a live
              stream
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.STREAM_REMAINDER:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.CHAT_USER.replace(':taskId', notification?.data?.accepted?.task?._id)}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'> Upcoming Live Stream:</Typography> Your scheduled live stream starts in 20
              minutes.
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.STREAM_ACCEPTED:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.MY_TASK.replace(':taskType', MY_TASK_TYPE.ACCEPTED)}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.user.name}</Typography> has accepted your stream
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.WALLET_MONEY_DEPOSIT:
      return (
        <Stack direction='row' spacing={3} alignItems='center' component='a' href={ROUTES.WALLET}>
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <WalletIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>${notification.data.amount}</Typography> has been successfully added to your
              wallet.
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.WALLET_MONEY_PAID_REQUESTER:
      return (
        <Stack direction='row' spacing={3} alignItems='center' component='a' href={ROUTES.WALLET}>
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <WalletIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              You have just earned
              <Typography variant='h5_dark'> ${notification.data.amount}</Typography> , credited to your wallet
              successfully
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.WALLET_MONEY_WITHDRAW:
      return (
        <Stack direction='row' spacing={3} alignItems='center' component='a' href={ROUTES.WALLET_WITHDRAW_MONEY}>
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <WalletIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>${notification.data.amount}</Typography> has been deposited to your bank
              account successfully
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.CREATE_PROPOSAL:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={ROUTES.MY_REQUEST_PROPOSAL.replace(':taskId', notification?.data?.task?._id).replace(
            ':proposalId',
            notification?.data?.proposal?._id,
          )}
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ContactIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>{notification.data.user.name}</Typography> has created proposal for
              <Typography variant='h5_dark'> {notification.data.task.title}</Typography>
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.RECORDED_VIDEO_COMMENT:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={
            ROUTES.RECORDING_PLAY +
            '?streamId=' +
            notification.data.task.streamDetails.liveStreamId +
            '&taskId=' +
            notification.data.task._id
          }
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>Hey {notification.data.user.name}</Typography> has commented on your video{' '}
              <Typography variant='h5_dark'>{notification.data.task.title}</Typography>
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.RECORDED_VIDEO_LIKE:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={
            ROUTES.RECORDING_PLAY +
            '?streamId=' +
            notification.data.task.streamDetails.liveStreamId +
            '&taskId=' +
            notification.data.task._id
          }
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>Hey {notification.data.user.name}</Typography> has liked your video{' '}
              <Typography variant='h5_dark'>{notification.data.task.title}</Typography>
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    case NOTIFICATION_TYPE.RECORDED_VIDEO_COMMENT_LIKE:
      return (
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          component='a'
          href={
            ROUTES.RECORDING_PLAY +
            '?streamId=' +
            notification.data.task.streamDetails.liveStreamIdeamId +
            '&taskId=' +
            notification.data.task._id
          }
        >
          <Box sx={{ width: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <VideoIcon />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant='h5'>
              <Typography variant='h5_dark'>Hey {notification.data.user.name}</Typography> has liked your comment
            </Typography>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.text.light }}>
              {dayjs(notification.createdAt).fromNow()}
            </Typography>
          </Stack>
        </Stack>
      );
    default:
      return null;
  }
};

const getNotifications = async () => {
  const { data } = await api.get(`/api/user/notification?page=1&size=10`);
  return data;
};

function NotificationItem({ notification }) {
  return (
    <Box
      sx={{
        p: '12px 20px',
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {getNotificationMessage({ notificationType: notification.type, notification })}
    </Box>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
};
const NotificationPage = () => {
  const { data: notifications } = useQuery({ queryKey: ['notifications'], queryFn: () => getNotifications() });
  ReactGA.send({ hitType: 'pageview', page: ROUTES.NOTIFICATIONS, title: 'Notification Page' });
  return (
    <Box sx={{ pb: 8 }}>
      <TopNavigation title={'Notifications'} />
      <TopNavigationSpace />
      <Stack spacing={2} mt={2.5}>
        {notifications?.data?.map((notification) => (
          <NotificationItem key={nanoid()} notification={notification} />
        ))}
      </Stack>
    </Box>
  );
};

export default NotificationPage;
