import * as React from 'react';

function RightArrowIcon(props) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.5 12h6M12.5 15l3-3-3-3'
        stroke={props.variant === 'light' ? '#D8D8D8' : '#111'}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 6c-1.25 1.67-2 3.75-2 6 0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2c-1.43 0-2.8.3-4.03.85'
        stroke={props.variant === 'light' ? '#D8D8D8' : '#111'}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default RightArrowIcon;
