import React from 'react';
import api from '../../../config/api';
import { REQUEST_CARD_HEIGHT } from '../../../utils/constant';
import { useInfiniteQuery } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';
import { Box } from '@mui/material';
import PendingRequestCard from './PendingRequestCard';
import useInfiniteQueryReducedResult from '../../../utils/useInfiniteQueryReducedResult';

const PendingRequestComponent = ({ setPendingRequestDetails }) => {
  const getPendingRequest = async ({ pageParam }) => {
    const { data } = await api.get(`/api/user/profile/task?filter=pending&page=${pageParam}&size=10`);
    setPendingRequestDetails &&
      setPendingRequestDetails((prev) => ({
        ...prev,
        height: data.data.totalCount * REQUEST_CARD_HEIGHT,
      }));
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['pendingRequests'],
    queryFn: getPendingRequest,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const pendingRequests = useInfiniteQueryReducedResult(data);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      {pendingRequests?.map((task, index) => (
        <PendingRequestCard
          key={task?._id}
          data={task}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          index={index}
          totalCount={pendingRequests?.length}
        />
      ))}
    </Box>
  );
};

export default PendingRequestComponent;
