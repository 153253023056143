import React from 'react';

function NotificationMessageIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.25'
        d='M1.666 6.668c0-3.333 1.667-5 5-5h6.667c3.333 0 5 1.667 5 5v4.167c0 3.333-1.667 5-5 5h-.417a.845.845 0 00-.667.333L11 17.835c-.55.733-1.45.733-2 0l-1.25-1.667c-.133-.183-.441-.333-.666-.333h-.417c-3.333 0-5-.834-5-5V10'
      ></path>
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.25'
        d='M13.326 6.668h.842M5.834 6.668h4.592'
      ></path>
      <path stroke='#292D32' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.25' d='M5.834 10.832h5'></path>
    </svg>
  );
}

export default NotificationMessageIcon;
