import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea';
import api from '../../config/api';
import { toast } from 'react-toastify';
import ROUTES from '../../routes';
import MyVideoLoading from '../../components/Loading/MyVideoLoading';
import checkCameraAndMicAccess from '../../utils/checkCameraAndMicAccess';

const LiveStreamFormPage = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (value) => {
    // navigate(`/live/testingCamera`);
    const hasAccess = await checkCameraAndMicAccess();
    if (!hasAccess) {
      toast.error('Please allow camera and microphone access to go live');
      return;
    }
    setIsLoading(true);
    try {
      // creating task
      const { data: task } = await api.post('/api/user/task', {
        title: value.title,
        description: value.description,
        type: 'GO_LIVE',
        // duration: 15,
        visible: 'PUBLIC',
      });
      const taskId = await task?.data?._id;
      // creating stream
      const { data: streamDetails } = await api.post(`api/stream?task=${taskId}`);
      const streamId = await streamDetails?.data?.streamDetails?.liveStreamId;
      // starting stream
      await api.put(`/api/stream/${streamId}/start`);
      navigate(ROUTES.LIVE.replace(':taskId', taskId).replace(':streamId', streamId));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  if (isLoading) return <MyVideoLoading />;
  return (
    <Box>
      <TopNavigation title={'Live'} />
      <TopNavigationSpace />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={1}>
          <Typography variant={'h3_dark'}>Go Live</Typography>
          <CustomTextField
            name={'title'}
            label={'Title'}
            control={control}
            rules={{ required: 'Title is required' }}
            type={'text'}
          />
          <CustomTextArea
            name={'description'}
            label={'Description'}
            control={control}
            rules={{ required: 'Description is required' }}
            type={'text'}
          />
          <Button variant='contained' type='submit'>
            Go Live
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default LiveStreamFormPage;
