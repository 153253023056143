import * as React from 'react';

function BrokenClockIcon(props) {
  return (
    <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.473 10.12L8.407 8.887c-.36-.214-.654-.727-.654-1.147V5.007'
        stroke='#000'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.667 4A6.67 6.67 0 008 14.667 6.67 6.67 0 0014.667 8a6.67 6.67 0 00-9.354-6.1'
        stroke='#000'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default BrokenClockIcon;
