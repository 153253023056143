import * as React from 'react';

const StarIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={64} height={64} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='m35.467 21.627 3.52 7.04c.48.96 1.76 1.92 2.827 2.08l6.373 1.066c4.08.694 5.04 3.627 2.107 6.56l-4.96 4.96c-.827.827-1.307 2.454-1.04 3.627l1.413 6.16c1.12 4.853-1.466 6.747-5.76 4.213l-5.973-3.546c-1.093-.64-2.853-.64-3.947 0l-5.973 3.546c-4.293 2.534-6.88.64-5.76-4.213l1.413-6.16c.267-1.147-.213-2.773-1.04-3.627l-4.96-4.96c-2.933-2.933-1.973-5.893 2.107-6.56l6.373-1.066c1.067-.187 2.347-1.12 2.827-2.08l3.52-7.04c1.893-3.814 5.04-3.814 6.933 0'
    />
    <path
      fill='#FDCC0A'
      d='M16 26c-1.093 0-2-.907-2-2V5.333c0-1.093.907-2 2-2s2 .907 2 2V24c0 1.093-.907 2-2 2'
      opacity={0.4}
    />
    <path
      fill='#292D32'
      d='M48 26c-1.093 0-2-.907-2-2V5.333c0-1.093.907-2 2-2s2 .907 2 2V24c0 1.093-.907 2-2 2M32 12.667c-1.093 0-2-.907-2-2V5.333c0-1.093.907-2 2-2s2 .907 2 2v5.334c0 1.093-.907 2-2 2'
      opacity={0.4}
    />
  </svg>
);
export default StarIcon;
