import React, { useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CustomAvatar from '../CustomAvatar/CustomAvatar';
import EmptyHeartIcon from '../../assets/icons/EmptyHeartIcon';
import api from '../../config/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useInfiniteScrollUtils from '../../utils/useInfiniteScrollUtils';
import FilledHeartIcon from '../../assets/icons/FilledHeartIcon';

const sendChatLike = async (chatId) => {
  await api.post(`/api/user/chat/${chatId}/like`);
};
const getIsChatLiked = async (taskId) => {
  const { data } = await api.get(`/api/user/task/${taskId}/chat/chat`);
  return data;
};

const ChatBox = ({ chat, taskId, variant, type, index, totalCount, ...rest }) => {
  const { isLiked, text: message, likes, _id: id } = chat;
  const name = chat?.user.name;
  const avatarUrl = chat?.user.profile.avatarUrl;
  const queryClient = useQueryClient();
  const { mutate: sendChatLikeMutate } = useMutation({
    mutationFn: () => sendChatLike(id),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['chats', taskId] });
      const previousChat = queryClient.getQueryData(['chats', taskId]);
      queryClient.setQueriesData({ queryKey: ['chats', taskId] }, (oldChat) => {
        return { ...oldChat, likes: oldChat.likes + 1 };
      });
      return { previousChat };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['chats', taskId], context?.previousChat);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['chats', taskId] });
    },
  });
  // const { data: isChatLiked } = useQuery({
  //   queryKey: ['isChatLiked', taskId],
  //   queryFn: () => getIsChatLiked(taskId),
  // });

  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({ ...rest, observer });
  console.log(totalCount);
  return (
    <Box
      sx={{ p: '6px 16px', display: 'flex', alignItems: 'center', gap: 1 }}
      ref={index === totalCount - 1 ? lastElementRef : null}
    >
      <CustomAvatar avatarUrl={avatarUrl} name={name} />
      <Stack spacing={0.25} flex={1}>
        <Typography variant='h7_dark' sx={{ color: variant === 'light' ? '#000' : '#fff' }}>
          {name}
        </Typography>
        <Typography variant='caption_semibold' sx={{ color: variant === 'light' ? '#000' : '#fff' }}>
          {message}
        </Typography>
      </Stack>
      {type === 'Recorded' ? (
        <Stack alignItems='center' onClick={() => sendChatLikeMutate(id)} sx={{ cursor: 'pointer' }}>
          {isLiked ? <FilledHeartIcon /> : <EmptyHeartIcon />}
          <Typography variant='caption_semibold'>{likes > 0 ? likes : null}</Typography>
        </Stack>
      ) : null}
    </Box>
  );
};

export default ChatBox;
