import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import SuccessIcon from '../../assets/icons/SuccessIcon';
import CustomDialog from '../CustomDialog/CustomDialog';

const SuccessModal = ({ open = false, handleClose, onClick, title, message, btnText }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <SuccessIcon />
        <Stack spacing={1} alignItems={'center'} textAlign={'center'}>
          <Typography variant='h5_dark'>{title}</Typography>
          <Typography variant='h6'>{message}</Typography>
        </Stack>
        <Button variant='contained' sx={{ width: '70%' }} onClick={onClick}>
          {btnText}
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default SuccessModal;
