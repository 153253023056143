import { useCallback } from 'react';

const useInfiniteScrollUtils = ({ isLoading, isFetching, hasNextPage, fetchNextPage, observer }) => {
  return useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading, observer],
  );
};

export default useInfiniteScrollUtils;
