import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const useLiveStream = (page = 1, size = 3) => {
  const getLiveStream = async ({ page, size }) => {
    const { data } = await api.get(`/api/stream?page=${page}&size=${size}&state=started`);
    return data;
  };
  return useQuery({ queryKey: ['liveStream', page, size], queryFn: () => getLiveStream({ page, size }) });
};

export default useLiveStream;
