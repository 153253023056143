import React, { useEffect, useRef } from 'react';
import LikeIcon from '../../assets/icons/LikeIcon';
import MessageIcon from '../../assets/icons/MessageIcon';
import ShareIcon from '../../assets/icons/ShareIcon';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import api from '../../config/api';
import { useForm, useWatch } from 'react-hook-form';
import useProfile from '../../hooks/useProfile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { socket } from '../../config/socket';
import CustomTextField from '../CustomTextField/CustomTextField';
import SendIcon from '../../assets/icons/SendIcon';
import ChatBox from '../ChatBox/ChatBox';
import SocialShare from '../SocialShare/SocialShare';
import useGetChats from '../../hooks/useGetChats';
import useSendLike from '../../hooks/useSendLike';
import LikeCommentShareBox from '../LikeCommentShareComponent/LikeCommentShareBox';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import useIsUserLiked from '../../hooks/useIsUserLiked';
import LikeIconFilled from '../../assets/icons/LikeIconFilled';
import GiftIcon from '../../assets/icons/GiftIcon';
import HeartIcon from '../../assets/icons/giftIcons/HeartIcon';
import CoinIcon from '../../assets/icons/giftIcons/CoinIcon';
import RankingIcon from '../../assets/icons/giftIcons/RankingIcon';

const sendChat = async ({ taskId, message }) => {
  await api.post(`/api/user/task/${taskId}/chat`, { text: message });
};

const sendGift = async ({ streamerId, giftType }) => {
  await api.post('api/stream/gift', { streamerId, [giftType]: 1 });
};

const PlayerSkin = ({ taskId, streamId, task, duration, isViewer = false }) => {
  const socialShareRef = useRef(null);
  const { handleSubmit, control, reset } = useForm();
  const [messageWatch] = useWatch({ control, name: ['message'] });
  const { data: profile } = useProfile();
  const { data, ...rest } = useGetChats(taskId);
  const chats = useInfiniteQueryReducedResult(data);
  const queryClient = useQueryClient();
  const { data: userIsLiked } = useIsUserLiked(taskId);
  const [isLiked, setIsLiked] = React.useState(userIsLiked?.data?.isLiked);
  const [showGift, setShowGift] = React.useState(false);
  useEffect(() => {
    setIsLiked(userIsLiked?.data?.isLiked);
  }, [userIsLiked?.data?.isLiked]);

  const { mutate: sendChatMutate } = useMutation({
    mutationFn: sendChat,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['chats', taskId] });
    },
  });
  const { mutate: sendLikeMutate } = useSendLike(taskId);

  const { mutate: sendGiftMutate } = useMutation({ mutationFn: sendGift });

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const handleSocialShareDialog = () => {
    setOpenShareDialog(!openShareDialog);
  };

  useEffect(() => {
    socket.connect();
    console.dir(socket);
    if (duration) socket.emit('join_room', { room: streamId, user: profile?.data?._id, duration: duration });
    else socket.emit('join_room', { room: streamId, user: profile?.data?._id });
    return () => {
      socket.disconnect();
    };
  }, [duration, profile?.data?._id, streamId]);

  useEffect(() => {
    socket.on('receive_message', async (data) => {
      console.log(data);
      await queryClient.invalidateQueries({ queryKey: ['chats', taskId] });
    });
    socket.on('receive_like', async (data) => {
      console.log(data);
      await queryClient.invalidateQueries({ queryKey: ['task', taskId] });
    });
    return () => {
      socket.off('receive_message');
      socket.off('receive_like');
    };
  }, [queryClient, taskId]);

  const onMessageSend = async (value) => {
    socket.emit('send_message', {
      userDetails: {
        name: profile?.data?.name,
        avatarUrl: profile?.data?.profile?.avatarUrl,
      },
      room: streamId,
      message: value.message,
    });
    console.log(value.message);
    sendChatMutate({ taskId, message: value.message });
    // await queryClient.invalidateQueries(['chats', taskId]);
    reset({ message: '' });
  };
  const onSendLike = () => {
    socket.emit('send_like', {
      userDetails: {
        name: profile?.data?.name,
        avatarUrl: profile?.data?.profile?.avatarUrl,
      },
      room: streamId,
    });
    sendLikeMutate(taskId);
  };
  let chatHeight =
    document.documentElement.scrollHeight - socialShareRef?.current?.getBoundingClientRect()?.bottom - 100;
  chatHeight = showGift && isViewer ? chatHeight - 50 : chatHeight;
  const LIVE_STREAM_INVITE = process.env.REACT_APP_LIVE_STREAM_INVITE.replace('user', profile?.data?.name);
  useEffect(() => {
    messageWatch?.length > 0 && setShowGift(false);
  }, [messageWatch?.length]);
  console.log(messageWatch?.length === 0 && isViewer);
  console.log('isViewer: ', isViewer);
  console.log('messageWatch?.length: ', messageWatch?.length);
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDirection={'column'}
        width={'fit-content'}
        justifyContent={'center'}
        gap={1}
        sx={{ position: 'absolute', right: 16, top: '50%', transform: 'translate(0, -50%)', zIndex: 11 }}
        ref={socialShareRef}
      >
        <LikeCommentShareBox
          Icon={isLiked ? LikeIconFilled : LikeIcon}
          value={task?.data?.likes}
          onClick={onSendLike}
        />
        <LikeCommentShareBox Icon={MessageIcon} value={task?.data?.chats} />
        <LikeCommentShareBox Icon={ShareIcon} onClick={handleSocialShareDialog} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: socialShareRef?.current?.getBoundingClientRect()?.bottom + 1,
          bottom: 24,
          left: { sm: 24, xs: 16 },
          right: { sm: 24, xs: 16 },
          zIndex: 10,
          overflow: 'hidden',
        }}
      >
        <Stack spacing={1.5}>
          <Stack
            spacing={0.6}
            sx={{
              maxHeight: chatHeight,
              height: chatHeight,
              overflowY: 'scroll',
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            }}
          >
            {chats?.map((chat, index) => (
              <ChatBox key={index} chat={chat} index={index} totalCount={chats?.length} {...rest} />
            ))}
          </Stack>
          {showGift && isViewer && (
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <IconButton onClick={() => sendGiftMutate({ streamerId: profile?.data?._id, giftType: 'hearts' })}>
                <HeartIcon />
              </IconButton>
              <IconButton onClick={() => sendGiftMutate({ streamerId: profile?.data?._id, giftType: 'coins' })}>
                <CoinIcon />
              </IconButton>
              <IconButton onClick={() => sendGiftMutate({ streamerId: profile?.data?._id, giftType: 'flowers' })}>
                <RankingIcon />
              </IconButton>
            </Stack>
          )}

          <form onSubmit={handleSubmit(onMessageSend)}>
            <CustomTextField
              name={'message'}
              control={control}
              type={'text'}
              endAdornment={
                <InputAdornment position={'end'}>
                  {messageWatch?.length > 0 && !isViewer ? (
                    <IconButton type={'submit'}>
                      <SendIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setShowGift(!showGift)}>
                      <GiftIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              backgroundColor='rgba(25, 25, 25, 0.4)'
              borderColor='rgba(255, 255, 255, 0.3)'
              color='#ffffff'
              borderRadius='8px'
            />
          </form>
        </Stack>
      </Box>
      <SocialShare
        open={openShareDialog}
        setOpen={handleSocialShareDialog}
        url={window.location.href}
        text={LIVE_STREAM_INVITE}
      />
    </>
  );
};

export default PlayerSkin;
