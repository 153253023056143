import React from 'react';

function SearchIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#797979'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M1.333 7.665a6.33 6.33 0 016.333-6.333M14 7.668a6.33 6.33 0 01-6.334 6.333 6.333 6.333 0 01-5.687-3.54M9.333 3.332h4M9.333 5.332h2M14.666 14.665l-1.333-1.333'
      ></path>
    </svg>
  );
}

export default SearchIcon;
