import * as React from 'react';

function ReferIcon(props) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        opacity={0.4}
        d='M12.978 15.415l-.008.001-.007.002c-.31.054-.63.082-.963.082-.332 0-.653-.028-.963-.082l-.008-.002h-.007a6.485 6.485 0 01-4.437-2.805A6.468 6.468 0 015.5 9c0-3.594 2.906-6.5 6.5-6.5s6.5 2.906 6.5 6.5c0 1.348-.4 2.582-1.085 3.611a6.485 6.485 0 01-4.437 2.804z'
        stroke='#111'
      />
      <path
        d='M21.25 18.468l-1.65.39c-.37.09-.66.37-.74.74l-.35 1.47a1 1 0 01-1.74.41L12 15.998l-4.77 5.49a1 1 0 01-1.74-.41l-.35-1.47a.996.996 0 00-.74-.74l-1.65-.39a1.003 1.003 0 01-.48-1.68l3.9-3.9a6.986 6.986 0 004.78 3.02c.34.06.69.09 1.05.09.36 0 .71-.03 1.05-.09 1.99-.29 3.7-1.42 4.78-3.02l3.9 3.9c.55.54.28 1.49-.48 1.67zM12.58 5.98l.59 1.18c.08.16.29.32.48.35l1.07.18c.68.11.84.61.35 1.1l-.83.83c-.14.14-.22.41-.17.61l.24 1.03c.19.81-.24 1.13-.96.7l-1-.59a.701.701 0 00-.66 0l-1 .59c-.72.42-1.15.11-.96-.7l.24-1.03c.04-.19-.03-.47-.17-.61l-.83-.83c-.49-.49-.33-.98.35-1.1l1.07-.18c.18-.03.39-.19.47-.35l.59-1.18c.29-.64.81-.64 1.13 0z'
        fill='#292D32'
      />
    </svg>
  );
}

export default ReferIcon;
