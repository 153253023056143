import React from 'react';
import api from '../../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';
import { Box } from '@mui/material';
import PendingTaskCard from './PendingTaskCard';
import { TASK_CARD_HEIGHT } from '../../../utils/constant';
import useInfiniteQueryReducedResult from '../../../utils/useInfiniteQueryReducedResult';

const PendingTaskComponent = ({ setPendingTaskDetails }) => {
  const getPendingTasks = async ({ pageParam }) => {
    const { data } = await api.get(`/api/user/profile/proposal?filter=pending&page=${pageParam}&size=10`);
    setPendingTaskDetails((prev) => ({ ...prev, height: data.data.totalCount * TASK_CARD_HEIGHT }));
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['pendingTasks'],
    queryFn: getPendingTasks,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const pendingTasks = useInfiniteQueryReducedResult(data);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      {pendingTasks
        ? pendingTasks?.map((task, index) => (
            <PendingTaskCard
              key={task?._id}
              data={task}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              index={index}
              totalCount={pendingTasks?.length}
            />
          ))
        : null}
    </Box>
  );
};

export default PendingTaskComponent;
