import * as React from 'react';

function BackIcon(props) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path opacity={0.4} d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z' fill='#000' />
      <path
        d='M16.2 11.1H9.972l2.064-2.064a.905.905 0 000-1.272.905.905 0 00-1.272 0l-3.6 3.6a.905.905 0 000 1.272l3.6 3.6a.89.89 0 00.636.264.89.89 0 00.636-.264.905.905 0 000-1.272L9.972 12.9H16.2c.492 0 .9-.408.9-.9 0-.492-.408-.9-.9-.9z'
        fill='#fff'
      />
    </svg>
  );
}

export default BackIcon;
