import React from 'react';

function RankingIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none' viewBox='0 0 60 60'>
      <path
        fill='#FDCC0A'
        d='M33.25 20.275l3.3 6.6c.45.9 1.65 1.8 2.65 1.95l5.975 1c3.825.65 4.725 3.4 1.975 6.15l-4.65 4.65c-.775.775-1.225 2.3-.975 3.4L42.85 49.8c1.05 4.55-1.375 6.325-5.4 3.95l-5.6-3.325c-1.025-.6-2.675-.6-3.7 0l-5.6 3.325c-4.025 2.375-6.45.6-5.4-3.95l1.325-5.775c.25-1.075-.2-2.6-.975-3.4l-4.65-4.65c-2.75-2.75-1.85-5.525 1.975-6.15l5.975-1c1-.175 2.2-1.05 2.65-1.95l3.3-6.6c1.775-3.575 4.725-3.575 6.5 0z'
      ></path>
      <path
        fill='#FDCC0A'
        d='M15 24.379a1.889 1.889 0 01-1.875-1.875v-17.5c0-1.025.85-1.875 1.875-1.875s1.875.85 1.875 1.875v17.5c0 1.025-.85 1.875-1.875 1.875zM45.001 24.379a1.889 1.889 0 01-1.875-1.875v-17.5c0-1.025.85-1.875 1.875-1.875s1.875.85 1.875 1.875v17.5c0 1.025-.85 1.875-1.875 1.875zM30.001 11.879a1.889 1.889 0 01-1.875-1.875v-5c0-1.025.85-1.875 1.875-1.875s1.875.85 1.875 1.875v5c0 1.025-.85 1.875-1.875 1.875z'
        opacity='0.4'
      ></path>
    </svg>
  );
}

export default RankingIcon;
