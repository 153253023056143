import React from 'react';
import { Box, Typography } from '@mui/material';

const NoVideoComponent = () => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        p: '32px 20px',
        backgroundColor: (theme) => theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h6' sx={{ width: '215px', textAlign: 'center' }}>
        Hey, Looks like there is no video yet!
      </Typography>
    </Box>
  );
};

export default NoVideoComponent;
