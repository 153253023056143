import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, Typography } from '@mui/material';
import MenuHeader from '../WelcomePage/MenuHeader';
import api from '../../config/api';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';

const ReferEarnTransactionPage = ({ data }) => {
  const getReferTransaction = async () => {
    const { data } = await api.get('/api/user/referral/txn?page=1&size=10');
    return data;
  };
  const { data: referTransaction, isLoading } = useQuery({
    queryKey: ['refer'],
    queryFn: getReferTransaction,
  });
  if (isLoading) return <Loading />;
  return (
    <Box sx={{ pb: 8, minHeight: '100svh' }}>
      <TopNavigation title={'Refer and Earn'} />
      <TopNavigationSpace />
      <Stack mt={2.5} spacing={3}>
        <MenuHeader title={`Successful Referral (${referTransaction?.data?.referralCount})`} isViewAll={false} />
        <Stack spacing={3}>
          {referTransaction?.data?.referralTxn?.map((transaction) => (
            <Stack direction='row' key={transaction?._id} justifyContent={'space-between'}>
              <Stack spacing={0.5}>
                <Typography variant='h6_bold'>{transaction?.referee?.name}</Typography>
                <Typography variant='h6'>
                  {dayjs(transaction?.referee?.createdAt).format('DD MMM, YYYY, HH:mm')}
                </Typography>
              </Stack>
              <Typography variant='h6_bold'>
                {transaction?.reward?.type === 'MONEY'
                  ? `$${transaction?.reward?.value}`
                  : `${transaction?.reward?.value} Free Mins`}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReferEarnTransactionPage;
