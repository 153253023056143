import { Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTextArea from '../CustomTextArea/CustomTextArea';

const CustomTextAreaWithLabel = ({ name, label, control, rules }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6_semibold" sx={{ color: '#1A1B1F66' }}>
        {label}
      </Typography>
      <CustomTextArea
        name={name}
        control={control}
        label={label}
        rules={rules}
      />
    </Stack>
  );
};

export default CustomTextAreaWithLabel;
