import React from 'react';
import { Typography } from '@mui/material';
import ReferEarnImage from '../../assets/images/ReferEarnImage';
import Stack from '@mui/material/Stack';

const NoReferEarnPage = () => {
  return (
    <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', mt: 2 }} spacing={4}>
      <ReferEarnImage />
      <Typography sx={{ textAlign: 'center' }}>
        At the moment, there is no active Referral Program available.
      </Typography>
    </Stack>
  );
};

export default NoReferEarnPage;
