import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const CustomTextField = ({
  name,
  control,
  label,
  type = 'text',
  rules,
  startAdornment,
  endAdornment,
  fontSize,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <TextField
          variant='outlined'
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          sx={{
            my: 1,
            '& input': {
              fontSize: fontSize || '16px',
              fontWeight: 600,
              // padding: '20px 20px 8px 14px',
              padding: '14px 20px',
            },
            ...rest,
          }}
          InputProps={{
            style: {
              borderRadius: '8px',
              borderColor: rest.borderColor || 'rgba(0, 0, 0, 0.1)',
              color: rest.color || 'black',
            },
            startAdornment,
            endAdornment,
          }}
          placeholder={label}
          // label={label}
          type={type}
        />
      )}
    />
  );
};

export default CustomTextField;
