import React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import InfoModal from '../../components/InfoModal/InfoModal';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

const LiveStreamStoppedPage = () => {
  const [openModal, setOpenModal] = React.useState(true);
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100dvh',
      }}
      component={Paper}
    >
      <InfoModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          navigate(ROUTES.WELCOME);
        }}
        title='Live Stream Stopped'
        description='Live stream has been stopped. Please check back later.'
      />
    </Stack>
  );
};

export default LiveStreamStoppedPage;
