import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getActivity = async () => {
  const { data } = await api.get('/api/user/profile/activity');
  return data;
};

const useGetActivity = () => {
  return useQuery({ queryKey: ['activity'], queryFn: getActivity });
};

export default useGetActivity;
