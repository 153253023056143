import * as React from 'react';

function PaymentIcon(props) {
  return (
    <svg
      width={80}
      height={81}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 42.05c10.355 0 18.75-8.395 18.75-18.75 0-10.356-8.395-18.75-18.75-18.75S3.75 12.945 3.75 23.3s8.395 18.75 18.75 18.75z"
        fill="#FDCC0A"
      />
      <path
        d="M23.75 22.05v-5a2.5 2.5 0 012.5 2.5 1.25 1.25 0 002.5 0 5 5 0 00-5-5V13.3a1.25 1.25 0 00-2.5 0v1.25a5 5 0 100 10v5a2.5 2.5 0 01-2.5-2.5 1.25 1.25 0 00-2.5 0 5 5 0 005 5v1.25a1.25 1.25 0 002.5 0v-1.25a5 5 0 100-10zm-5-2.5a2.5 2.5 0 012.5-2.5v5a2.5 2.5 0 01-2.5-2.5zm5 10v-5a2.5 2.5 0 010 5z"
        fill="#000"
      />
      <path
        d="M38.75 69.55h-.056A28.807 28.807 0 0112 47.336a1.249 1.249 0 011.422-1.517 1.25 1.25 0 011.013.95 26.3 26.3 0 0024.37 20.28 1.25 1.25 0 01-.055 2.5z"
        fill="#FFE271"
      />
      <path
        d="M21.875 48.464l-8.75-5a1.248 1.248 0 00-1.739.526l-3.75 7.5A1.25 1.25 0 009 53.275l12.5-2.5a1.25 1.25 0 00.375-2.311z"
        fill="#FDCC0A"
      />
      <path
        d="M67.462 40.8a1.25 1.25 0 01-1.25-1.194 26.274 26.274 0 00-22.225-24.758l-.455-.067a1.25 1.25 0 01.437-2.46l.425.062a28.767 28.767 0 0124.318 27.11 1.25 1.25 0 01-1.193 1.307h-.057z"
        fill="#FFE271"
      />
      <path
        d="M73.571 31.404a1.25 1.25 0 00-1.196-.604l-12.5 1.25a1.25 1.25 0 00-.675 2.206l7.5 6.25a1.25 1.25 0 00.98.28 1.25 1.25 0 00.86-.542l5-7.5a1.25 1.25 0 00.031-1.34z"
        fill="#FDCC0A"
      />
      <path
        d="M71.25 44.55h-22.5a5 5 0 00-5 5v22.5a5 5 0 005 5h22.5a5 5 0 005-5v-22.5a5 5 0 00-5-5z"
        fill="#FFE271"
      />
      <path
        d="M66.25 44.55v13.625a1.237 1.237 0 01-2.21.875l-4.04-7-4.04 7a1.238 1.238 0 01-2.21-.875V44.55h12.5z"
        fill="#FDCC0A"
      />
      <path
        d="M63.75 73.3h-2.5a1.25 1.25 0 010-2.5h2.5a1.25 1.25 0 010 2.5zm7.5 0h-2.5a1.25 1.25 0 010-2.5h2.5a1.25 1.25 0 010 2.5z"
        fill="#000"
      />
    </svg>
  );
}

export default PaymentIcon;
