import { Box } from '@mui/material';
import React, { useState } from 'react';
import MenuHeader from '../../MenuHeader';
import Slider from 'react-slick';
import TopStreamerCard from './TopStreamerCard';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../config/api';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import NoStreamerCard from '../../../TopStreamersPage/NoStreamerCard';

const topStreamersSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const getTopStreamers = async () => {
  const { data } = await api.get('/api/user/top-streamers');
  return data;
};

const TopStreamersComponent = () => {
  const { data: topStreamers } = useQuery({
    queryKey: ['topStreamers'],
    queryFn: getTopStreamers,
  });
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const navigate = useNavigate();
  return (
    <Box mt={4}>
      <MenuHeader
        title={'Top Streamers'}
        onClick={() => navigate(ROUTES.DISCOVER.replace(':discoverType', '0'))}
        isViewAll={topStreamers?.data?.totalCount > 0}
      />
      <Box mt={2} sx={{ overflowX: 'hidden' }}>
        {topStreamers?.data?.totalCount > 0 ? (
          <Slider {...topStreamersSliderSettings}>
            {topStreamers?.data?.result?.map((topStreamer) => (
              <TopStreamerCard key={topStreamer._id} data={topStreamer} />
            ))}
          </Slider>
        ) : (
          <NoStreamerCard />
        )}
      </Box>
    </Box>
  );
};

export default TopStreamersComponent;
