import * as React from 'react';

function RightArrowIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.size || 16}
      height={props.size || 16}
      fill='none'
      viewBox='0 0 18 18'
    >
      <path
        stroke={props.variant === 'dark' ? '#010101' : '#737373'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.277'
        d='M11.209 4.207l4.307 4.307-4.307 4.307M8.783 8.516h6.613M3.453 8.516h2.462'
      ></path>
    </svg>
  );
}

export default RightArrowIcon;
