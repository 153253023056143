import * as React from 'react';

function CoinIcon(props) {
  return (
    <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7 12.833c2.094 0 3.792-1.476 3.792-3.296V7.38C10.792 9.2 9.094 10.5 7 10.5S3.208 9.2 3.208 7.38v2.157c0 .998.508 1.89 1.313 2.491'
        stroke='#1E1D25'
        strokeWidth={0.875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.792 4.462c0 .531-.146 1.021-.403 1.441C9.765 6.93 8.482 7.583 7 7.583c-1.482 0-2.765-.653-3.39-1.68a2.744 2.744 0 01-.402-1.44c0-.91.426-1.733 1.109-2.328.688-.6 1.633-.968 2.683-.968 1.05 0 1.995.367 2.683.962.683.601 1.109 1.423 1.109 2.333z'
        stroke='#1E1D25'
        strokeWidth={0.875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.792 4.462V7.38C10.792 9.2 9.094 10.5 7 10.5S3.208 9.2 3.208 7.38V4.461c0-1.82 1.698-3.295 3.792-3.295 1.05 0 1.995.367 2.683.962.683.601 1.109 1.423 1.109 2.333z'
        stroke='#1E1D25'
        strokeWidth={0.875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default CoinIcon;
