import React from 'react';

function HeartIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' fill='none' viewBox='0 0 60 60'>
      <path
        fill='#FDCC0A'
        d='M30 55c13.807 0 25-11.193 25-25S43.807 5 30 5 5 16.193 5 30s11.193 25 25 25z'
        opacity='0.4'
      ></path>
      <path
        fill='#FDCC0A'
        d='M30.825 42.498c-.45.15-1.225.15-1.675 0-3.9-1.325-12.65-6.9-12.65-16.35 0-4.175 3.35-7.55 7.5-7.55a7.445 7.445 0 016 3.025 7.48 7.48 0 016-3.025c4.15 0 7.5 3.375 7.5 7.55 0 9.45-8.75 15.025-12.675 16.35z'
      ></path>
    </svg>
  );
}

export default HeartIcon;
