import { signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../config/firebase';
import api from '../config/api';
import generateUniqueUserName from './generateUniqueUserName';
import saveDataToDatabase from './saveDataToDatabase';
import { isNewUser } from '../store';
import ROUTES from '../routes';
import { toast } from 'react-toastify';

const GEOLOCATION_API_KEY = process.env.REACT_APP_GEO_LOCATION_API_KEY;

const handleGoogleAuth = async (setIsLoading, setIsAllowLocationModal, navigate) => {
  setIsLoading(true);
  try {
    const userCredential = await signInWithPopup(auth, googleAuthProvider);
    const { data } = await api.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOLOCATION_API_KEY}`, {
      homeMobileCountryCode: 310,
      homeMobileNetworkCode: 410,
      radioType: 'gsm',
      carrier: 'Vodafone',
      considerIp: true,
    });
    const { lat, lng } = data.location;
    const location = `${lat},${lng}`;
    const { data: locationData } = await api.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location}&key=${GEOLOCATION_API_KEY}`,
    );
    console.log(locationData);
    const results = locationData.results;
    const address_component = results[results.length - 1].address_components[0];
    const longName = address_component.long_name;
    const shortName = address_component.short_name;
    console.log(longName, shortName);
    const token = await userCredential.user.getIdToken();
    localStorage.setItem('token', token);
    localStorage.setItem('isNewUser', 'false');
    const { data: userProfile } = await api.get('/api/user/profile');
    console.log(userProfile);
    if ('status' in userProfile && userProfile.status === false) {
      // if (locationError) {
      //   setIsAllowLocationModal(true);
      //   setIsLoading(false);
      //   return;
      // }
      const { email, displayName, uid } = userCredential.user;
      const userName = generateUniqueUserName(email);
      await saveDataToDatabase({
        email,
        name: displayName,
        uid,
        method: 'GOOGLE',
        userName,
        country: { code: shortName, value: longName },
      });
      isNewUser.value = true;
      navigate(ROUTES.REFERRAL_CODE_INPUT);
    } else {
      const intendedRoute = localStorage.getItem('intendedRoute');
      localStorage.removeItem('intendedRoute');
      intendedRoute ? navigate(intendedRoute) : navigate(ROUTES.WELCOME);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    if (error?.code === 'auth/popup-closed-by-user') {
      toast.info('Popup closed by user');
    } else if (error?.code === 'auth/network-request-failed') {
      toast.error('Network error. Please check your internet connection.');
    } else if (error?.code === 'auth/user-disabled') {
      toast.error('This user account has been disabled.');
    } else if (error?.code === 'auth/user-not-found') {
      toast.error('No user found with this email.');
    } else if (error?.code === 'auth/wrong-password') {
      toast.error('Incorrect password.');
    } else {
      toast.error(error.message);
    }
    console.log(error);
  }
};

export default handleGoogleAuth;
