import api from '../config/api';

const saveDataToDatabase = async ({ email, name, uid, method, userName, country, phone }) => {
  const body = {
    email,
    name,
    uid,
    method,
    country,
    userName,
    phone,
  };
  await api.post('/api/user', body);
};

export default saveDataToDatabase;
