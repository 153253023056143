import React from 'react';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import EditPenIcon from '../../assets/icons/EditPenIcon';
import MenuHeader from '../../components/MenuHeader/MenuHeader';

const Title = ({ label }) => {
  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: '12px',
        p: '16px 20px',
      }}
    >
      <Typography variant='h6'>{label}</Typography>
    </Box>
  );
};

const BankAccountDetails = ({ bank, setEditMode }) => {
  return (
    <Stack spacing={2}>
      <MenuHeader title={'Saved Bank Account'} icon={<EditPenIcon />} iconClick={() => setEditMode(true)} />
      <Stack spacing={1.5}>
        <Title label={bank.accNo} />
        <Title label={bank.code} />
        <Title label={bank.accHolderName} />
        <Title label={bank.bankName} />
      </Stack>
    </Stack>
  );
};

export default BankAccountDetails;
