import { Badge, Box, Container, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import Logo_text from '../../assets/images/logo_text.svg';
import WalletIcon from '../../assets/icons/WalletIcon';
import NotificationIcon from '../../assets/icons/NotificationIcon';
import useGetNotificationStatus from '../../hooks/useGetNotificationStatus';
import api from '../../config/api';
import PropTypes from 'prop-types';
import NotificationMessageIcon from '../../assets/icons/NotificationMessageIcon';
import useGetMessageNotification from '../../hooks/useGetMessageNotification';

export const TopNavigationSpace = () => {
  return <Box sx={{ height: '50px' }}></Box>;
};

const WelcomeScreenContent = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(ROUTES.WELCOME)}>
      <img src={Logo_text} alt={'Logo'} style={{ height: '28px' }} />
    </Box>
  );
};
const OtherScreenContent = ({ previousPath, title }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ width: '108px' }}>
        <IconButton onClick={() => navigate(previousPath || -1)}>
          <LeftArrowIcon />
        </IconButton>
      </Box>
      <Typography variant='h6_bold'>{title}</Typography>
    </>
  );
};
OtherScreenContent.propTypes = {
  previousPath: PropTypes.string,
  title: PropTypes.string,
};
const TopNavigation = ({ title, previousPath }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [profileMenuState, setProfileMenuState] = React.useState(null);
  const isProfileMenuOpen = Boolean(profileMenuState);
  const handleOpenProfileMenu = (event) => {
    setProfileMenuState(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setProfileMenuState(null);
  };
  const handleLogout = () => {
    handleCloseProfileMenu();
    localStorage.removeItem('token');
    navigate(ROUTES.LOGIN);
  };
  const { data: notificationStatus } = useGetNotificationStatus();
  const { data: messageNotificationStatus } = useGetMessageNotification();
  const [isNotification, setIsNotification] = React.useState(false);
  const [isMessageNotification, setIsMessageNotification] = React.useState(false);
  useEffect(() => {
    setIsNotification(notificationStatus?.data?.unReadNotification);
    setIsMessageNotification(messageNotificationStatus?.data?.isNewMessage);
  }, [messageNotificationStatus, notificationStatus?.data, notificationStatus?.data?.unReadNotification]);

  const updateNotificationStatus = async () => {
    if (!isNotification) return;
    try {
      await api.put('/api/user/notification/status');
      setIsNotification(false);
    } catch (e) {
      setIsNotification(true);
    }
  };

  return (
    <>
      <Container
        maxWidth='xs'
        py={1.5}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
          backgroundColor: '#FFFFFF',
          height: '50px',
          top: 0,
          width: '100%',
          mx: { sm: '-24px', xs: '-16px' },
          zIndex: 3,
        }}
      >
        {location.pathname === ROUTES.WELCOME ||
        location.pathname === ROUTES.DISCOVER ||
        location.pathname === ROUTES.LIVE_ALL ||
        location.pathname === ROUTES.PROFILE ? (
          <WelcomeScreenContent />
        ) : (
          <OtherScreenContent previousPath={previousPath} title={title} />
        )}

        <Stack direction={'row'}>
          <IconButton onClick={async () => navigate(ROUTES.MESSAGES)}>
            {isMessageNotification ? (
              <Badge variant='dot' color='primary'>
                <NotificationMessageIcon />
              </Badge>
            ) : (
              <NotificationMessageIcon />
            )}
          </IconButton>
          <IconButton
            onClick={async () => {
              await updateNotificationStatus();
              navigate(ROUTES.NOTIFICATIONS);
            }}
          >
            {isNotification ? (
              <Badge variant='dot' color='primary'>
                <NotificationIcon />
              </Badge>
            ) : (
              <NotificationIcon />
            )}
          </IconButton>
          <IconButton onClick={() => navigate(ROUTES.WALLET)}>
            <WalletIcon />
          </IconButton>
          {/*<IconButton*/}
          {/*  // onClick={handleOpenProfileMenu}*/}
          {/*  onClick={() => navigate(ROUTES.PROFILE + '?isBottomNavigation')}*/}
          {/*>*/}
          {/*  <Avatar sx={{ width: 20, height: 20 }}></Avatar>*/}
          {/*</IconButton>*/}
        </Stack>
      </Container>
      <Menu
        anchorEl={profileMenuState}
        open={isProfileMenuOpen}
        onClose={handleCloseProfileMenu}
        onClick={handleCloseProfileMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>
          <Typography variant='h6_bold'>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

TopNavigation.propTypes = {
  title: PropTypes.string,
  previousPath: PropTypes.string,
};
export default TopNavigation;
