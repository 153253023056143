import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Lottie from 'lottie-react';
import videoAnimation from '../../assets/animation/videoAnimation.json';

const MyVideoLoading = ({ content = "We're loading video for you, Please Wait!" }) => {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100svh',
      }}
    >
      <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
        <Lottie animationData={videoAnimation} loop={true} />
        <Stack textAlign='center'>
          <Typography variant='h5_dark'>{content}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MyVideoLoading;
