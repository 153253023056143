import React from 'react';

function GiftIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
      <path
        stroke='#FDCC0A'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.277'
        d='M3.29 13.255c0 2.128.709 2.838 2.837 2.838h5.677c2.128 0 2.838-.71 2.838-2.838V7.578H3.289v2.725'
      ></path>
      <path
        stroke='#FDCC0A'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.277'
        d='M15.726 5.45v.71c0 .78-.376 1.419-1.419 1.419H3.664c-1.085 0-1.419-.639-1.419-1.42V5.45c0-.78.334-1.419 1.42-1.419h10.642c1.043 0 1.42.639 1.42 1.42z'
      ></path>
      <path
        stroke='#FDCC0A'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.277'
        d='M8.731 4.033H4.814a.664.664 0 01.022-.922l1.007-1.008a.68.68 0 01.958 0l1.93 1.93zM13.151 4.033H9.234l1.93-1.93a.681.681 0 01.958 0l1.008 1.008c.255.255.262.66.021.922zM6.815 7.578v3.647c0 .568.625.901 1.1.596l.667-.44a.709.709 0 01.78 0l.632.426a.707.707 0 001.1-.589v-3.64H6.815z'
      ></path>
    </svg>
  );
}

export default GiftIcon;
