import React from 'react';

function CoinIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
      <path fill='#FDCC0A' d='M7.492 13.485a5.992 5.992 0 100-11.985 5.992 5.992 0 000 11.985z' opacity='0.4'></path>
      <path
        fill='#FDCC0A'
        d='M16.476 11.995a4.488 4.488 0 01-4.485 4.485 4.477 4.477 0 01-3.682-1.928 7.094 7.094 0 006.24-6.24 4.477 4.477 0 011.927 3.683zM8.587 7.28l-1.8-.63c-.18-.06-.218-.075-.218-.338 0-.195.135-.352.308-.352h1.125c.24 0 .435.217.435.487 0 .308.255.563.562.563a.567.567 0 00.563-.563c0-.862-.668-1.567-1.5-1.605v-.037a.562.562 0 10-1.125 0v.037h-.068c-.787 0-1.432.66-1.432 1.478 0 .712.315 1.17.967 1.395l1.808.63c.18.06.217.075.217.337 0 .195-.135.353-.307.353H6.997c-.24 0-.435-.218-.435-.488a.567.567 0 00-.563-.562.567.567 0 00-.562.562c0 .863.667 1.568 1.5 1.605v.045c0 .308.255.563.562.563a.567.567 0 00.563-.563v-.037h.067c.788 0 1.433-.66 1.433-1.478 0-.712-.323-1.17-.975-1.402z'
      ></path>
    </svg>
  );
}

export default CoinIcon;
