import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import LogoBlackIcon from '../../assets/icons/LogoBlackIcon';

const SplashScreenPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        height: '100dvh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mx: { sm: '-24px', xs: '-16px' },
        position: 'absolute',
      }}
    >
      <Stack spacing={2} alignItems={'center'}>
        <LogoBlackIcon />
        <Typography variant='h5'>stream earn enjoy</Typography>
      </Stack>
      <Box sx={{ position: 'absolute', bottom: 40 }}>
        <Typography variant='h6'>bunpun technologies private limited</Typography>
      </Box>
    </Box>
  );
};

export default SplashScreenPage;
