import React from 'react';
import api from '../../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';
import { Box } from '@mui/material';
import CompletedRequestCard from './CompletedRequestCard';
import useInfiniteQueryReducedResult from '../../../utils/useInfiniteQueryReducedResult';

const CompletedRequestComponent = ({ setCompletedRequestDetails }) => {
  const getCompletedRequest = async ({ pageParam }) => {
    const { data } = await api.get(`/api/user/profile/task?filter=completed&page=${pageParam}&size=10`);
    setCompletedRequestDetails &&
      setCompletedRequestDetails((prev) => ({
        ...prev,
        height: data.data.totalCount * 160,
      }));
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['completedRequestsInfiniteScroll'],
    queryFn: getCompletedRequest,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      console.log(allPages);
      const fetchedData = allPages?.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages?.length + 1 : undefined;
    },
  });
  const completedTasks = useInfiniteQueryReducedResult(data);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      {completedTasks?.map((task, index) => (
        <CompletedRequestCard
          key={task?._id}
          data={task}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          index={index}
          totalCount={completedTasks?.length}
        />
      ))}
    </Box>
  );
};

export default CompletedRequestComponent;
