import api from '../config/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const updateViewCount = async (streamId) => {
  console.log('inside updateViewCount');
  await api.post(`/api/stream/viewers/${streamId}`);
};

const useUpdateViewCount = (streamId, taskId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateViewCount,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['task', taskId] });
      const previousTask = queryClient.getQueryData(['task', taskId]);
      console.log(previousTask);
      queryClient.setQueriesData({ queryKey: ['task', taskId] }, (oldTask) => {
        console.log(oldTask);
        return { ...oldTask, viewers: oldTask.viewers + 1 };
      });
      return { previousTask };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['task', taskId], context?.previousTask);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['task', taskId] });
    },
  });
};

export default useUpdateViewCount;
