import ROUTES from '../routes';

const host = window.location.host;

export let BASE_URL = 'https://dev-api.bunpun.com';

if (host === 'dev-app.bunpun.com') {
  BASE_URL = 'https://dev-api.bunpun.com';
} else if (host === 'staging-app.bunpun.com') {
  BASE_URL = 'https://staging-api.bunpun.com';
}

export const TASK_CARD_HEIGHT = 155;
export const REQUEST_CARD_HEIGHT = 153;
export const TOP_STREAMER_CARD_HEIGHT = 123;
export const TOP_REQUEST_CARD_HEIGHT = 162;

export const PLATFORM_FEE = 0.03;

export const WITHDRAWAL_MESSAGE = 'Withdrawal to be completed in 5 to 7 days.';

export const STREAM_TYPE = { REQUEST: 'REQUEST', GO_LIVE: 'GO_LIVE' };

export const TRANSACTION_TYPE = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  REWARD: 'REWARD',
  REFUND: 'REFUND',
};

export const TRANSACTION_RESPONSE = {
  SUCCESS: 'succeeded',
  REJECT: 'rejected',
  PENDING: 'pending',
};

export const TRANSACTIONS = {
  ADD_WALLET: 'ADD_WALLET',
  WITHDRAWAL: 'WITHDRAWAL',
  REQUEST_TASK: 'REQUEST_TASK',
  STREAMER: 'STREAMER',
  GO_LIVE: 'GO_LIVE',
  REWARD: 'REWARD',
};

export const LIVE_STREAM_STATUS = {
  STARTING: 'starting',
  LIVE: 'started',
  STOPPED: 'stopped',
};

export const NOTIFICATION_TYPE = {
  WELCOME: 'WELCOME',
  BOOKING_REQUEST_PAYMENT: 'BOOKING_REQUEST_PAYMENT',
  BOOK_INTIMATION_STREAMER: 'BOOK_INTIMATION_STREAMER',
  GO_LIVE_PUBLIC: 'GO_LIVE_PUBLIC',
  STREAM_MESSAGE: 'STREAM_MESSAGE',
  STREAM_STARTED: 'STREAM_STARTED',
  STREAM_ACCEPTED: 'STREAM_ACCEPTED',
  WALLET_MONEY_DEPOSIT: 'WALLET_MONEY_DEPOSIT',
  WALLET_MONEY_WITHDRAW: 'WALLET_MONEY_WITHDRAW',
  WALLET_MONEY_PAID_REQUESTER: 'WALLET_MONEY_PAID_REQUESTER',
  CREATE_PROPOSAL: 'CREATE_PROPOSAL',
  STREAM_REMAINDER: 'STREAM_REMAINDER',
  STREAM_RESCHEDULE: 'STREAM_RESCHEDULE',
  NEW_RELEASE: 'NEW_RELEASE',
  RECORDED_VIDEO_COMMENT_LIKE: 'RECORDED_VIDEO_COMMENT_LIKE',
  RECORDED_VIDEO_COMMENT: 'RECORDED_VIDEO_COMMENT',
  RECORDED_VIDEO_LIKE: 'RECORDED_VIDEO_LIKE',
};

export const NOTIFICATION_LINK = {
  WELCOME: ROUTES.WELCOME,
  BOOKING_REQUEST_PAYMENT: ROUTES.WALLET,
  BOOK_INTIMATION_STREAMER: ROUTES.SCHEDULE_LIVE,
  GO_LIVE_PUBLIC: ROUTES.LIVE_PLAY,
  STREAM_MESSAGE: ROUTES.CHAT_USER,
  STREAM_STARTED: ROUTES.LIVE_PLAY,
  STREAM_ACCEPTED: ROUTES.MY_REQUEST,
  WALLET_MONEY_DEPOSIT: ROUTES.WALLET,
  WALLET_MONEY_WITHDRAW: ROUTES.WALLET_WITHDRAW_MONEY,
  WALLET_MONEY_PAID_REQUESTER: ROUTES.WALLET,
  CREATE_PROPOSAL: ROUTES.MY_REQUEST_PROPOSAL,
  STREAM_REMAINDER: ROUTES.CHAT_USER,
  STREAM_RESCHEDULE: 'STREAM_RESCHEDULE',
  NEW_RELEASE: 'NEW_RELEASE',
  RECORDED_VIDEO_COMMENT_LIKE: ROUTES.RECORDING_PLAY,
  RECORDED_VIDEO_COMMENT: ROUTES.RECORDING_PLAY,
  RECORDED_VIDEO_LIKE: ROUTES.RECORDING_PLAY,
};

export const MY_REQUEST_TYPE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const MY_TASK_TYPE = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
};
