import * as React from 'react';

function PlayIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.275 6.65c3.191 1.842 3.191 4.858 0 6.7L11.7 14.833l-2.575 1.484c-3.184 1.841-5.792.333-5.792-3.35V7.033c0-3.683 2.608-5.191 5.8-3.35l1.875 1.084"
        stroke="#111"
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlayIcon;
