import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getPopularVideos = async () => {
  const { data } = await api.get('/api/user/popular-videos?page=1&size=5');
  return data;
};

const useGetPopularVideos = () => {
  return useQuery({
    queryKey: ['popularVideos'],
    queryFn: getPopularVideos,
  });
};

export default useGetPopularVideos;
