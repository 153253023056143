import React, { useState } from 'react';
import { Box } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import WithdrawMoneyForm from './WithdrawMoneyForm';
import SelectPaymentMethod from './SelectPaymentMethod';

const WalletWithdrawMoneyPage = () => {
  const [amount, setAmount] = useState(0);
  return (
    <>
      <Box sx={{ pb: 8 }}>
        <TopNavigation title='Withdraw' />
        <TopNavigationSpace />
        {amount ? <SelectPaymentMethod amount={amount} /> : <WithdrawMoneyForm setAmount={setAmount} />}
      </Box>
    </>
  );
};

export default WalletWithdrawMoneyPage;
