import React, { useEffect, useState } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../config/api';
import Loading from '../../components/Loading/Loading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea';
import { useForm } from 'react-hook-form';
import ROUTES from '../../routes';
import { toast } from 'react-toastify';
import useGetTask from '../../hooks/useGetTask';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import CustomDateTimePicker from '../../components/CustomDateTimePicker/CustomDateTimePicker';
import CustomCalendar from '../../components/CustomCalendar/CustomCalendar';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import getDateByLocalTimezone from '../../utils/getDateByLocalTimezone';
import { useMutation } from '@tanstack/react-query';
import TaskDetails from '../../components/TaskDetails/TaskDetails';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';

dayjs.extend(customParseFormat);

const createProposal = async (body) => {
  const { data } = await api.post('/api/user/proposal', body);
  return data;
};

const SubmitProposalPage = () => {
  const [open, setOpen] = useState(false);
  const [proposeAt, setProposeAt] = useState({ date: null, timeZone: '' });
  const [deadlineError, setDeadlineError] = useState({ error: false, message: '' });
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { data: task, isLoading } = useGetTask(taskId);
  useEffect(() => {
    setProposeAt({ date: dayjs(task?.data?.requestAt), timeZone: '' });
  }, [task?.data?.requestAt]);
  const { control, handleSubmit } = useForm();
  const formattedDate = getDateByLocalTimezone(task?.data?.requestAt, task?.data?.timeZone);
  const { mutate: createProposalMut } = useMutation({
    mutationFn: createProposal,
    onSuccess: () => {
      setOpenModal(true);
    },
    onError: (error) => {
      console.log(error);
      toast.error('Proposal Not Submitted');
    },
  });

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(ROUTES.WELCOME);
  };

  const onSubmitProposal = (data) => {
    createProposalMut({
      ...data,
      task: task?.data?._id,
      amount: task?.data?.amount,
      proposeAt: proposeAt?.date?.toISOString() || task.data.requestAt,
      timeZone: proposeAt?.timeZone || task.data.timeZone,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Box sx={{ pb: 8 }}>
        <TopNavigation title={'Submit Proposal'} previousPath={ROUTES.WELCOME} />
        <TopNavigationSpace />
        <Stack spacing={1} pt={2.5}>
          <Typography variant='h3_dark'>{task?.data?.title}</Typography>
          <Typography variant='h6'>{task?.data?.description}</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmitProposal)}>
          <Stack spacing={2} pt={2}>
            <TaskDetails
              date={formattedDate}
              duration={task?.data?.duration}
              amount={task?.data?.amount}
              timeZone={task?.data?.timeZone}
            />
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              sx={{
                border: (theme) => `1px solid ${theme.palette.border.main}`,
                borderRadius: '8px',
                p: 1.2,
              }}
            >
              <CustomAvatar avatarUrl={task?.data?.user?.profile?.avatarUrl} name={task.data.user.name} />
              <Typography variant={'h6_bold'}>{task?.data?.user?.name}</Typography>
            </Stack>

            <CustomTextArea
              name={'message'}
              label={'Your message - you can confirm the task details here, propose any alternative, etc.'}
              control={control}
              rules={{ required: 'Message is required' }}
            />
            {/*<CustomTextField*/}
            {/*  name={'amount'}*/}
            {/*  label={task?.data?.amount}*/}
            {/*  control={control}*/}
            {/*  rules={{ required: 'Amount is required' }}*/}
            {/*  type={'number'}*/}
            {/*  startAdornment={*/}
            {/*    <InputAdornment position="end">*/}
            {/*      <AttachMoneyIcon />*/}
            {/*    </InputAdornment>*/}
            {/*  }*/}
            {/*/>*/}
            <CustomDateTimePicker
              label={'Propose streaming time'}
              onClick={() => setOpen(true)}
              value={proposeAt.date}
              error={deadlineError}
            />
            <Button variant='contained' type='submit'>
              Continue
            </Button>
          </Stack>
        </form>
      </Box>
      <CustomDialog open={open} handleClose={handleClose} maxWidth={'xs'} fullWidth>
        <CustomCalendar dateAndTime={proposeAt} setDateAndTime={setProposeAt} handleClose={handleClose} />
      </CustomDialog>
      <SuccessModal
        open={openModal}
        handleClose={handleCloseModal}
        title={'Success!'}
        message={'Your proposal has been submitted successfully'}
        btnText={'OK'}
        onClick={handleCloseModal}
      />
    </>
  );
};

export default SubmitProposalPage;
