import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';

export default function BottomDrawer({ bottomDrawerState, setBottomDrawerState, children }) {
  const toggleDrawer = (state) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setBottomDrawerState(state);
  };

  return (
    <Drawer
      anchor={'bottom'}
      open={bottomDrawerState}
      onClose={toggleDrawer(false)}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'center',
        },
      }}
      ModalProps={{
        disablePortal: true,
      }}
    >
      <Slide direction='up' in={bottomDrawerState} mountOnEnter unmountOnExit>
        <Container
          maxWidth='xs'
          sx={{
            backgroundColor: '#fff',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            height: '100%',
            padding: '24px',
          }}
          role='presentation'
        >
          {children}
        </Container>
      </Slide>
    </Drawer>
  );
}
