import { signal } from '@preact/signals-react';

export const taskDetails = signal({});

export const userDetails = signal(null);

export const intendedRoute = signal(null);

export const setting = signal({});

export const isNewUser = signal(false);

export const isRequestVideoFormPending = signal(false);

export const publishSettings = signal({
  signalingURL: '',
  applicationName: '',
  streamName: '',
  streamInfo: '',
  audioBitrate: 0,
  audioCodec: '',
  audioTrack: null,
  audioTrackDeviceId: '',
  videoBitrate: 0,
  videoCodec: '',
  videoFrameSize: '',
  videoFrameRate: 0,
  videoTrack: null,
  videoTrack1DeviceId: '',
  videoTrack2DeviceId: '',
  userData: '',
  publishStart: false,
  publishStarting: false,
  publishStop: false,
  publishStopping: false,
});
