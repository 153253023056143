import * as React from 'react';

const CardIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
    <path
      stroke='#111'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.125}
      d='M1.5 6.375h15M4.5 12.375H6M7.875 12.375h3'
    />
    <path
      stroke='#292D32'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.125}
      d='M16.5 11.243v.84c0 2.632-.668 3.292-3.33 3.292H4.83c-2.662 0-3.33-.66-3.33-3.293V5.919c0-2.633.668-3.293 3.33-3.293h8.333c2.67 0 3.337.66 3.337 3.293v2.317'
    />
  </svg>
);
export default CardIcon;
