import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '48px',
    minWidth: '110px',
    borderRadius: '8px',
    borderColor: state.isFocused ? '#fdcc0a !important' : 'rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
    ':hover': {
      borderColor: 'rgba(0, 0, 0, 1)',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 16px 8px 12px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '8px 0px 8px 16px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    marginTop: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
    color: state.isSelected ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.6)',
    padding: '8px 16px',
    cursor: 'pointer',
  }),
};
const OptionWithIcon = ({ data, isDisabled, isFocused, isSelected, innerProps, innerRef, selectProps }) => {
  const { label, flag } = data;

  return (
    <Box
      {...innerProps}
      ref={innerRef}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: '8px 16px',
        backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
        color: isSelected ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.6)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {flag && <Avatar src={flag} sx={{ width: 24, height: 24 }}></Avatar>}
      <Typography variant='h6' style={{ marginLeft: flag ? '8px' : 0 }}>
        {label}
      </Typography>
    </Box>
  );
};

const CustomReactSelect = ({
  control,
  options,
  name,
  label,
  rules,
  placeholder,
  isMulti = true,
  closeMenuOnSelect = false,
  defaultValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Stack spacing={1}>
          {label && (
            <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
              {label}
            </Typography>
          )}
          <Select
            options={options}
            value={value}
            onChange={onChange}
            isMulti={isMulti}
            placeholder={placeholder}
            defaultValue={defaultValue}
            components={{
              IndicatorSeparator: null,
            }}
            styles={customStyles}
            closeMenuOnSelect={closeMenuOnSelect}
          />
          {error && (
            <Typography variant='caption' color='error' sx={{ m: '4px 14px !important' }}>
              {error.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default CustomReactSelect;
