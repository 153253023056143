import React from 'react';
import api from '../../config/api';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Box, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import useGetTask from '../../hooks/useGetTask';
import MyRequestProposalCard from './MyRequestProposalCard';

const MyRequestProposalsPage = () => {
  const { taskId } = useParams();
  const getProposal = async () => {
    try {
      const { data } = await api.get(`/api/user/proposal?id=${taskId}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const { data: proposals } = useQuery({
    queryKey: ['proposals'],
    queryFn: getProposal,
  });
  const { data: task } = useGetTask(taskId);
  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TopNavigation title={'My Requests'} />
      <TopNavigationSpace />
      <Stack
        spacing={1}
        sx={{
          boxShadow: '0px -3px 20px 0px #0000001A',
          py: '20px',
          px: { sm: '24px', xs: '16px' },
          mx: { sm: '-24px', xs: '-16px' },
        }}
      >
        <Typography variant='h4'>{task?.data?.title}</Typography>
        <Typography variant='h6'>{task?.data?.description}</Typography>
      </Stack>
      <Stack spacing={2} mt={2.5}>
        {proposals?.data?.map((proposal) => (
          <MyRequestProposalCard key={proposal?._id} proposal={proposal} />
        ))}
      </Stack>
    </Box>
  );
};

export default MyRequestProposalsPage;
