import * as React from 'react';

const InfoIconWithBackground = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={64} height={64} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M32 58.667c14.727 0 26.666-11.94 26.666-26.667S46.727 5.333 32 5.333 5.333 17.273 5.333 32 17.273 58.667 32 58.667'
      opacity={0.4}
    />
    <path
      fill='#FDCC0A'
      d='M32 36.667c1.093 0 2-.907 2-2V21.333c0-1.093-.907-2-2-2s-2 .907-2 2v13.334c0 1.093.907 2 2 2M34.453 41.653a3.1 3.1 0 0 0-.56-.88 3.1 3.1 0 0 0-.88-.56 2.67 2.67 0 0 0-2.027 0q-.48.2-.88.56-.36.4-.56.88c-.133.32-.213.667-.213 1.013 0 .347.08.694.213 1.014.134.346.32.613.56.88q.4.36.88.56c.32.133.667.213 1.014.213.346 0 .693-.08 1.013-.213q.48-.2.88-.56c.24-.267.427-.534.56-.88.133-.32.213-.667.213-1.014 0-.346-.08-.693-.213-1.013'
    />
  </svg>
);
export default InfoIconWithBackground;
