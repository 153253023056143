import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  websocket: undefined,
  peerConnection: undefined,
  peerConnectionVideoSender: undefined,
  peerConnectionAudioSender: undefined,
  connected: false,
};
export const webrtcPublishSlice = createSlice({
  name: 'webrtcPublish',
  initialState,
  reducers: {
    setWebsocket(state, action) {
      state.websocket = action.payload;
    },
    setPeerConnection(state, action) {
      state.peerConnection = action.payload;
    },
    setConnected(state, action) {
      state.connected = action.payload;
    },
    setPeerConnectionAudioSender(state, action) {
      state.peerConnectionAudioSender = action.payload;
    },
    setPeerConnectionVideoSender(state, action) {
      state.peerConnectionVideoSender = action.payload;
    },
  },
});

export const {
  setWebsocket,
  setPeerConnection,
  setConnected,
  setPeerConnectionAudioSender,
  setPeerConnectionVideoSender,
} = webrtcPublishSlice.actions;

export default webrtcPublishSlice.reducer;
