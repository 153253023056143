import React from 'react';
import Stack from '@mui/material/Stack';
import ReferralImage from '../../assets/images/ReferralImage';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import api from '../../config/api';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';

const ReferralCodeInputPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { control, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    const { referralCode } = data;
    setIsLoading(true);
    try {
      const { data: isValidReferralCode } = await api.get(`/api/public/validate?referralCode=${referralCode}`);
      if (isValidReferralCode?.data?.isReferralCode) {
        const formData = new FormData();
        formData.append('referralCode', referralCode);
        await api.post('/api/user/profile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Referral added successfully');
        navigate(ROUTES.WELCOME);
      } else {
        setIsLoading(false);
        toast.error('Invalid Referral Code');
      }
    } catch (e) {
      setIsLoading(false);
      toast.error('Error in adding referral code');
    }
  };
  const navigate = useNavigate();
  if (isLoading) return <Loading />;
  return (
    <Stack sx={{ minHeight: '100svh', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <ReferralImage />
      <Typography variant='h5'>Do you have any Referral code?</Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={3} mt={3}>
          <CustomTextField name='referralCode' control={control} label='Enter Code (Optional)' />
          <Stack direction='row' width='100%' spacing={2}>
            <Button variant='outlined' fullWidth onClick={() => navigate(ROUTES.WELCOME)}>
              Skip
            </Button>
            <Button variant='contained' fullWidth type='submit'>
              Next
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default ReferralCodeInputPage;
