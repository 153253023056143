import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getBanksDetails = async () => {
  const { data } = await api.get('/api/user/bank');
  return data;
};

const useGetBanksDetails = () => {
  return useQuery({
    queryKey: ['banks'],
    queryFn: getBanksDetails,
    refetchOnWindowFocus: false,
    initialData: { data: [] },
  });
};

export default useGetBanksDetails;
