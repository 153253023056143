import React from 'react';

function RankingIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' fill='none' viewBox='0 0 19 18'>
      <path
        fill='#FDCC0A'
        d='M10.473 6.086l.99 1.98c.136.27.496.54.796.585l1.792.3c1.147.195 1.418 1.02.592 1.845l-1.395 1.395c-.232.232-.367.69-.292 1.02l.398 1.732c.315 1.365-.413 1.898-1.62 1.185l-1.68-.997c-.308-.18-.803-.18-1.11 0l-1.68.997c-1.208.713-1.936.18-1.62-1.185l.397-1.732c.075-.323-.06-.78-.292-1.02l-1.395-1.395c-.825-.825-.555-1.658.592-1.845l1.793-.3c.3-.053.66-.315.795-.585l.99-1.98c.532-1.073 1.417-1.073 1.95 0z'
      ></path>
      <path
        fill='#FDCC0A'
        d='M4.998 7.313a.567.567 0 01-.562-.563V1.5c0-.308.255-.563.562-.563.308 0 .563.255.563.563v5.25a.567.567 0 01-.563.563zM13.998 7.313a.567.567 0 01-.563-.563V1.5c0-.308.255-.563.563-.563.308 0 .563.255.563.563v5.25a.567.567 0 01-.563.563zM9.498 3.563A.567.567 0 018.936 3V1.5c0-.308.255-.563.562-.563.308 0 .563.255.563.563V3a.567.567 0 01-.563.563z'
        opacity='0.4'
      ></path>
    </svg>
  );
}

export default RankingIcon;
