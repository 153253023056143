import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import useGetTask from '../../hooks/useGetTask';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Loading from '../../components/Loading/Loading';
import api from '../../config/api';
import { toast } from 'react-toastify';
import useGetProposal from '../../hooks/useGetProposal';
import { useQueryClient } from '@tanstack/react-query';
import CustomCalendar from '../../components/CustomCalendar/CustomCalendar';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomDateTimePicker from '../../components/CustomDateTimePicker/CustomDateTimePicker';

const ReschedulePage = () => {
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [date, setDate] = React.useState({ date: null, timeZone: '', error: null });
  const [dateError, setDateError] = useState({ error: false, message: '' });
  const { proposalId, taskId } = useParams();
  const { data: task, isLoading: taskLoading } = useGetTask(taskId);

  const { data: proposal } = useGetProposal(proposalId);

  const { handleSubmit, control, reset } = useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (proposal?.data?.proposeAt) {
      reset({
        date: dayjs(proposal?.data?.proposeAt),
      });
    }
  }, [proposal?.data?.proposeAt, reset]);
  const handleReschedule = async (data) => {
    setIsLoading(true);
    try {
      await api.put(`/api/user/proposal/${proposalId}/schedule`, {
        proposeAt: date?.date.toISOString(),
        timeZone: date.timeZone,
      });
      setIsLoading(false);
      queryClient.invalidateQueries(['proposal', proposalId]);
      toast.success('Request Rescheduled');
    } catch (e) {
      setIsLoading(false);
      toast.error("Request couldn't be rescheduled");
    }
  };
  if (isLoading || taskLoading) return <Loading />;
  return (
    <>
      <Box sx={{ pb: 2, position: 'relative', height: '100dvh' }}>
        <TopNavigation title={'Schedule a live'} />
        <TopNavigationSpace />
        <Stack mt={2.5}>
          <Typography variant='h4'>{task?.data?.title}</Typography>
          <Typography variant='h6'>{task?.data?.description}</Typography>
          <Stack spacing={2} pt={2}>
            <Typography variant='h6'>
              Proposed Amount: <Typography variant='h6_dark'>${task?.data?.amount}</Typography>
            </Typography>
            <Typography variant='h6'>
              Proposed Time:{' '}
              <Typography variant='h6_dark'>
                {dayjs(proposal?.data?.proposeAt).format('DD MMM, YYYY hh:mm A')}
              </Typography>
            </Typography>
            <Typography variant='h6'>
              Requested by: <Typography variant='h6_dark'>{task?.data?.user?.name}</Typography>
            </Typography>
          </Stack>
        </Stack>
        {/*<form onSubmit={handleSubmit(handleReschedule)}>*/}
        <Stack spacing={1.5} mt={4}>
          <CustomDateTimePicker
            label={'Date'}
            onClick={() => setOpenCalendar(true)}
            value={date.date}
            error={dateError}
          />
          <Button variant='contained' sx={{ borderRadius: 50 }} onClick={handleReschedule}>
            Schedule Live
          </Button>
        </Stack>
        {/*</form>*/}
      </Box>
      <CustomDialog open={openCalendar} handleClose={() => setOpenCalendar(false)} maxWidth={'xs'} fullWidth>
        <CustomCalendar dateAndTime={date} setDateAndTime={setDate} handleClose={() => setOpenCalendar(false)} />
      </CustomDialog>
    </>
  );
};

ReschedulePage.propTypes = {};

export default ReschedulePage;
