import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment, Stack, Typography } from '@mui/material';
import BrokenCalenderIcon from '../../assets/icons/BrokenCalenderIcon';
import dayjs from 'dayjs';

const CustomDateTimePicker = ({ label, onClick, value, error, placeholder }) => {
  return (
    <Stack spacing={1}>
      <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
        {label}
      </Typography>
      <TextField
        onClick={onClick}
        value={value ? dayjs(value).format('DD/MM/YYYY, HH:mm') : ''}
        helperText={error ? error.message : null}
        size='small'
        error={error.error}
        fullWidth
        sx={{
          mb: 1,
          '& input': {
            fontSize: '16px',
            fontWeight: 600,
            padding: '14px 20px',
          },
        }}
        InputProps={{
          style: {
            borderRadius: '8px',
            borderColor: 'rgba(0, 0, 0, 0.1)',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <BrokenCalenderIcon />
            </InputAdornment>
          ),
        }}
        variant='outlined'
        placeholder={placeholder}
      />
    </Stack>
  );
};

export default CustomDateTimePicker;
