import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { clearInterval, setInterval } from 'worker-timers';
import ClockIcon from '../../assets/icons/ClockIcon';

const CountdownTimer = ({ duration }) => {
  const [timeDuration, setTimeDuration] = React.useState(duration);
  const [countdownText, setCountdownText] = React.useState({ min: '0', sec: '0' });
  useEffect(() => {
    let interval = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev - 1000;
        if (newTimeDuration === 0) {
          clearInterval(interval);
          interval = null;
        }
        return newTimeDuration;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [duration]);
  useEffect(() => {
    const minutes = timeDuration / 60000;
    const seconds = (timeDuration % 60000) / 1000;
    setCountdownText({
      min: `${minutes < 10 ? '0' : ''}${Math.trunc(minutes)}`,
      sec: `${seconds < 10 ? '0' : ''}${Math.trunc(seconds)}`,
    });
  }, [timeDuration]);
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#5F5D5D80',
        color: '#fff',
        borderRadius: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 70,
      }}
      gap={1}
    >
      <ClockIcon color={'white'} />
      <Typography variant='caption_bold'>
        {countdownText.min}:{countdownText.sec}
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
