// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fadeOut {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/RecordedVideoPlayer/style.css"],"names":[],"mappings":"AAAA;IAEI,uBAAuB;IAEvB,sBAAsB;IAEtB,yBAAyB;AAC7B;;AAWA;IACI;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IAEI,sBAAsB;IAEtB,sBAAsB;IAEtB,yBAAyB;AAC7B;;AAWA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".fadeOut {\n    -webkit-animation-name: fadeOut;\n    animation-name: fadeOut;\n    -webkit-animation-duration: 1s;\n    animation-duration: 1s;\n    -webkit-animation-fill-mode: both;\n    animation-fill-mode: both;\n}\n\n@-webkit-keyframes fadeOut {\n    0% {\n        opacity: 0.5;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n\n@keyframes fadeOut {\n    0% {\n        opacity: 0.5;\n    }\n    100% {\n        opacity: 0;\n    }\n}\n\n.fadeIn {\n    -webkit-animation-name: fadeIn;\n    animation-name: fadeIn;\n    -webkit-animation-duration: 1s;\n    animation-duration: 1s;\n    -webkit-animation-fill-mode: both;\n    animation-fill-mode: both;\n}\n\n@-webkit-keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 0.5;\n    }\n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 0.5;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
