import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../../config/api';
import { Box } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import Loading from '../../components/Loading/Loading';
import BankAccountDetails from './BankAccountDetails';
import EditBankAccountForm from './EditBankAccountForm';

const getBanksDetails = async (id) => {
  const { data } = await api.get(`/api/user/bank/${id}`);
  return data;
};

const EditBankAccountPage = () => {
  const { id } = useParams();
  const { data: bank, isLoading } = useQuery({
    queryKey: ['bank'],
    queryFn: () => getBanksDetails(id),
    refetchOnWindowFocus: false,
  });
  const [editMode, setEditMode] = useState(false);
  if (isLoading) return <Loading />;
  return (
    <Box>
      <TopNavigation title={'Manage'} />
      <TopNavigationSpace />

      {editMode ? (
        <EditBankAccountForm bank={bank.data} setEditMode={setEditMode} />
      ) : (
        <BankAccountDetails bank={bank.data} setEditMode={setEditMode} />
      )}
    </Box>
  );
};

export default EditBankAccountPage;
