import React from 'react';
import BottomDrawer from '../../components/BottomDrawer/BottomDrawer';
import { Typography } from '@mui/material';
import GalleryAddIcon from '../../assets/icons/GalleryAddIcon';
import CustomBorder from '../../components/CustomBorder/CustomBorder';
import Stack from '@mui/material/Stack';
import GalleryEditIcon from '../../assets/icons/GalleryEditIcon';
import GalleryRemoveIcon from '../../assets/icons/GalleryRemoveIcon';
import api from '../../config/api';
import { toast } from 'react-toastify';

const EditProfileImageDrawer = ({
  bottomDrawerState,
  setBottomDrawerState,
  isProfileImageAvailable,
  handleSelectImageClick,
}) => {
  const handleRemoveImage = async () => {
    try {
      await api.get('/api/user/profile/rm-avatar');
      toast.success('Profile pic is removed successfully');
      setBottomDrawerState(false);
    } catch (e) {
      toast.error('Something went wrong');
      setBottomDrawerState(false);
    }
  };
  return (
    <BottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState}>
      {isProfileImageAvailable ? (
        <Stack spacing={1.5}>
          <CustomBorder onClick={handleSelectImageClick}>
            <GalleryEditIcon />
            <Typography variant='h5_dark'>Change Image</Typography>
          </CustomBorder>
          <CustomBorder onClick={handleRemoveImage}>
            <GalleryRemoveIcon />
            <Typography variant='h5_dark'>Remove Image</Typography>
          </CustomBorder>
        </Stack>
      ) : (
        <CustomBorder onClick={handleSelectImageClick}>
          <GalleryAddIcon />
          <Typography variant='h5_dark'>Upload Image</Typography>
        </CustomBorder>
      )}
    </BottomDrawer>
  );
};

export default EditProfileImageDrawer;
