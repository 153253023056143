import React from 'react';
import getInitials from '../../utils/getInitials';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const CustomAvatar = ({ avatarUrl, name = '', size, borderRadius, fontSize }) => {
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  return (
    <>
      {avatarUrl ? (
        <Avatar
          sx={{ width: size, height: size, fontSize: '1.5rem', fontWeight: '600', borderRadius: borderRadius || 50 }}
          src={`${S3_BUCKET}/${avatarUrl}`}
        />
      ) : (
        <Avatar
          sx={{
            width: size,
            height: size,
            fontSize: fontSize || '1.5rem',
            fontWeight: '600',
            borderRadius: borderRadius || 50,
          }}
        >
          {getInitials(name)}
        </Avatar>
      )}
    </>
  );
};

CustomAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
};

export default CustomAvatar;
