import React from 'react';

function LikeIconFilled() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#FDCC0A'
        stroke='#FDCC0A'
        strokeWidth='0.833'
        d='M11.905 6.66h0l-.001.007c-.047.338.03.7.274.973.225.263.551.401.888.401h3.417c.55 0 .983.22 1.216.554.23.333.286.763.11 1.244h0l-.005.014-2.05 6.241-.005.016-.004.016c-.098.4-.375.79-.766 1.082-.39.293-.864.466-1.32.466h-3.25a3.02 3.02 0 01-.811-.122c-.286-.084-.493-.195-.595-.297l-.02-.02-.02-.015-2.605-2.014V8.131l.035-.058 3.51-5.216h0l.003-.003c.12-.182.352-.364.645-.467a1.28 1.28 0 01.87-.014h0l.012.004c.628.212 1.036.927.902 1.523l-.003.013-.002.014-.425 2.733z'
        opacity='0.4'
      ></path>
      <path
        fill='#FDCC0A'
        stroke='#FDCC0A'
        strokeWidth='0.833'
        d='M3.484 5.733h.858c.607 0 .928.12 1.105.29.172.163.295.457.295 1.027v8.383c0 .57-.123.864-.295 1.028-.177.168-.498.289-1.105.289h-.858c-.608 0-.929-.12-1.105-.29-.172-.163-.295-.457-.295-1.027V7.05c0-.57.123-.864.295-1.028.176-.169.497-.289 1.105-.289z'
      ></path>
    </svg>
  );
}

export default LikeIconFilled;
