import * as React from 'react';

const ColorfullWalletIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M29.334 16v6.667c0 4-2.667 6.666-6.667 6.666H9.334c-4 0-6.667-2.666-6.667-6.666V16c0-3.627 2.187-6.16 5.587-6.587q.519-.08 1.08-.08h13.333c.347 0 .68.014 1 .067 3.44.4 5.667 2.947 5.667 6.6'
      opacity={0.4}
    />
    <path
      fill='#111'
      d='M23.668 9.4a6 6 0 0 0-1-.067H9.335q-.56 0-1.08.08c.187-.373.453-.72.773-1.04l4.334-4.346a4.7 4.7 0 0 1 6.613 0l2.333 2.36a4.5 4.5 0 0 1 1.36 3.013'
    />
    <path fill='#292D32' d='M29.334 16.667h-4a2.675 2.675 0 0 0-2.667 2.666c0 1.467 1.2 2.667 2.667 2.667h4' />
  </svg>
);
export default ColorfullWalletIcon;
