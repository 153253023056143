import * as React from 'react';

function CakeIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.89 13v1h-.87c-.74 0-1.34.6-1.34 1.35v.3a1.344 1.344 0 11-2.69 0v-.3c0-.75-.61-1.35-1.35-1.35-.74 0-1.34.6-1.34 1.35v.3a1.35 1.35 0 01-2.7 0v-.3c0-.75-.6-1.35-1.34-1.35-.74 0-1.35.6-1.35 1.35v.3a1.344 1.344 0 11-2.69 0v-.32c0-.74-.59-1.34-1.32-1.35h-.79V13c0-1.38 1.04-2.55 2.45-2.89.28-.07.57-.11.88-.11h11.12c.31 0 .6.04.88.11 1.41.34 2.45 1.51 2.45 2.89z"
        fill="#C7AB38"
      />
      <path
        opacity={0.4}
        d="M18.44 7.17v2.94c-.28-.07-.57-.11-.88-.11H6.44c-.31 0-.6.04-.88.11V7.17C5.56 5.97 6.64 5 7.98 5h8.04c1.34 0 2.42.97 2.42 2.17z"
        fill="#C7AB38"
      />
      <path
        d="M8.75 3.55v1.46h-.77c-.26 0-.5.03-.73.09V3.55c0-.35.34-.65.75-.65s.75.3.75.65zM16.75 3.33V5.1c-.23-.07-.47-.1-.73-.1h-.77V3.33c0-.41.34-.75.75-.75s.75.34.75.75zM12.75 2.82V5h-1.5V2.82c0-.45.34-.82.75-.82s.75.37.75.82z"
        fill="#C7AB38"
      />
      <path
        opacity={0.4}
        d="M21.25 20.5h-.36V14h-.87c-.74 0-1.34.6-1.34 1.35v.3a1.344 1.344 0 11-2.69 0v-.3c0-.75-.61-1.35-1.35-1.35-.74 0-1.34.6-1.34 1.35v.3a1.35 1.35 0 01-2.7 0v-.3c0-.75-.6-1.35-1.34-1.35-.74 0-1.35.6-1.35 1.35v.3a1.344 1.344 0 11-2.69 0v-.32c0-.74-.59-1.34-1.32-1.35h-.79v6.52h-.36c-.41 0-.75.34-.75.75s.34.75.75.75h18.5c.41 0 .75-.34.75-.75s-.34-.75-.75-.75z"
        fill="#C7AB38"
      />
    </svg>
  );
}

export default CakeIcon;