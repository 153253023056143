import React from 'react';
import VideoDescriptionTag from '../../../../components/VideoDescriptionTag/VideoDescriptionTag';
import BackgroundImage from '../../../../components/BackgroundImage/BackgroundImage';
import { useQuery } from '@tanstack/react-query';
import api from '../../../../config/api';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import LoadingSmall from '../../../../components/Loading/LoadingSmall';

const getRecording = async (streamId) => {
  const { data } = await api.get(`/api/stream/recordings/${streamId}`);
  return data;
};

const PopularVideoCard = ({ data }) => {
  const streamId = data.streamDetails.liveStreamId;
  const taskId = data._id;
  const { data: recording, isLoading } = useQuery({
    queryKey: ['recording', streamId],
    queryFn: () => getRecording(streamId),
  });
  const navigate = useNavigate();
  const totalDuration = recording?.data?.asset?.reduce((acc, curr) => acc + curr.asset.duration, 0);
  if (isLoading) return <LoadingSmall />;
  return (
    <BackgroundImage
      img={recording?.data?.asset?.[0]?.asset?.thumbnail_url}
      // width={180}
      height={250}
      borderRadius={10}
      border={(theme) => `2px solid ${theme.palette.primary.main}`}
      display={'flex'}
      cursor='pointer'
      onClick={() => navigate(ROUTES.RECORDING_PLAY + '?streamId=' + streamId + '&taskId=' + taskId)}
    >
      <VideoDescriptionTag duration={totalDuration} viewers={null} chats={null} likes={null} />
    </BackgroundImage>
  );
};

export default PopularVideoCard;
