import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddBankIcon from '../../assets/icons/AddBankIcon';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import api from '../../config/api';
import { useForm } from 'react-hook-form';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import { toast } from 'react-toastify';
import useGetBanksDetails from '../../hooks/useGetBanksDetails';
import Loading from '../../components/Loading/Loading';
import CustomBorder from '../../components/CustomBorder/CustomBorder';

const SelectPaymentMethod = ({ amount }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data: banks } = useGetBanksDetails();
  const bank = banks.data[0];
  console.log(bank);
  const len = bank?.accNo.toString().length;
  const maskedAccNo = 'X'.repeat(len - 4) + bank?.accNo.toString().slice(-4);

  const bankOptions = banks?.data?.map((bank) => {
    const len = bank?.accNo.toString().length;
    const maskedAccNo = 'X'.repeat(len - 4) + bank?.accNo.toString().slice(-4);
    return { value: bank?._id, title: `${bank?.bankName} | ${maskedAccNo}` };
  });
  const { handleSubmit, control, watch } = useForm();
  const onSelectedBank = watch('bank');
  const [openModal, setOpenModal] = useState(false);

  const onSubmit = async (body) => {
    setIsLoading(true);
    try {
      await api.post('/api/user/withdraw', body);
      setIsLoading(false);
      setOpenModal(true);
    } catch (e) {
      setIsLoading(false);
      toast.error('Something went wrong');
      console.log(e);
    }
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <Box position='relative' sx={{ minHeight: '90vh' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <MenuHeader title={'Saved Bank Accounts'} />
            {bank && (
              <CustomBorder isActive={true} onClick={() => onSubmit({ bank: bank?._id, amount })}>
                <Typography variant='h6'>
                  <Typography variant='h6_bold'>{bank?.bankName}</Typography> | {maskedAccNo}
                </Typography>
              </CustomBorder>
            )}

            {banks.data.length === 0 && (
              <Button
                variant='contained'
                size='large'
                rounded
                endIcon={<AddBankIcon />}
                onClick={() => navigate(ROUTES.ADD_BANK_ACCOUNT.replace(':amount', amount))}
              >
                Add Bank Account
              </Button>
            )}
          </Stack>
        </form>
      </Box>
      <SuccessModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={'Withdrawal Request Placed'}
        message={'Withdrawal request has been placed'}
        btnText={'OK'}
        onClick={() => {
          setOpenModal(false);
          navigate(ROUTES.WALLET);
        }}
      />
    </>
  );
};

export default SelectPaymentMethod;
