import Box from '@mui/material/Box';
import React from 'react';

const BackgroundImage = ({ img, width, height, borderRadius, children, onClick, ...rest }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${img})`,
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        ...rest,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default BackgroundImage;
