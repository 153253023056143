import * as React from 'react';

function LikeIcon(props) {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.9 9.959c.417-1.167-.333-2.167-1.583-2.167h-3.334c-.5 0-.916-.417-.833-1l.417-2.667c.166-.75-.334-1.583-1.084-1.833-.666-.25-1.5.083-1.833.583L6.233 7.96M6.233 15.29l2.584 2c.333.333 1.083.5 1.583.5h3.167c1 0 2.083-.75 2.333-1.75l1.033-3.142'
        stroke='#fff'
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.983 15.29V7.121c0-1.166.5-1.583 1.667-1.583h.833c1.167 0 1.667.417 1.667 1.583v8.167c0 1.167-.5 1.583-1.667 1.583H3.65c-1.167 0-1.667-.416-1.667-1.583z'
        stroke='#fff'
        strokeWidth={1.25}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default LikeIcon;
