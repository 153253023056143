import React from 'react';

const EmptyHeartIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none' {...props}>
      <path
        stroke='#111'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M13.726 3.813c.587.66.94 1.527.94 2.48 0 4.667-4.32 7.42-6.253 8.087-.227.08-.6.08-.827 0-1.933-.667-6.253-3.42-6.253-8.087 0-2.06 1.66-3.727 3.707-3.727 1.213 0 2.286.587 2.96 1.494a3.686 3.686 0 0 1 2.96-1.494'
      />
    </svg>
  );
};

export default EmptyHeartIcon;
