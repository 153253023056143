import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import WarningIcon from '../../assets/icons/WarningIcon';
import CustomDialog from '../../components/CustomDialog/CustomDialog';

const EndLiveStreamModal = ({ open = false, handleClose, endLiveStream }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'} textAlign='center'>
        <WarningIcon />
        <Stack spacing={1}>
          <Typography variant='h5_dark'>Are you Sure?</Typography>
          <Typography variant='h6'>Are you sure you want to end this stream?</Typography>
        </Stack>
        <Stack spacing={2}>
          <Button variant='contained' fullWidth sx={{ borderRadius: 50 }} onClick={endLiveStream} size='large'>
            End Stream
          </Button>
          <Typography variant='h5_bold' onClick={handleClose} sx={{ cursor: 'pointer' }}>
            Not now
          </Typography>
        </Stack>
      </Stack>
    </CustomDialog>
  );
};

export default EndLiveStreamModal;
