import React, { useState } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useProfile from '../../hooks/useProfile';
import Loading from '../../components/Loading/Loading';
import RequestVideoForm from '../RequestVideoPage/RequestVideoForm';
import { taskDetails } from '../../store';
import ConfirmBottomDrawer from '../RequestVideoPage/ConfirmBottomDrawer';
import getInitials from '../../utils/getInitials';
import useSetting from '../../hooks/useSetting';

const RequestVideoToStreamerPage = () => {
  const [deadline, setDeadline] = useState({
    date: null,
    timeZone: '',
  });
  const { streamerId } = useParams();
  const { data: setting } = useSetting();

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const [bottomDrawerState, setBottomDrawerState] = React.useState(false);
  const onSubmit = async (value) => {
    if (deadline.date !== null) {
      const fee = (parseFloat(value.amount) * setting?.data?.platformFeePercentage) / 100;
      taskDetails.value = {
        ...value,
        requestAt: deadline.date.toISOString(),
        type: 'REQUEST',
        duration: value.duration.value,
        fee: fee.toFixed(2),
        timeZone: deadline.timeZone,
        requestedTo: streamerId,
      };
      console.log(taskDetails.value);
      setBottomDrawerState(true);
    }
  };
  const { data: profile, isLoading } = useProfile(streamerId);
  if (isLoading) return <Loading />;

  return (
    <>
      <Box sx={{ position: 'relative', pb: 8 }}>
        <TopNavigation title={'Request'} />
        <TopNavigationSpace />
        <Box
          sx={{
            py: 1.5,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            boxShadow: '0px -3px 20px 0px #0000001A',
            mx: { sm: '-24px', xs: '-16px' },
            px: { sm: '24px', xs: '16px' },
          }}
        >
          <Avatar src={`${S3_BUCKET}/${profile?.data?.profile?.avatarUrl}`}>{getInitials(profile.data.name)}</Avatar>
          <Stack>
            <Typography variant='h5_bold'>{profile?.data?.name}</Typography>
            <Typography variant={'h6_semibold'} sx={{ color: '#11111180' }}>
              {profile?.data?.profile?.streamCount} Videos Created
            </Typography>
          </Stack>
        </Box>
        <RequestVideoForm onSubmit={onSubmit} deadline={deadline} setDeadline={setDeadline} />
      </Box>
      <ConfirmBottomDrawer bottomDrawerState={bottomDrawerState} setBottomDrawerState={setBottomDrawerState} />
    </>
  );
};

export default RequestVideoToStreamerPage;
