import React from 'react';
import Stack from '@mui/material/Stack';
import NoPageFoundImage from '../../assets/images/NoPageFoundImage';
import Button from '@mui/material/Button';

const NoPageFoundPage = () => {
  return (
    <Stack sx={{ minHeight: '100dvh', justifyContent: 'center', alignItems: 'center' }} spacing={4}>
      <NoPageFoundImage />
      <Button variant='contained' sx={{ width: '50%' }}>
        Go Back
      </Button>
    </Stack>
  );
};

export default NoPageFoundPage;
