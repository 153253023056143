import React from 'react';
import { Box } from '@mui/material';

const CustomBorder = ({ children, isActive = false, onClick }) => {
  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: (theme) => `1px solid ${isActive ? theme.palette.primary.main : theme.palette.border.main}`,
        p: '16px 20px',
        display: 'flex',
        gap: 1.25,
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default CustomBorder;
