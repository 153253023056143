import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getTask = async (taskId) => {
  const { data } = await api.get(`/api/user/task/${taskId}`);
  return data;
};

const useGetTask = (taskId) => {
  return useQuery({
    queryKey: ['task', taskId],
    queryFn: () => getTask(taskId),
    enabled: !!taskId,
    refetchOnWindowFocus: false,
  });
};

export default useGetTask;
