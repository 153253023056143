import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import './style.css';
import Routes from '../../routes';
import ROUTES from '../../routes';
import { Box } from '@mui/material';
import GettingStartedCard from './components/GettingStartedCard';
import Button from '@mui/material/Button';
import SplashScreenPage from '../SplashScreenPage/SplashScreenPage';
import CreateAStreamingRequestImage from '../../assets/images/getStartingPage/CreateAStreamingRequestImage';
import AgreeOnTimingAndConditionsImage from '../../assets/images/getStartingPage/AgreeOnTimingAndConditionsImage';
import WaitForCreatorResponseImage from '../../assets/images/getStartingPage/WaitForCreatorResponseImage';
import EnjoyTheStreamInYourTimeImage from '../../assets/images/getStartingPage/EnjoyTheStreamInYourTimeImage';
import { nanoid } from 'nanoid';

const sliderArray = [
  {
    image: <CreateAStreamingRequestImage />,
    title: 'Create a Streaming Request',
    subtitle: 'Submit a streaming request with remuneration for the Creators to execute',
  },
  {
    image: <WaitForCreatorResponseImage />,
    title: 'Wait for Creator’s Response',
    subtitle: 'Creators select and complete the requests in a live stream',
  },
  {
    image: <AgreeOnTimingAndConditionsImage />,
    title: 'Agree On Timing and Conditions',
    subtitle: 'bunpun streamlines client-streamer agreement for timing and setup conditions',
  },
  {
    image: <EnjoyTheStreamInYourTimeImage />,
    title: 'Enjoy the stream in "Your" time',
    subtitle: 'Interface enables chat, comments, sharing, likes; creators earn remuneration',
  },
];

const GetStartedPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  let sliderRef = useRef(null);
  useEffect(() => {
    const isNewUser = JSON.parse(localStorage.getItem('isNewUser'));
    console.log(isNewUser);
    if (!isNewUser && isNewUser !== null) {
      navigate(ROUTES.LOGIN);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreenPage />
      ) : (
        <Box
          sx={{
            // minHeight: '100dvh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
            overflowX: 'hidden',
            height: '100dvh',
            alignItems: 'center',
          }}
        >
          <swiper-container ref={sliderRef} slides-per-view={1} pagination='true' style={{ width: '100%' }}>
            {sliderArray.map((item) => (
              <swiper-slide style={{ width: '100%', display: 'flex', alignItems: 'center' }} key={nanoid()}>
                <GettingStartedCard image={item.image} title={item.title} subtitle={item.subtitle} />
              </swiper-slide>
            ))}
          </swiper-container>
          <Stack
            spacing={2}
            direction='row'
            sx={{
              position: 'absolute',
              bottom: '1.5rem',
              width: '100%',
              display: 'flex',
              zIndex: 10,
              // mt: '1.5rem',
            }}
          >
            {currentSlide !== 3 ? (
              <Button variant='outlined' size='large' onClick={() => navigate(Routes.REGISTER)} fullWidth>
                Skip
              </Button>
            ) : (
              <Button variant='contained' size='large' onClick={() => navigate(Routes.REGISTER)} fullWidth>
                Join Now
              </Button>
            )}
            {currentSlide !== 3 && (
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  sliderRef?.current?.swiper?.slideTo(currentSlide + 1);
                  setCurrentSlide(currentSlide + 1);
                }}
                fullWidth
              >
                Next
              </Button>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default GetStartedPage;
