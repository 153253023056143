const checkCameraAndMicAccess = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    stream.getTracks().forEach((track) => track.stop()); // Stop the tracks to release the camera and mic
    return true;
  } catch (error) {
    console.error('Error accessing camera and/or microphone:', error);
    return false;
  }
};

export default checkCameraAndMicAccess;
