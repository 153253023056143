import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { useNavigate } from 'react-router-dom';
import useProfile from '../../hooks/useProfile';
import Loading from '../../components/Loading/Loading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ContactIcon from '../../assets/icons/ContactIcon';
import CakeIcon from '../../assets/icons/CakeIcon';
import LocationIcon from '../../assets/icons/LocationIcon';
import SmsIcon from '../../assets/icons/SmsIcon';

dayjs.extend(customParseFormat);

const CustomHeader = ({ label, value, Icon }) => {
  return (
    <Box display={'flex'} gap={1}>
      <Icon />
      <Stack spacing={0.5}>
        <Typography variant={'h6_bold'}>{label}</Typography>
        <Typography variant={'h6'}>{value}</Typography>
      </Stack>
    </Box>
  );
};

const PersonalDetailsPage = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading } = useProfile();

  if (isLoading) return <Loading />;
  return (
    <Box sx={{ pb: 2 }}>
      <TopNavigation title={'Personal Details'} />
      <TopNavigationSpace />
      <Stack pt={2.5} spacing={2.5}>
        <CustomHeader label={'Name'} value={profile?.data?.name} Icon={ContactIcon} />
        <CustomHeader
          label={'Date of Birth'}
          value={profile?.data?.profile?.dob ? dayjs(profile?.data?.profile?.dob).format('DD MMM, YYYY') : '-'}
          Icon={CakeIcon}
        />
        <CustomHeader label={'Location'} value={profile?.data?.profile?.address || '-'} Icon={LocationIcon} />
        <CustomHeader label={'Email Address'} value={profile?.data?.email} Icon={SmsIcon} />
      </Stack>
    </Box>
  );
};

export default PersonalDetailsPage;
