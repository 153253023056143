import React from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography, useRadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomRadio = ({ value, title, subtitle, icon, isActive }) => {
  const radioGroup = useRadioGroup();
  return (
    <FormControlLabel
      value={value}
      checked={radioGroup.value === value}
      control={
        <Radio
          sx={{
            '& .MuiRadio-root': {
              p: 0,
              fontSize: 16,
            },
          }}
        />
      }
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          {icon && icon}
          <Stack>
            <Typography variant='h5_dark'>{title}</Typography>
            <Typography variant='caption'>{subtitle}</Typography>
          </Stack>
        </Box>
      }
      labelPlacement='start'
    />
  );
};

const CustomRadioButtonNew = ({ name, control, options, labelPlacement = 'end' }) => {
  return (
    <FormControl component='fieldset'>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
          <RadioGroup value={value} onChange={onChange}>
            <Stack spacing={1.2}>
              {options.map((option) => (
                <CustomRadio
                  key={option.value}
                  value={option.value}
                  title={option.title}
                  subtitle={option.subtitle}
                  icon={option?.icon}
                  isActive={value === option.value}
                  onChange={onChange}
                  labelPlacement={labelPlacement}
                />
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default CustomRadioButtonNew;
