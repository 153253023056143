import * as React from 'react';

function ClockIcon(props) {
  return (
    <svg width={14} height={14} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.164 8.855l-1.808-1.08c-.315-.186-.572-.635-.572-1.002V4.38'
        stroke={props.color === 'white' ? '#fff' : '#1E1D25'}
        strokeWidth={0.875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.334 3.5A5.835 5.835 0 007 12.833 5.835 5.835 0 0012.833 7a5.836 5.836 0 00-8.185-5.338'
        stroke={props.color === 'white' ? '#fff' : '#1E1D25'}
        strokeWidth={0.875}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ClockIcon;
