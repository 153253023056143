import { Box } from '@mui/material';
import React from 'react';
import MenuHeader from '../../MenuHeader';
import Slider from 'react-slick';
import LiveStreamCard from './LiveStreamCard';
// import data from '../../data';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import NoLiveStreamCard from './NoLiveStreamCard';
import useLiveStream from '../../../../hooks/useLiveStream';
import Loading from '../../../../components/Loading/Loading';

const liveStreamsSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
};

const LiveStreamComponent = () => {
  const navigate = useNavigate();
  const { data: allLiveStreams, isLoading } = useLiveStream();
  console.log(allLiveStreams);
  if (isLoading) return <Loading />;
  return (
    <Box mt={4}>
      <MenuHeader
        title={'Live Streams'}
        onClick={() => {
          navigate(ROUTES.LIVE_ALL + '?isBottomNavigation=true');
        }}
        isViewAll={allLiveStreams?.data?.totalCount > 0}
      />

      <Box mt={2}  sx={{ overflowX: 'hidden' }}>
        {allLiveStreams?.data?.totalCount > 0 ? (
          <Slider {...liveStreamsSliderSettings}>
            {allLiveStreams.data?.result?.map((liveShow) => (
              <LiveStreamCard key={liveShow.title} data={liveShow} />
            ))}
          </Slider>
        ) : (
          <NoLiveStreamCard />
        )}
      </Box>
    </Box>
  );
};

export default LiveStreamComponent;
