import React from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const WalletBalance = ({ wallet }) => {
  return (
    <Stack
      spacing={0.5}
      p='20px'
      textAlign='center'
      sx={{
        borderRadius: '12px',
        backgroundColor: (theme) => theme.palette.primary.main,
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='h6'>Balance</Typography>
      <Typography variant='h1'>{`$${wallet?.data?.totalBalance?.toFixed(2)}`}</Typography>
    </Stack>
  );
};

export default WalletBalance;
