import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';

const MenuHeader = ({ title, top, icon, iconClick }) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        position: top ? 'sticky' : 'relative',
        top: top,
        backgroundColor: '#fff',
        py: '12px',
      }}
    >
      <Typography variant={'h4'}>{title}</Typography>
      {icon && <IconButton onClick={iconClick}>{icon}</IconButton>}
    </Box>
  );
};

export default MenuHeader;
