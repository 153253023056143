import api from '../config/api';
import { useQueries, useQuery } from '@tanstack/react-query';

const getRecordings = async (streamId) => {
  if (streamId) {
    const { data } = await api.get(`/api/user/profile/streams?page=1&size=10&id=${streamId}`);
    return data;
  } else {
    const { data } = await api.get('/api/user/profile/streams?page=1&size=10');
    return data;
  }
};

const useGetRecordings = (streamId) => {
  const { data: recordings, isLoading } = useQuery({
    queryKey: ['getRecordings'],
    queryFn: () => getRecordings(streamId),
  });
  const { data: result, pending } = useQueries({
    queries:
      recordings?.data?.totalCount > 0
        ? recordings?.data?.result?.map((task) => {
            const streamId = task?.streamDetails?.liveStreamId;
            return {
              queryKey: ['recordings', streamId],
              queryFn: () => api.get(`/api/stream/recordings/${streamId}`),
              refetchOnWindowFocus: false,
            };
          })
        : [],
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      };
    },
  });
  return { recordings, result, isLoading, pending };
};
export default useGetRecordings;
