import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import ClockIcon from '../../../assets/icons/ClockIcon';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import relativeTime from 'dayjs/plugin/relativeTime';
import ROUTES from '../../../routes';
import useInfiniteScrollUtils from '../../../utils/useInfiniteScrollUtils';
import CustomAvatar from '../../../components/CustomAvatar/CustomAvatar';

dayjs.extend(relativeTime);

const AcceptedTaskCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const observer = useRef();
  const userChatLink = ROUTES.CHAT_USER.replace(':taskId', data?.task?._id);
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  console.log(data);
  return (
    <Card sx={{ backgroundColor: '#f6f6f6', mb: 1 }} ref={index === totalCount - 1 ? lastElementRef : null}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Stack spacing={1}>
              <Typography variant='h5_bold'>{data?.task?.title}</Typography>
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <ClockIcon />
                <Typography variant='h6'>{dayjs(data?.createdAt).fromNow()}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box display='flex' justifyContent='space-between'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <CustomAvatar avatarUrl={data?.task?.user?.profile?.avatarUrl} name={data?.task?.user?.name} />
              <Typography variant='h6_bold'>{data?.task?.user?.name}</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ fontSize: '12px' }}
              onClick={() => navigate(userChatLink)}
              disabled={dayjs(data?.task?.requestAt).add(data?.task?.duration, 'm').isBefore(Date.now())}
            >
              {dayjs(data?.task?.requestAt).add(data?.task?.duration, 'm').isBefore(Date.now())
                ? 'Task Expired'
                : 'View'}
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

AcceptedTaskCard.propTypes = {};

export default AcceptedTaskCard;
