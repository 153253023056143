import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import CustomTextFeildWithLabel from '../../components/CustomTextFeildWithLabel/CustomTextFeildWithLabel';
import CustomTextAreaWithLabel from '../../components/CustomTextAreaWithLabel/CustomTextAreaWithLabel';
import Button from '@mui/material/Button';
import resizeImage from '../../utils/resizeImage';
import DocumentUploadIcon from '../../assets/icons/DocumentUploadIcon';
import CancelIcon from '../../assets/icons/CancelIcon';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

const FeedbackPage = () => {
  const { handleSubmit, control } = useForm();
  const [files, setFiles] = React.useState([]);
  const [resizedFiles, setResizedFiles] = React.useState([]);
  const [totalFileSelected, setTotalFileSelected] = React.useState(0);
  const navigate = useNavigate();

  const handleImageSelect = async (e) => {
    console.log(e.target.files);
    let allImages = true;
    Object.values(e.target.files).forEach((file) => {
      if (!file.type.includes('image')) {
        allImages = false;
      }
    });
    if (!allImages) {
      toast.error(`Invalid file format`);
      return;
    }
    setTotalFileSelected(Object.values(e.target.files).length);
    setFiles(Object.values(e.target.files));

    const modifiedFiles = await Promise.all(Object.values(e.target.files).map(async (file) => await resizeImage(file)));
    setResizedFiles(modifiedFiles);
  };
  const handleSelectImageClick = () => {
    document.getElementById('screenShot').click();
  };
  const onSubmit = async (value) => {
    const { title, comment } = value;
    const formData = new FormData();
    resizedFiles.forEach((file) => {
      formData.append('file', file);
    });

    formData.append('title', title);
    formData.append('comment', comment);
    try {
      await api.post('/api/user/feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Feedback posted successfully');
      navigate(ROUTES.PROFILE);
    } catch (e) {
      toast.error('Something went wrong');
      console.log(e);
    }
  };
  return (
    <Box sx={{ pb: 2 }}>
      <TopNavigation title='Feedback' />
      <TopNavigationSpace />
      <Stack spacing={2} mt={2.5}>
        <Typography variant='h3_dark'>Hello, let’s get in touch!</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <CustomTextFeildWithLabel name='title' control={control} label='Title' rules={{ required: true }} />
            <CustomTextAreaWithLabel
              name='comment'
              control={control}
              label='Please tell us more'
              rules={{ required: true }}
            />
            <Stack spacing={1}>
              <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
                Found a bug? Attach a screenshot!
              </Typography>
              <Box
                sx={{
                  padding: '14px 20px',
                  borderRadius: '8px',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction='row' spacing={1}>
                  {files.map((file) => (
                    <Box sx={{ position: 'relative' }} key={file?.name}>
                      <img src={URL.createObjectURL(file)} alt={file?.name} width={30} height={30} />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: -8,
                          right: -8,
                          zIndex: 99,
                          backgroundColor: '#fff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setFiles(files.filter((originalFile) => originalFile?.name !== file?.name));
                        }}
                      >
                        <CancelIcon size={16} />
                      </Box>
                    </Box>
                  ))}
                </Stack>
                <IconButton onClick={handleSelectImageClick}>
                  <DocumentUploadIcon />
                </IconButton>
              </Box>
              <input
                id='screenShot'
                type='file'
                hidden
                onChange={(e) => handleImageSelect(e)}
                accept='image/*'
                multiple
              />
            </Stack>

            <Button variant='contained' type='submit'>
              Submit Feedback
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

export default FeedbackPage;
