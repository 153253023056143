import React from 'react';

function HeartIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' fill='none' viewBox='0 0 19 18'>
      <path fill='#FDCC0A' d='M9.498 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z' opacity='0.4'></path>
      <path
        fill='#FDCC0A'
        d='M9.746 12.752a.952.952 0 01-.503 0c-1.17-.398-3.795-2.07-3.795-4.905a2.255 2.255 0 012.25-2.265c.735 0 1.388.353 1.8.908a2.244 2.244 0 011.8-.908 2.255 2.255 0 012.25 2.265c0 2.835-2.625 4.507-3.802 4.905z'
      ></path>
    </svg>
  );
}

export default HeartIcon;
