import * as React from 'react';

function ProfileIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.397 2.31a2.587 2.587 0 01-2.053 4.03 1.061 1.061 0 00-.193 0A2.578 2.578 0 012.66 3.758c0-1.43 1.155-2.59 2.59-2.59M9.573 2.333a2.04 2.04 0 012.041 2.042c0 1.103-.875 2-1.966 2.042a.658.658 0 00-.151 0M2.427 8.493c-1.412.945-1.412 2.485 0 3.425 1.604 1.073 4.235 1.073 5.839 0 1.412-.945 1.412-2.485 0-3.425-1.598-1.067-4.23-1.067-5.84 0zM10.698 11.667a2.82 2.82 0 001.144-.508c.91-.682.91-1.808 0-2.49a2.901 2.901 0 00-1.126-.502"
        stroke="#292D32"
        strokeWidth={0.875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileIcon;
