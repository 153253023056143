import * as React from 'react';

function GoLiveIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M19.95 36.667c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.205 7.462 16.667 16.667 16.667z"
        fill="#FDCC0A"
      />
      <path
        d="M24.95 17.05l-4.833-2.783c-1.2-.7-2.65-.7-3.85 0a3.808 3.808 0 00-1.916 3.333v5.583c0 1.384.716 2.634 1.916 3.334.6.35 1.267.516 1.917.516.667 0 1.317-.166 1.917-.516l4.833-2.784A3.808 3.808 0 0026.85 20.4c.033-1.4-.684-2.65-1.9-3.35z"
        fill="#292D32"
      />
    </svg>
  );
}

export default GoLiveIcon;