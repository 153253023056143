import * as React from 'react';

const GalleryEditIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} fill='none' {...props}>
    <path
      fill='#FDCC0A'
      d='M36.667 23.167v3.817c0 6.066-3.617 9.683-9.684 9.683H13.017c-4.25 0-7.317-1.783-8.75-4.95l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.467c1.3 1.116 3.4 1.116 4.7 0l6.933-5.95c1.3-1.117 3.4-1.117 4.7 0z'
    />
    <path
      fill='#292D32'
      d='M34.95 13.334h-4.9c-2.117 0-3.383-1.267-3.383-3.384v-4.9c0-.667.133-1.233.366-1.716H13.018c-6.067 0-9.683 3.616-9.683 9.683v13.967c0 1.816.316 3.4.933 4.733l.183-.133 8.2-5.5c1.333-.9 3.217-.8 4.4.233l.567.466c1.3 1.117 3.4 1.117 4.7 0l6.933-5.95c1.3-1.116 3.4-1.116 4.7 0l2.717 2.334v-10.2c-.483.233-1.05.367-1.717.367'
      opacity={0.4}
    />
    <path
      fill='#292D32'
      d='M15 17.3a3.967 3.967 0 1 0 0-7.933 3.967 3.967 0 0 0 0 7.933M34.95 1.667h-4.9c-2.117 0-3.384 1.266-3.384 3.383v4.9c0 2.117 1.267 3.383 3.384 3.383h4.9c2.116 0 3.383-1.267 3.383-3.383v-4.9c0-2.117-1.267-3.383-3.383-3.383m-3.267 9.283a.44.44 0 0 1-.25.117l-1.733.25c-.05.016-.117.016-.167.016-.25 0-.466-.083-.617-.25a.88.88 0 0 1-.233-.783l.25-1.734a.6.6 0 0 1 .117-.25l2.833-2.833c.05.117.1.25.15.384.067.133.133.25.2.366.05.1.117.2.184.267.066.1.133.2.183.25.033.05.05.066.066.083.15.183.317.35.467.467.033.033.067.067.084.067.083.066.183.15.25.2.1.066.183.133.283.183.117.066.25.133.383.2s.267.116.383.15zm3.983-3.983-.533.533a.15.15 0 0 1-.133.066h-.05c-1.2-.35-2.15-1.3-2.5-2.5a.19.19 0 0 1 .05-.183l.55-.55c.9-.9 1.75-.883 2.633 0 .45.45.667.883.667 1.317-.017.433-.233.866-.684 1.316'
    />
  </svg>
);
export default GalleryEditIcon;
