import React from 'react';

function DownloadIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.875'
        d='M5.099 12.543c-2.608 0-3.652-1.044-3.652-3.651v-.076c0-2.345.846-3.424 2.911-3.617M9.59 5.191c2.1.181 2.957 1.26 2.957 3.623v.076c0 2.607-1.044 3.651-3.651 3.651H7.589M7 1.168v7.513M8.954 7.379L7 9.333 5.046 7.38'
      ></path>
    </svg>
  );
}

export default DownloadIcon;
