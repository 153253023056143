import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ClockIcon from '../../../assets/icons/ClockIcon';
import ProfileIcon from '../../../assets/icons/ProfileIcon';
import useInfiniteScrollUtils from '../../../utils/useInfiniteScrollUtils';
import ROUTES from '../../../routes';

const PendingRequestCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();
  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  const MY_REQUEST_PROPOSALS_LINK = ROUTES.MY_REQUEST_PROPOSALS.replace(':taskId', data?._id);
  return (
    <Card sx={{ backgroundColor: '#f6f6f6', mb: 1 }} ref={index === totalCount - 1 ? lastElementRef : null}>
      <CardContent>
        <Stack spacing={2} width={'100%'}>
          <Stack spacing={1} textAlign='start'>
            <Typography variant={'h5_bold'}>{data?.title}</Typography>
            <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
              <ClockIcon />
              <Typography variant={'h6'}>{dayjs(data?.createdAt).fromNow()}</Typography>
            </Stack>
          </Stack>
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <ProfileIcon />
              <Typography variant={'h6_bold'}>{data?.proposalCount}</Typography>
            </Stack>
            <Button
              variant={'contained'}
              sx={{ borderRadius: 50, fontSize: '12px' }}
              onClick={() => navigate(MY_REQUEST_PROPOSALS_LINK)}
            >
              Review Proposals
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PendingRequestCard;
