import React from 'react';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { Button, Stack, Typography } from '@mui/material';
import InsufficientWalletIcon from '../../assets/icons/InsufficientWalletIcon';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

const InsufficientWalletModal = ({ open = false, handleClose, setIsChangePaymentMethod }) => {
  const navigate = useNavigate();
  return (
    <CustomDialog open={open} handleClose={handleClose} maxWidth={'xs'}>
      <Stack spacing={3} alignItems={'center'} sx={{ p: '40px 32px', width: '100%' }}>
        <InsufficientWalletIcon />
        <Stack spacing={1} alignItems={'center'} textAlign='center'>
          <Typography variant='h5_dark'>Uh-oh!</Typography>
          <Typography variant='h6'>You have insufficient wallet balance!</Typography>
        </Stack>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Button variant='contained' fullWidth onClick={() => navigate(ROUTES.WALLET_ADD_MONEY)}>
            Add Money
          </Button>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => {
              setIsChangePaymentMethod(true);
              handleClose();
            }}
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </CustomDialog>
  );
};

export default InsufficientWalletModal;
