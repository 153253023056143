// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

:root {
    --black: #000000;
    --white: #ffffff;
    --yellow: #fae80a;
    --swiper-theme-color: #fae80a !important;
}
.swiper-pagination-bullet .swiper-pagination-bullet-active{
    background: #fae80a !important;
}
.swiper-pagination-bullet-active {
    color: #fff;
    background: #fae80a !important;
}

a{
    text-decoration: none;
    color: var(--black);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,wCAAwC;AAC5C;AACA;IACI,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n:root {\n    --black: #000000;\n    --white: #ffffff;\n    --yellow: #fae80a;\n    --swiper-theme-color: #fae80a !important;\n}\n.swiper-pagination-bullet .swiper-pagination-bullet-active{\n    background: #fae80a !important;\n}\n.swiper-pagination-bullet-active {\n    color: #fff;\n    background: #fae80a !important;\n}\n\na{\n    text-decoration: none;\n    color: var(--black);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
