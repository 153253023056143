import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';

const MenuHeader = ({ title, onClick, isViewAll = true }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width='100%'>
      <Typography variant={'h4'}>{title}</Typography>
      {isViewAll && (
        <Stack spacing={0.5} direction={'row'} alignItems={'center'} onClick={onClick} sx={{ cursor: 'pointer' }}>
          <Typography>View all</Typography>
          <IconButton>
            <RightArrowIcon />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
};

export default MenuHeader;
