import * as React from 'react';

function NotificationIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 5.367v2.775M17.158 12.642a1.85 1.85 0 01-1 2.708 19.45 19.45 0 01-12.275 0c-1.2-.4-1.658-1.617-1-2.708l1.059-1.767c.291-.483.525-1.342.525-1.9v-1.75a5.55 5.55 0 015.55-5.558c3.05 0 5.55 2.5 5.55 5.55v1.75c0 .15.016.316.041.491"
        stroke="#000"
        strokeWidth={1.25}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M12.775 15.683A2.785 2.785 0 0110 18.458a2.78 2.78 0 01-1.958-.816c-.5-.5-.817-1.2-.817-1.959"
        stroke="#000"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default NotificationIcon;
