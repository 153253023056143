import React from 'react';
import { Box } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import TopRequestComponent from './TopRequestComponent';

const TopRequestPage = () => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TopNavigation title={'Top Requests'} />
      <TopNavigationSpace />
      <TopRequestComponent top={'50px'} />
    </Box>
  );
};

export default TopRequestPage;
