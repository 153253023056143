import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box, InputAdornment } from '@mui/material';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import Button from '@mui/material/Button';
import useGetWallet from '../../hooks/useGetWallet';
import { useForm } from 'react-hook-form';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import PaymentCheckout from '../../components/PaymentCheckout/PaymentCheckout';
import { useMutation } from '@tanstack/react-query';
import api from '../../config/api';
import WalletBalance from '../../components/WalletBalance/WalletBalance';
import Loading from '../../components/Loading/Loading';
import ROUTES from '../../routes';
import SuccessModal from '../../components/SuccessModal/SuccessModal';
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const getPaymentIntent = async (amount) => {
  const { data } = await api.post('/api/user/payment-intent', {
    amount: amount,
  });
  return data;
};

const moneyArray = ['50', '100', '200', '500', '1000'];

const addMoney = async (body) => {
  const { data } = await api.post('/api/user/payment', body);
  return data;
};

const AddMoneyForm = () => {
  const [amount, setAmount] = React.useState(0);
  const [transactionDetails, setTransactionDetails] = React.useState(null);
  const { data: wallet, isLoading } = useGetWallet();
  const { control, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const { mutate: addMoneyMutate } = useMutation({
    mutationFn: addMoney,
    onSuccess: () => {
      setOpenModal(true);
    },
  });

  const addMoneyToWallet = async (value) => {
    setAmount(value.amount);
    paymentIntent(value.amount);
  };
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState('');

  const { mutate: paymentIntent, isPending } = useMutation({
    mutationFn: (amount) => getPaymentIntent(amount),
    onSuccess: (data) => {
      setTransactionDetails({
        txnID: data?.data?.id,
        paymentMethod: 'card',
        currency: 'usd',
        status: 'succeeded',
        amount,
      });
      setClientSecret(data?.data?.client_secret);
      setOpenPaymentModal(true);
    },
  });
  const handleClose = () => {
    setOpenPaymentModal(false);
  };
  const [openModal, setOpenModal] = useState(false);
  if (isLoading || isPending) return <Loading />;
  return (
    <>
      <form onSubmit={handleSubmit(addMoneyToWallet)}>
        <Stack spacing={2} mt={2}>
          <WalletBalance wallet={wallet} />
          <Stack spacing={1.5}>
            <CustomNumberField
              name='amount'
              label='Enter Amount to Add'
              control={control}
              startAdornment={
                <InputAdornment position='start'>
                  <AttachMoneyIcon />
                </InputAdornment>
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {moneyArray.map((money) => (
                <Button
                  key={money}
                  variant='outlined'
                  size='small'
                  sx={{ fontSize: '10px', fontWeight: 600 }}
                  onClick={() => setValue('amount', money)}
                >
                  ${money}
                </Button>
              ))}
            </Box>
          </Stack>
          <Button variant='contained' type='submit' size='large' rounded fullWidth>
            Next
          </Button>
        </Stack>
      </form>
      <CustomDialog open={openPaymentModal} handleClose={handleClose}>
        <PaymentCheckout
          clientSecret={clientSecret}
          amount={amount}
          callback={() => addMoneyMutate(transactionDetails)}
          redirectUrl={ROUTES.WALLET}
          handleClose={handleClose}
        />
      </CustomDialog>
      <SuccessModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title={'Success!'}
        message={'Payment Successful'}
        btnText={'OK'}
        onClick={() => {
          setOpenModal(false);
          navigate(ROUTES.WALLET);
        }}
      />
    </>
  );
};

export default AddMoneyForm;
