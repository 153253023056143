import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import useLiveStreamState from '../../hooks/useLiveStreamState';
import { useNavigate, useParams } from 'react-router-dom';
import useGetTask from '../../hooks/useGetTask';
import EyeIcon from '../../assets/icons/EyeIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../../components/Loading/Loading';
import ROUTES from '../../routes';
import { socket } from '../../config/socket';
import useProfile from '../../hooks/useProfile';
import PlayerSkin from '../../components/PlayerSkin/PlayerSkin';
import PlayIcon from '../../assets/icons/PlayIcon';
import useGetViewers from '../../hooks/useGetViewers';
import LiveStreamStoppedPage from '../LiveStreamStoppedPage/LiveStreamStoppedPage';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import { LIVE_STREAM_STATUS } from '../../utils/constant';
import RatingModal from '../LiveStreamPage/RatingModal';

const LiveStreamPlayerPage = () => {
  const [playing, setPlaying] = useState(false);
  const [isShowBackground, setIsShowBackground] = useState(true);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const { taskId } = useParams();
  const navigate = useNavigate();

  const { data: task, isLoading } = useGetTask(taskId);
  const streamId = task?.data?.streamDetails?.liveStreamId;
  const playBackUrl = task?.data?.streamDetails?.HLSPlaybackURL;
  const { data: profile } = useProfile();

  const { data: liveStreamStatus } = useLiveStreamState(streamId);
  const isLiveStreamStarted = liveStreamStatus === LIVE_STREAM_STATUS.LIVE;
  const { data: viewers } = useGetViewers(streamId, isLiveStreamStarted);
  useEffect(() => {
    socket.connect();
    socket.emit('join_room', { roomId: streamId, user: profile?.data?._id });
    socket.emit('send_message', {
      userDetails: {
        name: profile?.data?.name,
        avatarUrl: profile?.data?.profile?.avatarUrl,
      },
      room: streamId,
      message: 'Hello',
    });
    socket.on('receive_message', (data) => {
      console.log(data);
    });

    return () => {
      socket.disconnect();
    };
  }, [profile?.data?._id, profile?.data?.name, profile?.data?.profile?.avatarUrl, streamId]);
  useEffect(() => {
    if (liveStreamStatus === LIVE_STREAM_STATUS.STOPPED && task?.data?.user?._id === profile?.data?._id) {
      setIsRatingModalOpen(true);
    }
  }, [liveStreamStatus, profile?.data?._id, task?.data?.user?._id]);
  const handlePlay = () => {
    console.log('handlePlay');
    setPlaying(!playing);
    setIsShowBackground(!isShowBackground);
  };
  const user = task?.data?.type === 'REQUEST' ? task?.data?.accepted?.user : task?.data?.user;
  console.log(user);
  const userName = user?.name;
  const avatarUrl = user?.profile?.avatarUrl;
  const streamerId = user?._id;
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          mx: { sm: '-24px', xs: '-16px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100dvh',
        }}
      >
        {isLiveStreamStarted ? (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9,
                cursor: isShowBackground ? 'pointer' : 'default',
              }}
              className={isShowBackground ? 'fadeIn' : 'fadeOut'}
              onClick={handlePlay}
            >
              <Button variant='contained' sx={{ borderRadius: 50, p: 3 }}>
                <PlayIcon />
              </Button>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                width: '100%',
                px: 2,
                zIndex: 10,
              }}
            >
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <IconButton onClick={() => navigate(ROUTES.WELCOME)}>
                  <CancelIcon />
                </IconButton>
                <Box display={'flex'} alignItems={'center'} gap={1.5}>
                  <Box display={'flex'} alignItems={'center'} gap={0.5}>
                    <CustomAvatar name={userName} avatarUrl={avatarUrl} />
                    <Typography variant={'h6_dark'}>{task.data.user.name}</Typography>
                  </Box>
                  <Button
                    variant='contained'
                    sx={{ borderRadius: 50, fontSize: 9, p: '4px 12px' }}
                    onClick={() => navigate(ROUTES.REQUEST_VIDEO_TO_STREAMER.replace(':streamerId', streamerId))}
                  >
                    Request
                  </Button>
                </Box>
                <Stack
                  spacing={0.5}
                  direction={'row'}
                  sx={{
                    padding: '4px 8px 4px 8px',
                    backgroundColor: '#5F5D5D80',
                    color: '#fff',
                    borderRadius: '4px',
                  }}
                >
                  <EyeIcon />
                  <Typography variant={'caption_bold'}>{viewers}</Typography>
                </Stack>
              </Box>
              <Stack spacing={1} pt={2}>
                <Typography variant={'h5_semibold'}>{task.data.title}</Typography>
                <Typography variant={'h6'}>{task.data.description}</Typography>
              </Stack>
            </Box>
            <PlayerSkin taskId={taskId} streamId={streamId} task={task} isViewer={true} />
            <ReactPlayer url={playBackUrl} playing={playing} volume={1} muted={false} width='100%' height='100dvh' />
          </>
        ) : (
          <LiveStreamStoppedPage />
        )}
      </Box>
      <RatingModal
        open={isRatingModalOpen}
        title={'How was your Live Stream?'}
        taskId={taskId}
        handleClose={() => setIsRatingModalOpen(false)}
      />
    </>
  );
};

export default LiveStreamPlayerPage;
