import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import EyeIcon from '../../../../assets/icons/EyeIcon';
import PlayIcon from '../../../../assets/icons/PlayIcon';
import { useNavigate } from 'react-router-dom/dist';
import useInfiniteScrollUtils from '../../../../utils/useInfiniteScrollUtils';
import useGetViewers from '../../../../hooks/useGetViewers';
import ROUTES from '../../../../routes';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';
import useGetThumbnail from '../../../../hooks/useGetThumbnail';

const LiveStreamCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const navigate = useNavigate();

  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    observer,
  });
  const streamId = data.streamDetails.liveStreamId;
  console.log(streamId);
  const { data: thumbnail } = useGetThumbnail(streamId);
  const { data: viewers } = useGetViewers(streamId);

  const handleLiveStream = () => {
    const taskId = data?._id;
    navigate(ROUTES.LIVE_PLAY.replace(':taskId', taskId));
  };
  const username = data.type === 'REQUEST' ? data?.accepted?.user?.name : data?.user?.name;
  const avatarUrl = data.type === 'REQUEST' ? data?.accepted?.user?.profile?.avatarUrl : data?.user?.profile?.avatarUrl;
  return (
    <Card sx={{ width: '100%', borderRadius: '20px', mb: 1 }} ref={index === totalCount - 1 ? lastElementRef : null}>
      <Box
        sx={{
          height: 200,
          backgroundImage: `url(${thumbnail?.data?.live_stream?.thumbnail_url})`,
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={handleLiveStream}
      >
        <Stack spacing={0.5} direction='row' sx={{ position: 'absolute', top: 16, right: 16 }}>
          <Stack
            spacing={0.5}
            direction='row'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#5F5D5D80',
              color: '#fff',
              borderRadius: '4px',
            }}
          >
            <EyeIcon />
            <Typography variant='caption_bold'>{viewers}</Typography>
          </Stack>
          <Stack
            spacing={0.5}
            direction='row'
            alignItems='center'
            sx={{
              padding: '4px 8px 4px 8px',
              backgroundColor: '#fff',
              color: '#000',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                width: 7,
                height: 7,
                backgroundColor: '#F11B45',
                borderRadius: '50%',
              }}
            ></Box>
            <Typography variant='caption_bold'>Live</Typography>
          </Stack>
        </Stack>
        <Typography variant='h6_bold' sx={{ position: 'absolute', left: 20, bottom: 20, color: '#fff' }}>
          {data.title}
        </Typography>
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack spacing={1} direction='row' alignItems='center'>
          <CustomAvatar avatarUrl={avatarUrl} name={username} />
          {/* <Avatar>C</Avatar> */}
          <Box>
            <Typography variant='h5_bold'>{username}</Typography>
            {/*<Typography variant='h6' color='#737373'>*/}
            {/*  {data.creator.subTitle}*/}
            {/*</Typography>*/}
          </Box>
        </Stack>
        <IconButton
          color='primary'
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handleLiveStream}
        >
          <PlayIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default LiveStreamCard;
