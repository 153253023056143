import React from 'react';
import { Stack, Typography } from '@mui/material';
import CoinIcon from '../../assets/icons/CoinIcon';
import ClockIcon from '../../assets/icons/ClockIcon';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

const TaskDetails = ({ date, amount, duration, timeZone }) => {
  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(defaultTimeZone);
  const { parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: { ...allTimezones, 'Asia/Kolkata': 'Asia/Calcutta' },
  });

  const localTimeZone = parseTimezone(defaultTimeZone);
  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack spacing={1} justifyContent='center'>
        <Typography variant='h6'>
          Stream Date: <Typography variant='h6_dark'>{date.format('DD MMM, YYYY')}</Typography>
        </Typography>
        <Typography variant='h6'>
          Stream TIme:{' '}
          <Typography variant='h6_dark'>
            {date.format('HH:mm')}{' '}
            {localTimeZone.label.substring(localTimeZone.label.indexOf('(') + 1, localTimeZone.label.indexOf(')'))}
          </Typography>
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <CoinIcon />
          <Typography variant='h6'>${amount}</Typography>
        </Stack>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <ClockIcon />
          <Typography variant='h6'>{duration} min</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TaskDetails;
