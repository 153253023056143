import * as React from 'react';

function WalletIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.667 10c0-2.267 1.366-3.85 3.491-4.117.217-.033.442-.05.675-.05h8.334c.216 0 .425.009.625.042 2.15.25 3.541 1.842 3.541 4.125v4.167c0 2.5-1.666 4.166-4.166 4.166H5.833c-2.5 0-4.166-1.666-4.166-4.166v-.825"
        stroke="#000"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.792 5.875a3.812 3.812 0 00-.625-.042H5.833c-.233 0-.458.017-.675.05.117-.233.284-.45.484-.65L8.35 2.517a2.937 2.937 0 014.133 0l1.459 1.475c.533.525.816 1.191.85 1.883zM18.333 10.417h-2.5c-.916 0-1.666.75-1.666 1.666 0 .917.75 1.667 1.666 1.667h2.5"
        stroke="#000"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WalletIcon;
