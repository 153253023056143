import * as React from 'react';

function SendIcon(props) {
  return (
    <svg width={15} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.18 1.317c-.355-.364-.88-.5-1.37-.357L1.39 4.282A1.362 1.362 0 00.406 5.36c-.1.533.252 1.21.712 1.492l3.57 2.195a.926.926 0 001.143-.137l4.09-4.115a.52.52 0 01.752 0 .541.541 0 010 .757L6.577 9.668a.94.94 0 00-.137 1.149l2.182 3.607c.256.428.696.671 1.179.671.056 0 .12 0 .177-.007.554-.071.994-.45 1.157-.985l3.386-11.407a1.394 1.394 0 00-.34-1.379'
        fill='#FDCC0A'
      />
    </svg>
  );
}

export default SendIcon;
