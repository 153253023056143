import api from '../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';

const getChat = async (taskId) => {
  const { data } = await api.get(`/api/user/task/${taskId}/chat`);
  return data;
};
const getChats = async ({ queryKey, pageParam = 2 }) => {
  console.log('queryKey', queryKey);
  const [_key, taskId] = queryKey;
  const { data } = await api.get(`/api/user/task/${taskId}/chat?page=${pageParam}&size=5`);
  return data;
};

const useGetChats = (taskId) => {
  return useInfiniteQuery({
    queryKey: ['chats', taskId],
    queryFn: getChats,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 5;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
};

export default useGetChats;
