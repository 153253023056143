import * as React from 'react';

function CalenderIcon(props) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.917 5.933v-2.6a1.26 1.26 0 00-1.25-1.25 1.26 1.26 0 00-1.25 1.25v2.5H14.584v-2.5a1.26 1.26 0 00-1.25-1.25 1.26 1.26 0 00-1.25 1.25v2.6c-4.5.417-6.683 3.1-7.017 7.084a.837.837 0 00.834.883h28.2c.483 0 .883-.417.833-.883-.333-3.984-2.517-6.667-7.017-7.084z"
        fill="#FDCC0A"
      />
      <path
        opacity={0.4}
        d="M33.333 16.4c.917 0 1.667.75 1.667 1.667v10.266c0 5-2.5 8.334-8.333 8.334H13.333C7.5 36.667 5 33.333 5 28.333V18.067c0-.917.75-1.667 1.667-1.667h26.666z"
        fill="#FDCC0A"
      />
      <path
        d="M14.167 25a1.66 1.66 0 01-.634-.133c-.2-.084-.383-.2-.55-.35a1.922 1.922 0 01-.35-.55 1.66 1.66 0 01-.133-.634c0-.216.05-.433.133-.633.084-.2.2-.383.35-.55.167-.15.35-.267.55-.35.4-.167.867-.167 1.267 0 .2.083.383.2.55.35.15.167.267.35.35.55.083.2.133.417.133.633 0 .217-.05.434-.133.634s-.2.383-.35.55c-.167.15-.35.266-.55.35a1.66 1.66 0 01-.633.133zM20 25a1.66 1.66 0 01-.633-.133c-.2-.084-.383-.2-.55-.35-.3-.317-.483-.75-.483-1.184 0-.433.183-.866.483-1.183.167-.15.35-.267.55-.35.4-.183.867-.183 1.267 0 .2.083.383.2.55.35.3.317.483.75.483 1.183 0 .434-.183.867-.483 1.184-.167.15-.35.266-.55.35a1.66 1.66 0 01-.633.133zM14.167 30.833a1.66 1.66 0 01-.634-.133c-.2-.083-.383-.2-.55-.35-.3-.317-.483-.75-.483-1.183 0-.434.183-.867.483-1.184.167-.15.35-.266.55-.35.4-.166.867-.166 1.267 0 .2.084.383.2.55.35.3.317.483.75.483 1.184 0 .433-.183.866-.483 1.183-.167.15-.35.267-.55.35a1.66 1.66 0 01-.633.133z"
        fill="#292D32"
      />
    </svg>
  );
}

export default CalenderIcon;