import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';

const MessageBox = ({ message }) => {
  const { unReadMsgCount, user, updatedAt, taskCount } = message;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.8,
        borderRadius: 1,
        p: '12px 20px',
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        cursor: 'pointer',
      }}
      onClick={() => navigate(ROUTES.USER_MESSAGES.replace(':userId', user._id))}
    >
      <CustomAvatar avatarUrl={user.profile[0].avatarUrl} name={user.name} />
      <Stack spacing={1} sx={{ flex: 1 }}>
        <Typography variant='h5_semibold'>{user.name}</Typography>
        <Typography color='text.light' variant='h6_semibold'>
          {unReadMsgCount === 0 ? `${taskCount} tasks requested ` : `${unReadMsgCount} New Messages`}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant='caption'>{dayjs(updatedAt).fromNow()}</Typography>
      </Stack>
    </Box>
  );
};

export default MessageBox;
