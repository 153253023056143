import React from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

function GettingStartedCard({ image, title, subtitle }) {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' sx={{ width: '100%' }}>
      {image}
      <Stack justifyContent='center' alignItems='center' spacing={2} sx={{ textAlign: 'center', pb: 4 }}>
        <Typography variant={'h2'}>{title}</Typography>
        <Typography variant={'h4'} mt={2}>
          {subtitle}
        </Typography>
      </Stack>
    </Box>
  );
}

export default GettingStartedCard;
