import React from 'react';
import { Controller } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import AsyncSelect from 'react-select/async';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
    padding: '6.5px 8px',
    border: state.isFocused ? '2px solid #fdcc0a' : '1px solid rgba(0,0,0,0.23)',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid rgba(0,0,0,0.87)',
    },
    '&:focus-within': {
      border: '2px solid #fdcc0a',
    },
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: 600,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#2684FF' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#deebff',
      color: 'black',
    },
  }),
};

const CustomAsyncReactSelect = ({ control, options, name, label, rules, defaultValue }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value = defaultValue }, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Typography variant='h6_semibold' sx={{ color: '#1A1B1F66' }}>
            {label}
          </Typography>
          <AsyncSelect
            cacheOptions
            // defaultOptions={value}
            onChange={onChange}
            loadOptions={options}
            defaultValue={defaultValue}
            styles={customStyles}
            // value={value}
          />
          {error && (
            <Typography variant='caption' color='error' sx={{ m: '4px 14px !important' }}>
              {error.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

CustomAsyncReactSelect.propTypes = {};

export default CustomAsyncReactSelect;
