import * as React from 'react';

const InfoIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      stroke='#111'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.25}
      d='M8 5.334v3.333M2.667 4A6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8a6.67 6.67 0 0 0-9.354-6.1'
    />
    <path stroke='#111' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.667} d='M7.996 10.667h.006' />
  </svg>
);
export default InfoIcon;
