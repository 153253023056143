import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import NoStreamRequest from '../../../../assets/images/NoStreamRequest';
import ROUTES from '../../../../routes';
import { useNavigate } from 'react-router-dom';

const NoStreamRequestCard = () => {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1.5}
      sx={{
        borderRadius: '20px',
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        p: '20px',
        alignItems: 'center',
      }}
    >
      <NoStreamRequest />
      <Typography variant='h5_dark'>No streaming request</Typography>
      <Button variant='contained' rounded size='large' onClick={() => navigate(ROUTES.REQUEST_VIDEO_FORM)}>
        Post a Request
      </Button>
    </Stack>
  );
};

export default NoStreamRequestCard;
