import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, Stack } from '@mui/material';
import './popularVideos.css';
import api from '../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import useInfiniteQueryReducedResult from '../../utils/useInfiniteQueryReducedResult';
import PopularVideoCard from './PopularVideoCard';
import NoStreamerCard from '../TopStreamersPage/NoStreamerCard';

const getPopularVideos = async ({ pageParam }) => {
  const { data } = await api.get(`/api/user/popular-videos?page=${pageParam}&size=10`);
  return data;
};

const PopularVideosPage = () => {
  const {
    data,
    isLoading: isPopularVideosLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['getPopularVideosInfinity'],
    queryFn: getPopularVideos,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const allPopularVideos = useInfiniteQueryReducedResult(data);
  // if (isLoading) return <LoadingSmall />;
  return (
    <Box sx={{ pb: 8, position: 'relative', minHeight: '100dvh' }}>
      <TopNavigation title={'Popular Videos'} />
      <TopNavigationSpace />
      <Box mt={2}>
        {allPopularVideos?.length === 0 && <NoStreamerCard />}
        <Stack gap={2} alignItems='center'>
          {allPopularVideos?.map((popularVideo, index) => (
            <PopularVideoCard
              data={popularVideo}
              fetchNextPage={fetchNextPage}
              isPopularVideosLoading={isPopularVideosLoading}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              totalCount={allPopularVideos?.length}
              index={index}
              key={popularVideo.title}
            />
          ))}
        </Stack>
        {/*<swiper-container*/}
        {/*  direction='vertical'*/}
        {/*  slides-per-view='auto'*/}
        {/*  style={{*/}
        {/*    height: '100%',*/}
        {/*    width: '100%',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {allPopularVideos?.map((popularVideo, index) => (*/}
        {/*    <swiper-slide class='popular-video-swiper-slide' key={popularVideo.title}>*/}
        {/*      <PopularVideoCard*/}
        {/*        data={popularVideo}*/}
        {/*        fetchNextPage={fetchNextPage}*/}
        {/*        isPopularVideosLoading={isPopularVideosLoading}*/}
        {/*        isFetching={isFetching}*/}
        {/*        hasNextPage={hasNextPage}*/}
        {/*        totalCount={allPopularVideos?.length}*/}
        {/*        index={index}*/}
        {/*      />*/}
        {/*    </swiper-slide>*/}
        {/*  ))}*/}
        {/*</swiper-container>*/}
      </Box>
    </Box>
  );
};

export default PopularVideosPage;
