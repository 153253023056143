import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';
import { Button, Stack, Typography } from '@mui/material';
import InfoIconWithBackground from '../../assets/icons/InfoIconWithBackground';

const InfoModal = ({ open = false, handleClose, onClick, title, message, btnText }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <InfoIconWithBackground />
        <Stack spacing={1} alignItems={'center'} textAlign={'center'}>
          <Typography variant='h5_dark'>{title}</Typography>
          <Typography variant='h6' sx={{ width: '100%' }}>
            {message}
          </Typography>
        </Stack>
        <Button variant='contained' sx={{ width: '70%' }} onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default InfoModal;
