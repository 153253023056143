// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mySwiper {
    height: 100px;
}

.swiper-container {
    min-width: 50px;
    height: 150px;
    line-height: 200px;
    text-align: center;
}

.swiper-slide {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}

.swiper-slide-active {
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomCalendar/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".mySwiper {\n    height: 100px;\n}\n\n.swiper-container {\n    min-width: 50px;\n    height: 150px;\n    line-height: 200px;\n    text-align: center;\n}\n\n.swiper-slide {\n    font-size: 14px;\n    font-weight: 600;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    opacity: 0.5;\n}\n\n.swiper-slide-active {\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
