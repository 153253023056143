import React from 'react';
import CustomDialog from '../CustomDialog/CustomDialog';
import { Button, Stack, Typography } from '@mui/material';
import StarIcon from '../../assets/icons/StarIcon';
import VideoPlayIcon from '../../assets/icons/VideoPlayIcon';
import ColorfulWalletIcon from '../../assets/icons/ColorfullWalletIcon';

const WelcomeReward = ({ open = false, handleClose }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <StarIcon />
        <Typography variant='h5_dark'>Hey, Welcome to bunpun!</Typography>
        <Typography variant='h6' textAlign='center'>
          Hope you enjoy your time. You’ve successfully unlocked the following rewards:
        </Typography>
        <Stack spacing={3} direction='row' alignItems='center' textAlign='center'>
          <Stack spacing={1.5} alignItems='center'>
            <VideoPlayIcon width={32} height={32} />
            <Stack>
              <Typography variant='h5_dark'>Free 30 mins!</Typography>
              <Typography variant='caption'>Go live, chat, engage!</Typography>
            </Stack>
          </Stack>
          <Stack spacing={1.5} alignItems='center'>
            <ColorfulWalletIcon width={32} height={32} />
            <Stack>
              <Typography variant='h5_dark'>$5</Typography>
              <Typography variant='caption'>To request a stream!</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button variant='contained' fullWidth sx={{ borderRadius: 50 }} onClick={handleClose}>
          Happy bunpun!
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default WelcomeReward;
