import React, { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useInfiniteScrollUtils from '../../utils/useInfiniteScrollUtils';
import api from '../../config/api';
import LoadingSmall from '../../components/Loading/LoadingSmall';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import VideoDescriptionTag from '../../components/VideoDescriptionTag/VideoDescriptionTag';
import ROUTES from '../../routes';
import { Box } from '@mui/material';

const getRecording = async (streamId) => {
  const { data } = await api.get(`/api/stream/recordings/${streamId}`);
  return data;
};

const PopularVideoCard = ({
  data,
  isPopularVideosLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
  index,
  totalCount,
}) => {
  const observer = useRef();
  const lastElementRef = useInfiniteScrollUtils({
    isPopularVideosLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    observer,
  });
  const streamId = data?.streamDetails?.liveStreamId;
  const taskId = data?._id;
  const { data: recording, isLoading } = useQuery({
    queryKey: ['recording', streamId],
    queryFn: () => getRecording(streamId),
  });
  const navigate = useNavigate();
  const totalDuration = recording?.data?.asset?.reduce((acc, curr) => acc + curr.asset.duration, 0);
  if (isLoading) return <LoadingSmall />;
  return (
    <Box ref={index === totalCount - 1 ? lastElementRef : null} sx={{ cursor: 'pointer' }}>
      <BackgroundImage
        img={recording?.data?.asset?.[0]?.asset?.thumbnail_url}
        width={350}
        height={200}
        borderRadius={10}
        border={(theme) => `2px solid ${theme.palette.primary.main}`}
        display={'flex'}
        onClick={() => navigate(ROUTES.RECORDING_PLAY + '?streamId=' + streamId + '&taskId=' + taskId)}
      >
        <VideoDescriptionTag duration={totalDuration} viewers={data?.viewers} />
      </BackgroundImage>
    </Box>
  );
};

export default PopularVideoCard;
