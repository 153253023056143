import * as React from 'react';

function NoStreamRequest(props) {
  return (
    <svg width='160' height='160' viewBox='0 0 160 160' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M137.08 141.253H24.0851C23.9584 141.253 23.856 141.151 23.856 141.024C23.856 140.897 23.9584 140.795 24.0851 140.795H137.08C137.206 140.795 137.309 140.897 137.309 141.024C137.309 141.151 137.206 141.253 137.08 141.253Z'
        fill='white'
      />
      <path
        d='M119.534 49.2348C119.538 48.8086 118.701 47.1849 120.533 46.2332C121.449 45.7564 124.254 45.3507 125.738 45.9715C127.934 46.8931 130.948 52.7523 130.021 54.8854C129.309 56.5219 124.393 63.0979 123.519 64.358L114.54 56.8118L116.398 53.7539L115.535 46.5948C115.495 45.7091 116.176 44.9571 117.061 44.9091C117.826 44.8681 118.514 45.3692 118.709 46.1091L119.534 49.2348Z'
        fill='white'
      />
      <path
        d='M123.519 64.5869C123.465 64.5869 123.413 64.5683 123.371 64.5331L114.392 56.9869C114.306 56.9139 114.285 56.7891 114.344 56.6925L116.161 53.7024L115.307 46.6221C115.26 45.5981 116.042 44.7347 117.049 44.6803C117.919 44.6342 118.708 45.2096 118.93 46.0506L119.247 47.2512C119.411 46.8128 119.757 46.3776 120.427 46.0294C121.373 45.5366 124.237 45.0944 125.827 45.76C127.269 46.3654 128.878 48.8109 129.722 50.9139C130.13 51.9302 130.739 53.8093 130.23 54.9766C129.671 56.2637 126.641 60.4358 124.831 62.9286C124.314 63.6416 123.905 64.2042 123.707 64.489C123.67 64.5421 123.613 64.5773 123.549 64.5856C123.539 64.5862 123.529 64.5869 123.519 64.5869ZM114.837 56.7622L123.475 64.0218C123.699 63.7062 124.045 63.2307 124.46 62.6586C126.141 60.3437 129.271 56.0346 129.811 54.793C130.132 54.0518 129.946 52.7002 129.297 51.0835C128.37 48.7725 126.802 46.665 125.649 46.1811C124.226 45.5846 121.473 46 120.639 46.4346C119.299 47.1302 119.547 48.1997 119.696 48.839C119.734 49.0016 119.764 49.1302 119.763 49.2352C119.762 49.3498 119.675 49.4464 119.562 49.4605C119.448 49.4739 119.341 49.4029 119.312 49.2915L118.486 46.1664C118.32 45.5347 117.729 45.1008 117.073 45.1366C116.316 45.177 115.729 45.8259 115.763 46.583L116.624 53.7248C116.63 53.776 116.619 53.8272 116.593 53.8714L114.837 56.7622Z'
        fill='#010101'
      />
      <path
        d='M117.757 42.3626L122.789 56.7536C123.233 58.0214 124.427 58.815 125.702 58.8029C126.019 58.7997 126.342 58.7446 126.658 58.6378C128.421 58.0413 129.244 56.0195 128.4 54.3568L126.165 49.9613C126.165 49.9613 126.165 49.9613 126.162 49.9581L125.818 49.2803L125.538 48.7293L121.654 39.6982L118.303 42.2474L117.757 42.3626Z'
        fill='#414141'
      />
      <path
        d='M123.871 44.852L121.655 39.6987L118.305 42.2466C118.303 42.7291 117.755 42.4591 117.758 42.4635C117.849 42.5909 120.842 41.3378 123.871 44.852Z'
        fill='#414141'
      />
      <path
        d='M133.789 42.0451C133.789 42.0451 127.781 40.4988 125.028 40.5648C121.799 40.6384 119 41.2144 115.582 41.9971C115.345 42.0496 115.101 42.104 114.859 42.1609C113.496 42.4764 112.13 42.2364 110.998 41.583C109.866 40.9296 108.976 39.8646 108.566 38.5244C107.787 35.9804 109.005 33.2502 111.421 32.1289C111.85 31.9299 112.293 31.7232 112.744 31.5081C115.147 30.3702 117.761 29.064 119.312 28.086C122.756 25.9107 127.135 20.4502 127.135 20.4502L133.789 42.0451Z'
        fill='#414141'
      />
      <path
        d='M115.582 41.9968C115.345 42.0493 115.101 42.1037 114.859 42.1606C113.496 42.4761 112.13 42.2361 110.998 41.5827C109.866 40.9293 108.976 39.8643 108.566 38.5241C107.787 35.9801 109.005 33.2499 111.421 32.1286C111.85 31.9296 112.293 31.7229 112.744 31.5078C112.269 32.9165 111.158 37.6365 115.582 41.9968Z'
        fill='#414141'
      />
      <path
        d='M135.86 41.744C140.099 40.5989 142.157 34.565 140.456 28.2669C138.754 21.9687 133.939 17.7913 129.7 18.9364C125.46 20.0815 123.403 26.1154 125.104 32.4135C126.806 38.7117 131.621 42.889 135.86 41.744Z'
        fill='#414141'
      />
      <path
        d='M125.527 28.3653C125.537 29.0444 125.602 29.7458 125.716 30.4562C125.821 31.1052 125.97 31.765 126.163 32.4287C126.45 33.4015 126.816 34.3167 127.247 35.1506C127.577 35.8021 127.944 36.405 128.343 36.9567C130.301 39.6754 132.959 41.1058 135.316 40.3814C138.763 39.3177 140.262 34.0396 138.658 28.5868C137.054 23.134 132.954 19.5749 129.507 20.6386C126.938 21.4252 125.45 24.5625 125.527 28.3653Z'
        fill='#010101'
      />
      <path
        d='M125.205 29.4965C125.306 30.1755 125.466 30.877 125.676 31.5874C125.867 32.2363 126.106 32.8962 126.388 33.5599C126.806 34.5327 127.295 35.4479 127.837 36.2818L130.122 35.3026C131.532 34.7055 132.376 33.2347 132.248 31.7013C132.235 31.5663 132.218 31.4216 132.191 31.2866C131.774 29.1791 129.661 27.9311 127.694 28.621L125.205 29.4965Z'
        fill='#414141'
      />
      <path
        d='M124.582 45.6948C124.582 45.6948 122.481 47.2257 122.067 47.8017C121.653 48.3777 119.889 51.0222 122.124 51.3217C122.124 51.3217 121.685 53.1662 123.263 52.8871C123.263 52.8871 123.171 54.0526 124.48 54.0916C124.48 54.0916 124.214 54.9006 124.612 55.3684C125.01 55.8363 125.699 55.6212 126.003 55.4183C126.446 55.122 127.046 53.8555 127.046 53.8555L129.935 54.4174'
        fill='white'
      />
      <path
        d='M125.251 55.8756C124.958 55.8756 124.662 55.7802 124.437 55.5159C124.118 55.1415 124.142 54.6135 124.197 54.2948C123.851 54.2398 123.574 54.0983 123.372 53.8724C123.156 53.6318 123.076 53.347 123.047 53.1422C122.667 53.1626 122.371 53.059 122.159 52.8343C121.804 52.4574 121.819 51.8436 121.861 51.5063C121.374 51.395 121.048 51.153 120.89 50.785C120.467 49.8033 121.442 48.2775 121.88 47.667C122.312 47.0679 124.36 45.5722 124.447 45.5089C124.549 45.434 124.692 45.457 124.767 45.5594C124.841 45.6618 124.819 45.8052 124.716 45.8794C124.14 46.2993 122.574 47.4871 122.252 47.9351C121.649 48.7742 121.04 49.9774 121.31 50.6039C121.427 50.8734 121.702 51.0334 122.154 51.0942C122.218 51.1025 122.276 51.1383 122.312 51.1914C122.349 51.2446 122.362 51.3111 122.347 51.3738C122.295 51.5946 122.22 52.2314 122.494 52.5201C122.638 52.6737 122.884 52.7204 123.223 52.6602C123.295 52.6474 123.365 52.6686 123.417 52.7159C123.47 52.7633 123.497 52.833 123.491 52.9034C123.491 52.906 123.468 53.2951 123.716 53.5684C123.882 53.7521 124.142 53.8506 124.486 53.8609C124.558 53.8628 124.625 53.8993 124.667 53.9582C124.709 54.017 124.719 54.0926 124.698 54.161C124.636 54.353 124.53 54.9175 124.786 55.2177C125.088 55.5716 125.65 55.3758 125.875 55.226C126.16 55.0359 126.618 54.2231 126.84 53.7553C126.885 53.6612 126.988 53.6081 127.091 53.6286L129.98 54.1905C130.104 54.2148 130.185 54.3351 130.161 54.4593C130.137 54.5834 130.018 54.6647 129.892 54.6404L127.175 54.1118C126.988 54.4823 126.527 55.3418 126.129 55.6068C125.917 55.7495 125.586 55.8756 125.251 55.8756Z'
        fill='#010101'
      />
      <path
        d='M122.124 51.3214L123.416 48.8759C123.512 48.6948 123.665 48.5508 123.852 48.4663L126.39 47.3169'
        fill='white'
      />
      <path
        d='M122.124 51.5507C122.088 51.5507 122.051 51.5424 122.017 51.5239C121.905 51.465 121.862 51.3261 121.921 51.2141L123.214 48.7687C123.333 48.544 123.526 48.3623 123.757 48.258L126.296 47.1085C126.411 47.056 126.547 47.1072 126.599 47.2224C126.652 47.3376 126.6 47.4733 126.485 47.5258L123.947 48.6752C123.807 48.7386 123.691 48.848 123.619 48.9831L122.326 51.4285C122.285 51.5059 122.206 51.5507 122.124 51.5507Z'
        fill='#010101'
      />
      <path
        d='M123.263 52.8864L124.893 50.4051C125.04 50.1805 125.278 50.032 125.544 49.9968L128.039 49.6685'
        fill='white'
      />
      <path
        d='M123.262 53.1154C123.219 53.1154 123.175 53.1032 123.137 53.0776C123.031 53.0085 123.001 52.8664 123.071 52.7602L124.701 50.2789C124.885 49.9985 125.181 49.813 125.513 49.7694L128.008 49.4411C128.134 49.4238 128.249 49.5128 128.265 49.6382C128.281 49.7637 128.193 49.8789 128.068 49.8955L125.573 50.2238C125.373 50.2501 125.195 50.3621 125.083 50.531L123.453 53.0123C123.41 53.0795 123.337 53.1154 123.262 53.1154Z'
        fill='#010101'
      />
      <path
        d='M124.479 54.0909L125.604 52.3238C125.896 51.8649 126.442 51.6358 126.974 51.7478L128.74 52.1209'
        fill='white'
      />
      <path
        d='M124.479 54.32C124.437 54.32 124.395 54.3085 124.356 54.2842C124.25 54.2163 124.218 54.0749 124.286 53.968L125.411 52.201C125.752 51.6647 126.397 51.3927 127.021 51.5239L128.787 51.897C128.911 51.9232 128.99 52.0448 128.964 52.1683C128.938 52.2925 128.817 52.3706 128.692 52.345L126.926 51.9719C126.49 51.8791 126.036 52.0704 125.797 52.4467L124.673 54.2138C124.629 54.2829 124.555 54.32 124.479 54.32Z'
        fill='#010101'
      />
      <path
        d='M85.6736 78.0779L96.848 88.8177C101.384 92.3761 108.036 90.9393 110.699 85.8264L125.283 64.7665L113.069 55.5352L101.553 72.4529L92.2067 61.9902C91.3465 61.1876 89.5923 60.1784 88.4659 59.8404L82.99 59.0923L81.5891 67.5428C80.6803 71.74 82.3859 75.3144 85.6736 78.0779Z'
        fill='#FDCC0A'
      />
      <path
        d='M110.699 86.0557C110.654 86.0557 110.609 86.0422 110.569 86.0147C110.465 85.9424 110.439 85.7997 110.512 85.696L124.97 64.8173L113.124 55.8637L101.744 72.5824C101.704 72.6406 101.639 72.6771 101.569 72.6822C101.505 72.6874 101.43 72.6592 101.383 72.6061L92.0366 62.1433C91.9528 62.0493 91.9605 61.904 92.0545 61.8201C92.1493 61.7357 92.2933 61.744 92.3784 61.8381L101.53 72.0826L112.881 55.4067C112.916 55.3549 112.971 55.3197 113.033 55.3094C113.096 55.2985 113.159 55.3152 113.208 55.3529L125.422 64.5843C125.52 64.6585 125.542 64.7968 125.472 64.8973L110.888 85.9571C110.843 86.0211 110.771 86.0557 110.699 86.0557Z'
        fill='#010101'
      />
      <path
        d='M56.3379 65.6742C56.3379 62.4364 58.7385 59.7017 61.9494 59.2806C66.1932 58.7231 69.0496 58.0473 71.5283 57.9449C75.8988 57.7631 88.8998 60.0441 88.8998 60.0441L90.3475 90.6566H56.3379V65.6742Z'
        fill='#FDCC0A'
      />
      <path
        d='M90.2956 90.8854C90.174 90.8854 90.0722 90.7894 90.0671 90.6665L89.0981 68.9225C89.0924 68.7964 89.1903 68.6889 89.317 68.6838C89.4309 68.6787 89.55 68.7759 89.5557 68.9027L90.5247 90.6467C90.5305 90.7727 90.4325 90.8803 90.3058 90.8854C90.3026 90.8854 90.2988 90.8854 90.2956 90.8854Z'
        fill='#010101'
      />
      <path
        d='M69.0498 60.8949L66.7688 58.5729L67.9938 46.6587L77.7525 48.692L78.1275 58.5185L77.3794 59.6238C75.7627 62.7771 71.533 63.4222 69.0498 60.8949Z'
        fill='white'
      />
      <path
        d='M72.7335 62.6806C71.2909 62.6806 69.9226 62.1104 68.8864 61.0556L66.6055 58.7337C66.5575 58.6851 66.5344 58.6179 66.5408 58.55L67.7658 46.6364C67.7722 46.5718 67.8061 46.5136 67.8586 46.4752C67.9111 46.4368 67.977 46.4227 68.0404 46.4361L77.7991 48.4694C77.9021 48.4905 77.977 48.5795 77.9808 48.6851L78.3565 58.5116C78.3584 58.5603 78.345 58.6083 78.3175 58.6486L77.5687 59.7539C76.7783 61.2988 75.3172 62.3516 73.5744 62.6172C73.2922 62.6595 73.0112 62.6806 72.7335 62.6806ZM67.0074 58.4892L69.2128 60.7344C70.3444 61.8857 71.9085 62.4073 73.5047 62.1635C75.1008 61.9203 76.4384 60.9564 77.1751 59.5196L77.8957 58.4528L77.5296 48.8796L68.1953 46.9347L67.0074 58.4892Z'
        fill='#010101'
      />
      <path
        d='M68.0007 47.7119C66.4423 42.3699 68.3315 36.8179 68.3315 36.8179L80.704 34.3359C80.9043 34.5971 86.9427 38.9881 85.7895 47.4988C84.8442 54.4735 78.8301 56.798 71.5341 52.8441'
        fill='white'
      />
      <path
        d='M78.1804 54.9985C76.1196 54.9985 73.8169 54.3418 71.4239 53.0452C71.3126 52.985 71.2716 52.8462 71.3318 52.7348C71.3919 52.6241 71.5308 52.5825 71.6422 52.6426C75.4438 54.7028 78.9868 55.1015 81.6211 53.7646C83.79 52.6638 85.1526 50.4865 85.5615 47.4682C86.2297 42.5338 84.5132 37.9879 80.727 34.6689C80.6873 34.6343 80.6566 34.6068 80.6303 34.5844L68.5075 37.0158C68.2463 37.8625 66.8275 42.875 68.2195 47.6474C68.2547 47.769 68.1849 47.8958 68.0639 47.9316C67.9411 47.9674 67.8156 47.897 67.7798 47.7761C66.2188 42.4263 68.0947 36.8001 68.1132 36.7444C68.1395 36.667 68.2054 36.6094 68.2847 36.5934L80.6572 34.1114C80.743 34.0942 80.8307 34.1274 80.8838 34.1966C80.8998 34.2132 80.9523 34.258 81.0278 34.3246C84.9337 37.7486 86.7046 42.4378 86.0147 47.5297C85.5846 50.7034 84.1369 53.001 81.8278 54.1729C80.7411 54.7246 79.5116 54.9985 78.1804 54.9985Z'
        fill='#010101'
      />
      <path
        d='M76.9881 31.0909C76.9881 31.0909 74.0467 28.5924 71.9731 29.2996C69.8995 30.0068 70.7206 33.6304 70.7206 33.6304C70.7206 33.6304 68.0557 32.2071 65.5443 34.9508C63.0329 37.6944 66.64 43.8948 66.64 43.8948L70.2713 42.97C70.2713 42.97 70.2028 43.5805 71.294 43.0692C72.3852 42.5572 72.768 39.4154 73.968 39.0532C73.968 39.0532 82.0985 44.4503 88.0589 38.8906C94.0185 33.3309 88.4332 28.4356 81.9808 27.793C75.5283 27.1504 76.9881 31.0909 76.9881 31.0909Z'
        fill='#414141'
      />
      <path d='M78.4045 43.588C77.7946 43.3204 77.0534 43.4785 76.5242 43.9892L78.4045 43.588Z' fill='white' />
      <path
        d='M76.5241 44.2188C76.4639 44.2188 76.4044 44.1951 76.3596 44.149C76.2719 44.0582 76.2738 43.9129 76.3647 43.8252C76.9625 43.2479 77.7996 43.0726 78.4966 43.3785C78.6124 43.429 78.6649 43.5647 78.6143 43.6806C78.5638 43.797 78.4281 43.8482 78.3122 43.7983C77.7862 43.5673 77.1462 43.7074 76.6834 44.1548C76.6386 44.197 76.581 44.2188 76.5241 44.2188ZM82.4441 45.0828C82.471 45.5961 82.775 45.9993 83.1225 45.9807C83.4674 45.9622 83.726 45.5276 83.6966 45.0118C83.6671 44.4953 83.3631 44.0921 83.0182 44.1106C82.6706 44.1324 82.4146 44.567 82.4441 45.0828ZM77.1705 45.4662C77.1999 45.982 77.5039 46.3833 77.8521 46.3647C78.197 46.3462 78.453 45.9116 78.4262 45.3958C78.3967 44.8799 78.0927 44.4761 77.7446 44.4972C77.3996 44.5158 77.1436 44.9503 77.1705 45.4662Z'
        fill='#010101'
      />
      <path d='M83.6762 43.2046C83.0663 42.9371 82.3252 43.0952 81.7959 43.6059L83.6762 43.2046Z' fill='white' />
      <path
        d='M81.7958 43.8347C81.7356 43.8347 81.6761 43.811 81.6313 43.765C81.5436 43.6741 81.5456 43.5288 81.6364 43.4411C82.2342 42.8638 83.0713 42.6891 83.7683 42.9944C83.8841 43.045 83.9366 43.1806 83.886 43.2965C83.8355 43.4123 83.7004 43.4648 83.584 43.4142C83.0579 43.1832 82.4179 43.3227 81.9552 43.7707C81.911 43.8136 81.8534 43.8347 81.7958 43.8347Z'
        fill='#010101'
      />
      <path
        d='M70.4051 43.0757C70.4051 43.0757 67.9315 39.8418 66.2572 40.1938C64.583 40.5458 64.4351 46.2603 66.263 47.0411C68.0908 47.8226 69.719 47.2747 69.719 47.2747'
        fill='white'
      />
      <path
        d='M68.3398 47.6927C67.726 47.6927 66.9695 47.5929 66.1727 47.2524C64.9996 46.7513 64.5727 44.5945 64.7571 42.7231C64.9113 41.1577 65.4547 40.1286 66.2099 39.9698C68.0051 39.5922 70.4819 42.7999 70.5868 42.9369C70.6636 43.0374 70.6444 43.1814 70.5439 43.2582C70.4428 43.3356 70.2995 43.3158 70.2227 43.2153C69.559 42.3481 67.5654 40.1548 66.3039 40.4185C65.7708 40.5305 65.3427 41.4534 65.2127 42.7686C65.0463 44.4556 65.4271 46.4364 66.3526 46.8313C68.0723 47.5666 69.63 47.063 69.6454 47.0578C69.7676 47.0182 69.895 47.0828 69.9353 47.2025C69.975 47.3222 69.911 47.4521 69.7913 47.4924C69.7516 47.5058 69.1788 47.6927 68.3398 47.6927Z'
        fill='#010101'
      />
      <path
        d='M66.85 44.6453C66.7521 44.6453 66.6612 44.582 66.6312 44.4834C66.5096 44.0879 66.4699 43.6617 66.5153 43.2501C66.5544 42.8988 66.674 42.6633 66.8808 42.5301C67.0555 42.4181 67.2897 42.3951 67.5067 42.4668C67.6654 42.5193 67.8158 42.6178 67.9656 42.7689C68.1537 42.9583 68.3048 43.1913 68.4014 43.4415C68.4468 43.5593 68.388 43.6924 68.2702 43.7378C68.1524 43.7833 68.02 43.7244 67.9739 43.6066C67.8996 43.4146 67.7844 43.2367 67.6398 43.0921C67.5419 42.9935 67.451 42.9314 67.3627 42.902C67.2756 42.8732 67.186 42.8783 67.1278 42.9161C67.0452 42.9692 66.9921 43.0985 66.9697 43.3007C66.9307 43.6501 66.9652 44.013 67.0683 44.349C67.1054 44.47 67.0376 44.598 66.9166 44.6351C66.8942 44.6421 66.8718 44.6453 66.85 44.6453Z'
        fill='#010101'
      />
      <path
        d='M80.7079 46.0522C80.7892 46.794 80.8711 47.5364 80.9524 48.2782C81.0017 48.7255 81.0484 49.1902 80.8724 49.5908C80.6964 49.9914 80.2298 50.3095 79.7114 50.2167'
        fill='white'
      />
      <path
        d='M79.897 50.4619C79.8234 50.4619 79.7486 50.4555 79.6718 50.4421C79.5476 50.4197 79.4644 50.3013 79.4868 50.1765C79.5092 50.0517 79.6276 49.9685 79.7524 49.9915C80.1729 50.0664 80.5345 49.7931 80.6638 49.4987C80.818 49.1467 80.7687 48.6987 80.7252 48.3032L80.4807 46.0773C80.4666 45.9518 80.5575 45.8385 80.683 45.8245C80.8116 45.8117 80.9217 45.9013 80.9358 46.0273L81.1802 48.2533C81.2302 48.7057 81.2865 49.2184 81.083 49.6824C80.9025 50.0939 80.4513 50.4619 79.897 50.4619Z'
        fill='#010101'
      />
      <path
        d='M77.1195 51.6062C77.1105 51.6062 77.1022 51.6055 77.0933 51.6049C76.5864 51.5466 76.0993 51.2964 75.7569 50.9182C75.4145 50.5406 75.2129 50.0311 75.2046 49.521C75.2027 49.3943 75.3032 49.2906 75.4299 49.2881C75.4312 49.2881 75.4325 49.2881 75.4337 49.2881C75.5585 49.2881 75.6609 49.3879 75.6629 49.5134C75.6693 49.914 75.8273 50.3134 76.0968 50.6103C76.3656 50.9073 76.7483 51.1038 77.1457 51.1498C77.2712 51.1646 77.3614 51.2778 77.3473 51.4033C77.3339 51.5198 77.2347 51.6062 77.1195 51.6062Z'
        fill='#010101'
      />
      <path
        d='M128.78 91.6305V135.064C128.78 138.356 126.111 141.024 122.819 141.024H38.3446C35.0525 141.024 32.3862 138.358 32.3862 135.066V91.6274C32.3862 88.3352 35.0525 85.6689 38.3446 85.6689H122.819C126.111 85.6689 128.78 88.3384 128.78 91.6305Z'
        fill='#414141'
      />
      <path
        d='M122.819 141.254H38.3445C34.9327 141.254 32.157 138.478 32.157 135.066V91.6275C32.157 88.2156 34.9327 85.4399 38.3445 85.4399H122.819C126.232 85.4399 129.009 88.2169 129.009 91.63V135.063C129.009 138.477 126.232 141.254 122.819 141.254ZM38.3445 85.8982C35.1855 85.8982 32.6152 88.4684 32.6152 91.6275V135.066C32.6152 138.225 35.1855 140.795 38.3445 140.795H122.819C125.98 140.795 128.551 138.224 128.551 135.063V91.63C128.551 88.4691 125.979 85.8975 122.819 85.8975H38.3445V85.8982Z'
        fill='#010101'
      />
      <path
        d='M80.5818 121.998C87.2259 121.998 92.6119 116.612 92.6119 109.968C92.6119 103.324 87.2259 97.938 80.5818 97.938C73.9378 97.938 68.5518 103.324 68.5518 109.968C68.5518 116.612 73.9378 121.998 80.5818 121.998Z'
        fill='#010101'
      />
      <path
        d='M80.5824 122.227C73.8228 122.227 68.3232 116.728 68.3232 109.968C68.3232 103.209 73.8228 97.709 80.5824 97.709C87.3421 97.709 92.8416 103.209 92.8416 109.968C92.8416 116.728 87.3421 122.227 80.5824 122.227ZM80.5824 98.1672C74.0756 98.1672 68.7815 103.461 68.7815 109.968C68.7815 116.475 74.0756 121.769 80.5824 121.769C87.0893 121.769 92.3834 116.475 92.3834 109.968C92.3834 103.461 87.0893 98.1672 80.5824 98.1672Z'
        fill='#010101'
      />
      <path
        d='M84.0337 111.397L80.4555 114.129C79.2715 115.033 77.564 114.188 77.564 112.699V107.238C77.564 105.749 79.2702 104.904 80.4548 105.808L84.0331 108.537C84.9771 109.256 84.9771 110.677 84.0337 111.397Z'
        fill='white'
      />
      <path
        d='M79.3654 114.729C79.0595 114.729 78.7517 114.658 78.4637 114.516C77.7674 114.172 77.3347 113.475 77.3347 112.698V107.237C77.3347 106.46 77.7674 105.764 78.463 105.42C79.1587 105.075 79.9754 105.154 80.5936 105.625L84.1718 108.354C84.6794 108.741 84.9699 109.329 84.9699 109.967C84.9699 110.605 84.6794 111.193 84.1718 111.58L80.5936 114.311C80.232 114.587 79.8006 114.729 79.3654 114.729ZM79.3699 105.662C79.1331 105.662 78.8938 105.717 78.6666 105.829C78.1194 106.1 77.793 106.626 77.793 107.237V112.697C77.793 113.308 78.12 113.834 78.6666 114.105C79.2144 114.375 79.8307 114.316 80.3158 113.945L83.8941 111.214C84.287 110.914 84.5117 110.459 84.5117 109.965C84.5117 109.471 84.2864 109.016 83.8934 108.716L80.3152 105.987C80.0317 105.772 79.7034 105.662 79.3699 105.662Z'
        fill='#010101'
      />
      <path d='M39.0746 132.18H36.8916V136.156H39.0746V132.18Z' fill='#010101' />
      <path d='M42.6232 132.18H40.4402V136.156H42.6232V132.18Z' fill='#010101' />
      <path
        d='M122.246 134.398H47.3925C47.2657 134.398 47.1633 134.296 47.1633 134.169C47.1633 134.042 47.2657 133.94 47.3925 133.94H122.246C122.372 133.94 122.475 134.042 122.475 134.169C122.475 134.296 122.372 134.398 122.246 134.398Z'
        fill='#010101'
      />
      <path
        d='M92.612 136.157C93.7102 136.157 94.6005 135.267 94.6005 134.169C94.6005 133.07 93.7102 132.18 92.612 132.18C91.5138 132.18 90.6235 133.07 90.6235 134.169C90.6235 135.267 91.5138 136.157 92.612 136.157Z'
        fill='#010101'
      />
      <path
        d='M92.6126 136.386C91.3896 136.386 90.395 135.392 90.395 134.169C90.395 132.946 91.3896 131.951 92.6126 131.951C93.835 131.951 94.8296 132.946 94.8296 134.169C94.8296 135.391 93.835 136.386 92.6126 136.386ZM92.6126 132.409C91.6424 132.409 90.8533 133.199 90.8533 134.169C90.8533 135.139 91.6424 135.928 92.6126 135.928C93.5822 135.928 94.3713 135.139 94.3713 134.169C94.3713 133.199 93.5822 132.409 92.6126 132.409Z'
        fill='#010101'
      />
      <path
        d='M72.8936 99.2714C72.6126 99.6528 72.1666 99.8698 71.7058 99.8698C71.5778 99.8698 71.4459 99.8512 71.3179 99.818L67.5278 98.7888C68.4565 100.609 66.4744 101.525 66.4744 101.525C66.868 102.997 64.9659 103.636 64.9659 103.636C65.1739 104.277 64.8622 104.747 64.4315 105.074C63.7563 105.589 62.8437 105.669 62.0616 105.339L57.1752 103.29C57.0498 103.241 56.9461 103.165 56.8578 103.076C56.5736 102.783 56.4974 102.322 56.7112 101.943C56.9186 101.577 57.3371 101.388 57.7435 101.479L57.8747 101.504L54.708 99.4116L60.9653 90.2007L60.9685 90.2039L64.8283 92.8394C64.8283 92.8394 70.5237 95.9696 72.4661 97.1274C73.2142 97.5728 73.4126 98.5687 72.8936 99.2714Z'
        fill='white'
      />
      <path
        d='M63.0318 105.766C62.6766 105.766 62.3156 105.695 61.9719 105.551L57.0862 103.502C56.9473 103.448 56.8174 103.361 56.6951 103.239C56.3297 102.862 56.2555 102.284 56.5108 101.831C56.6561 101.574 56.8827 101.388 57.1419 101.295L54.5806 99.6033C54.5294 99.5693 54.4935 99.5168 54.482 99.4567C54.4699 99.3965 54.4827 99.3345 54.5172 99.2833L60.7745 90.0724C60.8123 90.0173 60.8718 89.9808 60.9383 89.9732C61.0055 89.9655 61.0715 89.9879 61.1201 90.0333L64.9563 92.6503C64.9947 92.6701 70.6587 95.7844 72.5819 96.9306C72.9934 97.1757 73.2833 97.586 73.378 98.0557C73.4727 98.5274 73.3627 99.0202 73.076 99.4074C72.6715 99.9565 71.9681 100.225 71.2583 100.04L67.9118 99.1309C68.0366 99.5457 68.034 99.9469 67.9015 100.328C67.6564 101.037 67.0324 101.471 66.7323 101.646C66.9147 102.758 65.9367 103.48 65.2353 103.777C65.3377 104.337 65.1099 104.845 64.5678 105.256C64.1287 105.593 63.5873 105.766 63.0318 105.766ZM57.538 101.686C57.2801 101.686 57.042 101.824 56.9102 102.056C56.7534 102.333 56.7995 102.687 57.0222 102.917C57.0964 102.991 57.1745 103.044 57.259 103.077L62.1505 105.128C62.8795 105.436 63.6999 105.345 64.2926 104.892C64.7463 104.548 64.8948 104.16 64.7483 103.706C64.7099 103.587 64.7745 103.459 64.8929 103.419C64.962 103.395 66.5863 102.829 66.2529 101.585C66.2241 101.477 66.2772 101.365 66.3777 101.318C66.386 101.314 67.2161 100.919 67.4715 100.176C67.6071 99.7824 67.5572 99.3504 67.3236 98.8928C67.2827 98.8122 67.2929 98.7149 67.3499 98.6445C67.4068 98.5741 67.5009 98.5447 67.5873 98.5677L71.3774 99.5968C71.9003 99.7338 72.4135 99.5367 72.7086 99.1354C72.7086 99.1354 72.7086 99.1354 72.7092 99.1348C72.9191 98.8512 72.9991 98.4909 72.93 98.146C72.8603 97.7978 72.6535 97.506 72.3489 97.3242C70.4321 96.1818 64.7751 93.072 64.7182 93.0407L61.0254 90.5204L55.0273 99.3485L58.0001 101.313C58.091 101.373 58.1275 101.489 58.0865 101.59C58.0462 101.691 57.9425 101.749 57.8324 101.729L57.7012 101.705C57.6462 101.692 57.5918 101.686 57.538 101.686Z'
        fill='#010101'
      />
      <path
        d='M59.911 102.609C59.8809 102.609 59.8502 102.603 59.8208 102.591L57.783 101.716C57.6665 101.666 57.6128 101.531 57.6627 101.415C57.7126 101.298 57.847 101.245 57.9635 101.295L60.0012 102.169C60.1177 102.219 60.1715 102.354 60.1216 102.47C60.0844 102.557 60 102.609 59.911 102.609Z'
        fill='#010101'
      />
      <path
        d='M66.4726 101.753C66.4381 101.753 66.4029 101.746 66.3702 101.729L63.3526 100.217C63.2393 100.16 63.1939 100.023 63.2502 99.9101C63.3072 99.7975 63.4448 99.7514 63.5574 99.8077L66.575 101.319C66.6883 101.376 66.7337 101.514 66.6774 101.627C66.6377 101.707 66.5571 101.753 66.4726 101.753Z'
        fill='#010101'
      />
      <path
        d='M64.9646 103.866C64.923 103.866 64.8807 103.854 64.8423 103.831L62.1006 102.098C61.9937 102.03 61.9617 101.888 62.0295 101.781C62.0967 101.675 62.2388 101.643 62.345 101.71L65.0868 103.444C65.1937 103.511 65.2257 103.653 65.1578 103.76C65.1143 103.828 65.0401 103.866 64.9646 103.866Z'
        fill='#010101'
      />
      <path
        d='M67.5283 99.0173C67.4899 99.0173 67.4515 99.0078 67.4157 98.9873L65.4989 97.9018C65.3888 97.8397 65.3504 97.6996 65.4125 97.5895C65.4746 97.4794 65.6141 97.4404 65.7248 97.5031L67.6416 98.5885C67.7517 98.6506 67.7901 98.7908 67.728 98.9009C67.6858 98.9751 67.6083 99.0173 67.5283 99.0173Z'
        fill='#010101'
      />
      <path
        d='M61.1886 59.5381L57.5425 60.3003C55.0958 60.8117 52.8673 62.0686 51.1636 63.8978L38.9454 77.0152C37.0715 79.3973 36.2862 82.4578 36.7803 85.4478C37.2795 88.4667 39.0363 91.1323 41.6142 92.781L55.3287 101.116L62.0347 89.3416L52.8212 82.1954L63.0875 68.7413L61.1886 59.5381Z'
        fill='#FDCC0A'
      />
      <path
        d='M55.1372 101.344C55.0975 101.344 55.0572 101.333 55.022 101.313L45.4168 95.7315C45.3074 95.6681 45.2703 95.528 45.3336 95.4185C45.3976 95.3091 45.5372 95.272 45.6466 95.3353L55.0495 100.8L61.3522 89.4025L52.2943 82.3753C52.1944 82.2979 52.1759 82.1539 52.254 82.054C52.3314 81.9542 52.4748 81.9356 52.5752 82.0137L61.7887 89.16C61.8776 89.2291 61.9032 89.3532 61.8488 89.4518L55.3381 101.226C55.308 101.28 55.2581 101.319 55.1986 101.336C55.1781 101.341 55.1576 101.344 55.1372 101.344Z'
        fill='#010101'
      />
      <path
        d='M52.4345 82.4237C52.3769 82.4237 52.3206 82.402 52.2777 82.3616L49.7043 79.9507C49.6121 79.8643 49.6076 79.7191 49.694 79.6269C49.7804 79.5348 49.9251 79.5296 50.0179 79.616L52.4064 81.8535L60.5158 71.225C60.5932 71.1245 60.736 71.1047 60.8371 71.1821C60.9376 71.2589 60.9568 71.4023 60.8806 71.5034L52.6169 82.3335C52.5772 82.3847 52.5184 82.4173 52.4537 82.4231C52.4473 82.4237 52.4409 82.4237 52.4345 82.4237Z'
        fill='#010101'
      />
      <path
        d='M125.268 92.3701C124.119 95.3378 119.178 95.7768 119.178 95.7768C119.178 95.7768 115.82 92.125 116.969 89.1573C117.53 87.707 119.162 86.9845 120.612 87.5458C121.18 87.7653 121.637 88.1506 121.948 88.6223C122.495 88.4827 123.09 88.5051 123.658 88.7246C125.107 89.2866 125.829 90.9199 125.268 92.3701Z'
        fill='white'
      />
      <path
        d='M28.2779 122.71C34.2412 122.71 39.0753 117.876 39.0753 111.913C39.0753 105.949 34.2412 101.115 28.2779 101.115C22.3146 101.115 17.4805 105.949 17.4805 111.913C17.4805 117.876 22.3146 122.71 28.2779 122.71Z'
        fill='#FDCC0A'
      />
      <path
        d='M31.4784 113.252L28.1919 115.76C27.1044 116.59 25.5361 115.815 25.5361 114.447V109.431C25.5361 108.064 27.1032 107.288 28.1913 108.118L31.4778 110.625C32.3448 111.285 32.3448 112.59 31.4784 113.252Z'
        fill='white'
      />
      <path
        d='M28.2778 122.939C22.1978 122.939 17.2512 117.992 17.2512 111.912C17.2512 105.832 22.1978 100.886 28.2778 100.886C34.3578 100.886 39.3043 105.832 39.3043 111.912C39.3037 117.992 34.3578 122.939 28.2778 122.939ZM28.2778 101.344C22.4506 101.344 17.7095 106.085 17.7095 111.912C17.7095 117.74 22.4506 122.481 28.2778 122.481C34.105 122.481 38.8461 117.74 38.8461 111.912C38.8461 106.085 34.105 101.344 28.2778 101.344Z'
        fill='#010101'
      />
      <path
        d='M22.8449 91.6542C26.4987 91.6542 29.4606 88.6923 29.4606 85.0385C29.4606 81.3848 26.4987 78.4229 22.8449 78.4229C19.1912 78.4229 16.2292 81.3848 16.2292 85.0385C16.2292 88.6923 19.1912 91.6542 22.8449 91.6542Z'
        fill='#FDCC0A'
      />
      <path
        d='M22.8448 91.8839C19.0701 91.8839 16 88.8132 16 85.0391C16 81.2651 19.0707 78.1943 22.8448 78.1943C26.6189 78.1943 29.6896 81.2651 29.6896 85.0391C29.6902 88.8132 26.6195 91.8839 22.8448 91.8839ZM22.8448 78.6519C19.3229 78.6519 16.4576 81.5172 16.4576 85.0391C16.4576 88.5611 19.3229 91.4263 22.8448 91.4263C26.3667 91.4263 29.232 88.5611 29.232 85.0391C29.232 81.5172 26.3667 78.6519 22.8448 78.6519Z'
        fill='#010101'
      />
      <path
        d='M24.7738 85.6708L22.7529 87.2135C22.0842 87.7238 21.1199 87.2471 21.1199 86.4056V83.3217C21.1199 82.4806 22.0835 82.0035 22.7525 82.5139L24.7734 84.0555C25.3066 84.4614 25.3066 85.2638 24.7738 85.6708Z'
        fill='white'
      />
      <path
        d='M32.8435 63.491L31.2076 64.6827C30.4396 65.2376 29.3689 65.0693 28.8134 64.307L27.6217 62.6712C27.0662 61.9083 27.2345 60.8382 27.9967 60.2827L29.6339 59.0853C30.3967 58.5298 31.4668 58.6981 32.0281 59.461L33.2191 61.1026C33.774 61.8654 33.6063 62.9355 32.8435 63.491Z'
        fill='#FDCC0A'
      />
      <path
        d='M44.7014 50.9813L43.7209 51.6955C43.2608 52.0277 42.6195 51.9272 42.286 51.4702L41.5718 50.4898C41.239 50.0328 41.3395 49.3915 41.7964 49.0587L42.7776 48.3413C43.2345 48.0085 43.8758 48.109 44.2124 48.5666L44.926 49.5502C45.2588 50.0072 45.1584 50.6485 44.7014 50.9813Z'
        fill='#FDCC0A'
      />
      <path
        d='M99.124 53.4598L98.1435 54.174C97.6834 54.5062 97.0421 54.4057 96.7086 53.9488L95.9944 52.9683C95.6616 52.5113 95.7621 51.87 96.219 51.5372L97.2002 50.8198C97.6571 50.487 98.2984 50.5875 98.635 51.0451L99.3486 52.0288C99.6814 52.4851 99.581 53.1264 99.124 53.4598Z'
        fill='#FDCC0A'
      />
      <path
        d='M62.1504 26.8119L61.1699 27.5261C60.7097 27.8583 60.0684 27.7578 59.735 27.3008L59.0208 26.3203C58.688 25.8634 58.7884 25.2221 59.2454 24.8893L60.2265 24.1719C60.6835 23.8391 61.3248 23.9395 61.6614 24.3971L62.375 25.3808C62.7078 25.8378 62.6073 26.4791 62.1504 26.8119Z'
        fill='#FDCC0A'
      />
      <g opacity='0.4'>
        <path
          d='M65.7682 72.7251C65.6805 72.7251 65.5973 72.6745 65.5596 72.5901C65.5077 72.4749 65.5589 72.3392 65.6741 72.2873C65.909 72.1817 66.1759 72.0614 66.3295 71.8649C66.4447 71.7177 66.4882 71.4688 66.3666 71.3472C66.3122 71.2928 66.22 71.2537 66.1221 71.2128C65.9666 71.1475 65.7906 71.0726 65.669 70.9017C65.4981 70.6598 65.5128 70.3181 65.71 69.9641C65.7381 69.9136 65.7688 69.8624 65.7996 69.8118C65.8879 69.6659 65.9717 69.5283 65.9839 69.3926C65.9928 69.2973 65.9557 69.1942 65.8821 69.1097C65.8092 69.0259 65.7119 68.9747 65.6165 68.9702C65.4898 68.9638 65.3932 68.8563 65.3989 68.7302C65.4047 68.6041 65.5173 68.5049 65.6389 68.5126C65.8584 68.5235 66.0729 68.6317 66.2277 68.8089C66.382 68.9869 66.46 69.2141 66.4402 69.4329C66.4184 69.6742 66.2981 69.8739 66.1912 70.0493C66.1637 70.0947 66.1356 70.1408 66.11 70.1869C66.0274 70.3347 65.9673 70.5299 66.0428 70.6368C66.0882 70.7014 66.1861 70.743 66.2994 70.7904C66.4268 70.8441 66.5714 70.9049 66.6898 71.0227C66.9989 71.3318 66.933 71.8368 66.6898 72.1472C66.4664 72.4326 66.1311 72.5837 65.861 72.7053C65.8322 72.7187 65.8002 72.7251 65.7682 72.7251Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M75.2327 74.5877C75.1617 74.5877 75.0919 74.555 75.0471 74.4929C74.9723 74.3905 74.9953 74.2472 75.0977 74.1729C75.3473 73.9918 75.6295 73.7864 75.7646 73.5137C75.8721 73.2968 75.8593 72.9806 75.6686 72.8558C75.586 72.8014 75.4663 72.7784 75.3402 72.7534C75.1508 72.7163 74.9358 72.6741 74.763 72.5134C74.5198 72.2868 74.459 71.9041 74.5966 71.4651C74.6171 71.4004 74.6401 71.3358 74.6625 71.2712C74.7316 71.0766 74.7963 70.8929 74.7764 70.724C74.7623 70.6005 74.6887 70.4808 74.5761 70.3944C74.4622 70.308 74.3259 70.2696 74.2049 70.2894C74.082 70.3092 73.963 70.2235 73.9431 70.0987C73.9239 69.9739 74.0091 69.8568 74.1339 69.8369C74.3777 69.7985 74.6407 69.8689 74.8526 70.0302C75.0644 70.1915 75.2027 70.4251 75.2308 70.6715C75.2628 70.9467 75.1726 71.2008 75.0932 71.4248C75.0721 71.4843 75.051 71.5432 75.0324 71.6027C75.0017 71.7006 74.9147 72.0302 75.074 72.1793C75.1508 72.251 75.2788 72.276 75.4273 72.3048C75.5835 72.3355 75.7607 72.37 75.9182 72.4731C76.3303 72.7425 76.3713 73.3172 76.1735 73.7172C75.9886 74.0916 75.643 74.3425 75.3652 74.5441C75.3262 74.5736 75.2795 74.5877 75.2327 74.5877Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M66.5743 79.4163C66.1877 79.4163 65.8274 79.2538 65.5349 79.1213C65.4197 79.0695 65.3685 78.9338 65.421 78.8179C65.4735 78.7027 65.6092 78.6515 65.7244 78.7034C66.0047 78.8301 66.3209 78.9741 66.6274 78.9562C66.8693 78.9421 67.1375 78.7744 67.1516 78.5472C67.158 78.4487 67.1189 78.3335 67.078 78.2112C67.0165 78.0282 66.9468 77.8202 67.0012 77.5911C67.078 77.2672 67.38 77.0253 67.83 76.9274C67.8965 76.9127 67.9644 76.9005 68.0316 76.8883C68.2344 76.8519 68.4265 76.8173 68.5634 76.7168C68.6633 76.6432 68.7311 76.5203 68.7503 76.3795C68.7688 76.2387 68.7356 76.1024 68.6581 76.0051C68.5794 75.9059 68.5954 75.7619 68.694 75.6832C68.7932 75.6045 68.9372 75.6205 69.0159 75.7197C69.1708 75.9136 69.2392 76.176 69.2047 76.4397C69.1695 76.7034 69.0351 76.9389 68.8354 77.0861C68.6121 77.2499 68.3471 77.2973 68.1128 77.3395C68.0508 77.3504 67.9887 77.3619 67.9279 77.3747C67.8274 77.3965 67.4978 77.4842 67.4472 77.6967C67.4236 77.7984 67.4645 77.9219 67.5132 78.0653C67.5644 78.2163 67.622 78.3879 67.6098 78.576C67.5791 79.0669 67.1004 79.3875 66.6549 79.4138C66.6268 79.4157 66.6005 79.4163 66.5743 79.4163Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M84.5658 73.1406C84.4525 73.1406 84.3425 73.1137 84.2458 73.0619C84.0705 72.9672 83.9636 72.8161 83.8682 72.6824C83.8452 72.6497 83.8221 72.6177 83.7985 72.5864C83.7133 72.4763 83.6129 72.4097 83.5565 72.4232C83.5117 72.4328 83.4554 72.4865 83.3959 72.5441C83.3159 72.6209 83.225 72.708 83.0977 72.7592C82.7591 72.8942 82.3956 72.6798 82.2401 72.3944C82.1005 72.1397 82.0954 71.8504 82.0916 71.6187C82.0897 71.492 82.1901 71.3877 82.3169 71.3857C82.441 71.3915 82.5479 71.4843 82.5498 71.611C82.553 71.8024 82.5569 72.0193 82.642 72.1749C82.706 72.2926 82.8436 72.3675 82.9274 72.3336C82.9722 72.3157 83.0241 72.2657 83.0785 72.2139C83.1719 72.1243 83.289 72.0123 83.4612 71.9758C83.7025 71.9246 83.9591 72.0449 84.1613 72.308C84.1889 72.3438 84.2151 72.3803 84.2413 72.4174C84.3137 72.5192 84.3821 72.6152 84.4634 72.6593C84.514 72.6869 84.5844 72.6907 84.6509 72.6696C84.7175 72.6485 84.7725 72.6043 84.7981 72.5525C84.8538 72.4392 84.9914 72.3918 85.1047 72.4475C85.2186 72.5032 85.2653 72.6408 85.2097 72.7541C85.129 72.9185 84.9767 73.0465 84.7911 73.1061C84.7169 73.1285 84.6407 73.1406 84.5658 73.1406Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M81.3211 81.2615C81.2418 81.2615 81.1605 81.2474 81.0824 81.2186C80.8827 81.1456 80.7227 80.992 80.644 80.7962C80.5653 80.6003 80.5704 80.3968 80.5755 80.2183C80.5768 80.1722 80.5781 80.1261 80.5774 80.0807C80.5768 79.9072 80.5307 79.7613 80.4661 79.7255C80.427 79.7037 80.3547 79.7114 80.2709 79.7197C80.1621 79.7306 80.0277 79.7447 79.8894 79.6979C79.5317 79.5776 79.387 79.152 79.4331 78.8128C79.4754 78.4979 79.6462 78.2419 79.7838 78.0365C79.8542 77.9315 79.9963 77.9027 80.1013 77.9731C80.2062 78.0435 80.2344 78.1856 80.164 78.2906C80.0469 78.4653 79.9144 78.6637 79.8869 78.873C79.8645 79.04 79.9362 79.2295 80.0354 79.2627C80.0789 79.2775 80.1499 79.2704 80.2242 79.2627C80.3579 79.2493 80.5237 79.232 80.6882 79.3229C80.9877 79.4887 81.0344 79.8663 81.0357 80.0762C81.0357 80.1267 81.035 80.1779 81.0331 80.2291C81.0293 80.377 81.0254 80.5165 81.069 80.6247C81.099 80.6989 81.1624 80.7597 81.2392 80.7879C81.2738 80.8007 81.341 80.8167 81.4024 80.784C81.5144 80.7239 81.6526 80.7667 81.7122 80.8787C81.7717 80.9901 81.7294 81.129 81.6174 81.1885C81.5278 81.2371 81.4254 81.2615 81.3211 81.2615Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M94.7975 72.8813C94.6733 72.8813 94.5466 72.857 94.4257 72.809C94.1786 72.7104 93.9828 72.5223 93.8893 72.2925C93.785 72.0359 93.8036 71.7671 93.8196 71.5296C93.8241 71.4669 93.8285 71.4042 93.8305 71.3421C93.8337 71.2397 93.8298 70.8979 93.6359 70.7975C93.5431 70.7495 93.4125 70.7591 93.2621 70.7712C93.1034 70.7834 92.9229 70.7975 92.7437 70.7405C92.2746 70.5914 92.0813 70.0487 92.1645 69.6103C92.2426 69.2 92.5082 68.8659 92.722 68.5971C92.8007 68.4979 92.9453 68.482 93.0439 68.5607C93.1431 68.6394 93.1591 68.7834 93.0804 68.8826C92.8884 69.1239 92.6714 69.3971 92.6145 69.696C92.5697 69.9341 92.6663 70.2349 92.8826 70.304C92.9773 70.3341 93.0989 70.3245 93.2269 70.3149C93.4183 70.2996 93.6372 70.2829 93.8471 70.3917C94.1421 70.5447 94.3034 70.8967 94.2887 71.3575C94.2861 71.4253 94.2817 71.4938 94.2772 71.5623C94.2631 71.7684 94.2497 71.9629 94.3137 72.1203C94.3604 72.2349 94.4628 72.3309 94.5953 72.384C94.7277 72.4365 94.8679 72.4372 94.9812 72.386C95.0951 72.3341 95.2321 72.384 95.2845 72.4999C95.337 72.6151 95.2858 72.7508 95.1706 72.8032C95.0548 72.8551 94.9274 72.8813 94.7975 72.8813Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M95.7953 81.2622C95.5489 81.2622 95.3019 81.1534 95.1176 80.9633C94.9326 80.7719 94.8309 80.5204 94.8398 80.2727C94.8494 79.9963 94.9768 79.7588 95.0894 79.5495C95.1195 79.4939 95.1489 79.4382 95.1765 79.3819C95.2213 79.2897 95.3576 78.9761 95.2219 78.8052C95.1566 78.7227 95.0337 78.6791 94.8917 78.6279C94.7419 78.5742 94.5717 78.5127 94.4315 78.3873C94.0648 78.0583 94.1109 77.4843 94.3662 77.1188C94.6043 76.7777 94.9845 76.5812 95.2897 76.4231C95.4024 76.3649 95.5406 76.4091 95.5982 76.5217C95.6565 76.6343 95.6123 76.7719 95.5003 76.8302C95.2264 76.9716 94.916 77.1323 94.7419 77.3812C94.603 77.5796 94.5678 77.8945 94.7374 78.0462C94.811 78.1121 94.9256 78.1531 95.0465 78.1966C95.2283 78.2619 95.4344 78.3361 95.5809 78.5204C95.7877 78.7815 95.7902 79.1681 95.588 79.5822C95.5579 79.643 95.5259 79.7038 95.4933 79.7652C95.396 79.947 95.3045 80.1185 95.2981 80.2881C95.2936 80.4123 95.348 80.5415 95.4472 80.6439C95.5457 80.7457 95.6622 80.8059 95.7979 80.8039H95.7985C95.9246 80.8039 96.027 80.9057 96.0277 81.0318C96.0283 81.1585 95.9259 81.2615 95.7998 81.2615C95.7979 81.2622 95.7966 81.2622 95.7953 81.2622Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M105.217 78.8658C105.177 78.8658 105.137 78.8555 105.101 78.8344C104.887 78.709 104.729 78.4882 104.667 78.2296C104.605 77.9711 104.646 77.7029 104.78 77.4936C104.93 77.2607 105.16 77.1211 105.364 76.9983C105.418 76.9656 105.472 76.933 105.524 76.8984C105.61 76.8421 105.887 76.6418 105.857 76.4261C105.843 76.3224 105.76 76.2219 105.664 76.1055C105.563 75.9826 105.448 75.8437 105.391 75.6639C105.243 75.1947 105.575 74.7237 105.981 74.5394C106.361 74.3672 106.787 74.3922 107.131 74.412C107.257 74.4191 107.354 74.5279 107.346 74.6539C107.338 74.78 107.234 74.8741 107.104 74.8696C106.797 74.8517 106.447 74.8312 106.17 74.9573C105.949 75.0571 105.759 75.3099 105.828 75.5269C105.858 75.6216 105.935 75.7151 106.017 75.8149C106.14 75.964 106.28 76.1323 106.312 76.3659C106.356 76.6962 106.161 77.0303 105.776 77.2831C105.719 77.3202 105.66 77.356 105.601 77.3919C105.425 77.4987 105.258 77.5992 105.166 77.7426C105.099 77.8469 105.08 77.9858 105.113 78.1247C105.146 78.2629 105.226 78.3781 105.333 78.4408C105.443 78.5048 105.478 78.645 105.414 78.7544C105.372 78.8255 105.295 78.8658 105.217 78.8658Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M114.99 65.4706C114.945 65.4706 114.899 65.4674 114.853 65.4617C114.589 65.4265 114.354 65.2914 114.207 65.0911C114.044 64.8684 113.997 64.6028 113.955 64.3686C113.944 64.3065 113.933 64.2444 113.92 64.183C113.898 64.0825 113.811 63.7522 113.599 63.7017C113.497 63.6767 113.373 63.719 113.231 63.7663C113.079 63.8169 112.906 63.8732 112.72 63.8623C112.229 63.8316 111.909 63.3522 111.883 62.9062C111.86 62.4902 112.036 62.101 112.179 61.7881C112.231 61.6729 112.367 61.6223 112.482 61.6742C112.597 61.7266 112.648 61.8623 112.596 61.9775C112.468 62.2585 112.324 62.5766 112.342 62.8799C112.355 63.1218 112.522 63.3906 112.749 63.4047C112.852 63.4118 112.964 63.3721 113.086 63.3318C113.269 63.2703 113.476 63.2012 113.706 63.2556C114.03 63.333 114.272 63.6351 114.369 64.0857C114.383 64.1522 114.395 64.2201 114.407 64.2879C114.443 64.4908 114.477 64.6828 114.578 64.8198C114.651 64.9196 114.774 64.9881 114.915 65.0073C115.055 65.0258 115.192 64.9926 115.29 64.9158C115.388 64.837 115.533 64.8537 115.611 64.9522C115.69 65.0514 115.674 65.1954 115.575 65.2742C115.413 65.4022 115.206 65.4706 114.99 65.4706Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M119.012 69.3379C118.996 69.3379 118.981 69.336 118.964 69.3328C118.722 69.2816 118.502 69.1223 118.361 68.8963C118.22 68.6704 118.175 68.4029 118.235 68.1629C118.303 67.8948 118.477 67.6893 118.631 67.5076C118.672 67.4596 118.712 67.4116 118.751 67.3629C118.814 67.2823 119.013 67.0045 118.917 66.8093C118.871 66.7152 118.76 66.6461 118.632 66.5661C118.497 66.4816 118.343 66.3863 118.233 66.234C117.944 65.8359 118.109 65.2835 118.436 64.9802C118.742 64.6967 119.155 64.5847 119.485 64.4944C119.608 64.4611 119.733 64.5335 119.766 64.6557C119.8 64.7779 119.727 64.9034 119.605 64.9367C119.308 65.0173 118.97 65.1088 118.748 65.3155C118.57 65.4807 118.469 65.7802 118.603 65.9645C118.661 66.0445 118.764 66.1091 118.874 66.177C119.037 66.2794 119.224 66.3952 119.327 66.6064C119.475 66.9047 119.395 67.2835 119.11 67.6458C119.068 67.6989 119.024 67.7514 118.979 67.8039C118.845 67.9613 118.719 68.1098 118.678 68.2749C118.647 68.3952 118.673 68.5328 118.748 68.6538C118.823 68.7747 118.936 68.8586 119.058 68.8842C119.181 68.9104 119.261 69.032 119.235 69.1556C119.213 69.2644 119.119 69.3379 119.012 69.3379Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M80.5256 67.5817C80.4117 67.5817 80.3029 67.5785 80.2005 67.5747C80.0737 67.5708 79.9745 67.4652 79.9784 67.3385C79.9829 67.2124 80.0961 67.1145 80.2145 67.1171C80.5864 67.1286 81.0043 67.142 81.3909 66.9865C81.755 66.8399 82.0552 66.5059 82.0328 66.2716C82.0238 66.1782 81.963 66.0854 81.8933 65.9791C81.7883 65.8198 81.6577 65.6214 81.6846 65.3551C81.7307 64.8899 82.2197 64.5551 82.5077 64.3971C82.5838 64.3548 82.6619 64.3145 82.7406 64.2742C82.9928 64.1449 83.2309 64.022 83.3979 63.8409C83.5368 63.6899 83.6078 63.4889 83.5829 63.3155C83.572 63.2387 83.5368 63.1337 83.4337 63.0716C83.3256 63.0063 83.2904 62.8655 83.3557 62.7574C83.4216 62.6492 83.5617 62.614 83.6705 62.6793C83.8715 62.8003 84.0014 63.0031 84.0366 63.2508C84.0808 63.5619 83.9681 63.8985 83.7352 64.1513C83.5131 64.3926 83.227 64.5391 82.9505 64.6819C82.8763 64.7203 82.8014 64.7587 82.7291 64.7983C82.3841 64.9878 82.1589 65.2188 82.1409 65.4006C82.1313 65.5004 82.1857 65.59 82.2766 65.7276C82.3675 65.8659 82.4699 66.022 82.4897 66.2287C82.5377 66.7356 82.0475 67.2169 81.5617 67.4121C81.2097 67.5536 80.8488 67.5817 80.5256 67.5817Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M72.9927 87.3698C72.9453 87.3698 72.8973 87.3551 72.8564 87.3244C72.6765 87.1906 72.5562 86.9782 72.5261 86.7401C72.4961 86.5027 72.5594 86.2665 72.6996 86.0924C72.8551 85.8998 73.0708 85.7993 73.2615 85.7103C73.3108 85.6873 73.3601 85.6642 73.4081 85.6399C73.5636 85.5606 73.728 85.4268 73.7242 85.2898C73.7223 85.2073 73.6621 85.1158 73.5924 85.0102C73.5143 84.8918 73.426 84.758 73.3933 84.5916C73.3063 84.1558 73.6487 83.766 74.0295 83.6406C74.3821 83.5254 74.7527 83.5874 75.0497 83.6374C75.1745 83.6585 75.2589 83.7762 75.2378 83.901C75.2167 84.0258 75.0977 84.1097 74.9741 84.0892C74.713 84.0457 74.4173 83.9958 74.1729 84.0758C73.9885 84.1359 73.8081 84.326 73.8426 84.502C73.8586 84.5807 73.9149 84.6665 73.9751 84.758C74.0698 84.9014 74.1767 85.0646 74.1831 85.2777C74.1908 85.5791 73.9847 85.8594 73.6173 86.0476C73.5649 86.0745 73.5098 86.1001 73.4554 86.1257C73.2961 86.1999 73.145 86.2703 73.0567 86.3798C72.994 86.4579 72.9665 86.5679 72.9812 86.6825C72.9959 86.797 73.0503 86.8975 73.1303 86.957C73.2314 87.0326 73.2532 87.1759 73.1777 87.2777C73.1316 87.3378 73.0625 87.3698 72.9927 87.3698Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M63.7 89.6765C63.6526 89.6765 63.6046 89.6618 63.5637 89.631C63.3838 89.4979 63.2635 89.2848 63.2334 89.0474C63.2027 88.8093 63.2661 88.5731 63.4069 88.399C63.5624 88.2064 63.7781 88.1059 63.9688 88.017C64.0181 87.9939 64.0673 87.9709 64.1153 87.9466C64.2709 87.8672 64.4353 87.7334 64.4315 87.5965C64.4296 87.5139 64.3694 87.4224 64.2997 87.3168C64.2216 87.199 64.1333 87.0646 64.1006 86.8982C64.0136 86.4624 64.356 86.0726 64.7368 85.9472C65.0901 85.832 65.46 85.8941 65.7569 85.944C65.8817 85.9651 65.9662 86.0829 65.9451 86.2077C65.924 86.3325 65.8049 86.4163 65.6814 86.3958C65.4209 86.3523 65.1246 86.3024 64.8795 86.3824C64.6952 86.4426 64.5147 86.6326 64.5499 86.8086C64.5659 86.8874 64.6222 86.9731 64.6824 87.0646C64.7771 87.208 64.884 87.3712 64.8904 87.5843C64.8981 87.8858 64.692 88.1661 64.3246 88.3542C64.2721 88.3811 64.2171 88.4067 64.1627 88.4323C64.0033 88.5066 63.8523 88.577 63.764 88.6864C63.7013 88.7645 63.6737 88.8746 63.6885 88.9898C63.7032 89.1043 63.7576 89.2042 63.8376 89.2637C63.9387 89.3392 63.9605 89.4826 63.8849 89.5843C63.8389 89.6445 63.7697 89.6765 63.7 89.6765Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M53.3984 68.9688C53.2044 68.9688 53.0163 68.9388 52.8512 68.9125C52.7264 68.8927 52.6412 68.7756 52.6611 68.6501C52.6809 68.5253 52.7974 68.4402 52.9235 68.46C53.1776 68.5003 53.4662 68.5464 53.703 68.4664C53.8803 68.4063 54.0531 68.2226 54.0179 68.0543C54.0019 67.9787 53.9462 67.8956 53.8873 67.8079C53.7932 67.6677 53.6864 67.509 53.6793 67.2991C53.6691 67.0028 53.8688 66.7256 54.2278 66.5375C54.2796 66.5106 54.3328 66.485 54.3859 66.46C54.5401 66.3864 54.686 66.3173 54.7712 66.2098C54.8307 66.1343 54.8563 66.028 54.8416 65.9179C54.8268 65.8072 54.7737 65.7119 54.6963 65.6549C54.5945 65.58 54.5721 65.4373 54.6464 65.3349C54.7206 65.2325 54.864 65.2101 54.9664 65.285C55.1436 65.4149 55.264 65.6229 55.2953 65.8559C55.3267 66.0888 55.2672 66.3218 55.1302 66.4939C54.9792 66.6847 54.7686 66.7845 54.5824 66.8728C54.5344 66.8959 54.4864 66.9183 54.4403 66.9426C54.2912 67.0207 54.1331 67.1506 54.1376 67.2818C54.1401 67.3612 54.199 67.4488 54.2681 67.5512C54.3456 67.6664 54.4326 67.7964 54.4665 67.9595C54.5555 68.3871 54.2227 68.773 53.8496 68.8991C53.7004 68.9509 53.5475 68.9688 53.3984 68.9688Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M56.8278 74.3745C56.8105 74.3745 56.7926 74.3726 56.7753 74.3681C56.6524 74.3393 56.5756 74.2158 56.6044 74.0929C56.6614 73.8478 56.7266 73.5707 56.6678 73.3281C56.6172 73.1208 56.4354 72.9518 56.2985 72.9621C56.2294 72.9678 56.1487 73.0113 56.0623 73.0568C55.926 73.1291 55.7538 73.22 55.5497 73.2008C55.2655 73.1745 55.0159 72.9461 54.8649 72.5742C54.8431 72.5205 54.8226 72.4654 54.8028 72.4104C54.7433 72.2485 54.6876 72.0961 54.5897 71.9976C54.5193 71.9272 54.4207 71.8907 54.3087 71.8926C54.2127 71.8958 54.1276 71.933 54.0745 71.9944C53.9913 72.0904 53.8473 72.1006 53.7513 72.0174C53.6553 71.9349 53.645 71.7902 53.7276 71.6942C53.8639 71.5368 54.0694 71.4421 54.2927 71.4344C54.5257 71.4242 54.7542 71.5131 54.9142 71.6737C55.0831 71.8433 55.1625 72.0603 55.2329 72.2517C55.2508 72.3016 55.2694 72.3515 55.2892 72.4001C55.3698 72.5992 55.4889 72.7342 55.5919 72.7432C55.6604 72.7489 55.7474 72.7042 55.8473 72.651C55.9644 72.5883 56.0975 72.5179 56.2601 72.5038C56.6767 72.4686 57.021 72.8398 57.1126 73.2181C57.1977 73.5669 57.1164 73.9157 57.0505 74.196C57.0262 74.3035 56.9321 74.3745 56.8278 74.3745Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M46.4978 75.5667C46.3973 75.5667 46.3052 75.5001 46.277 75.399C46.2431 75.2774 46.3141 75.1507 46.4357 75.1167C46.6777 75.0495 46.9516 74.9728 47.1365 74.8038C47.294 74.6598 47.3599 74.4262 47.2793 74.304C47.2409 74.2464 47.1641 74.1958 47.0821 74.1427C46.9529 74.0582 46.7922 73.9532 46.7097 73.7625C46.5957 73.5014 46.6757 73.1724 46.9285 72.8607C46.965 72.8159 47.0034 72.7718 47.0418 72.7276C47.1551 72.5977 47.262 72.4742 47.301 72.3411C47.3285 72.2457 47.3132 72.1375 47.2575 72.0447C47.2082 71.9622 47.1346 71.9052 47.0553 71.8886C46.9317 71.8624 46.8524 71.7408 46.8793 71.6166C46.9055 71.4931 47.0265 71.4144 47.1513 71.4406C47.3548 71.4841 47.5372 71.6185 47.6511 71.8099C47.7714 72.0115 47.8047 72.2521 47.7407 72.4697C47.6735 72.6995 47.5212 72.8742 47.3874 73.0284C47.3522 73.0687 47.3177 73.1084 47.285 73.1494C47.1493 73.3164 47.0892 73.4854 47.1301 73.5801C47.1583 73.6447 47.2396 73.6979 47.3337 73.7593C47.445 73.8323 47.5711 73.9148 47.662 74.0505C47.893 74.398 47.7349 74.8787 47.4476 75.1417C47.182 75.3849 46.8377 75.4815 46.5612 75.5583C46.5388 75.5635 46.5183 75.5667 46.4978 75.5667Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M44.0428 83.0198C44.0185 83.0198 43.9942 83.0159 43.9705 83.0076C43.8502 82.9673 43.7856 82.838 43.8259 82.7177C43.9289 82.4079 44.0467 82.0572 43.9974 81.7276C43.9584 81.4646 43.7497 81.1708 43.511 81.1683C43.5104 81.1683 43.5091 81.1683 43.5084 81.1683C43.4086 81.1683 43.296 81.2156 43.1763 81.2655C42.9932 81.3423 42.7865 81.4287 42.5459 81.3839C42.2137 81.3219 41.9417 81.0191 41.8003 80.5539C41.7792 80.4841 41.7606 80.4131 41.7414 80.342C41.6832 80.1231 41.6281 79.9164 41.5072 79.7699C41.4156 79.6591 41.2748 79.5907 41.12 79.5817C40.9798 79.574 40.8518 79.6169 40.7641 79.7007C40.6726 79.7884 40.5273 79.7852 40.4403 79.6943C40.3526 79.6028 40.3558 79.4582 40.4467 79.3705C40.6252 79.199 40.8768 79.1094 41.1456 79.1247C41.4265 79.1407 41.687 79.2694 41.8598 79.478C42.0454 79.702 42.119 79.9798 42.1843 80.2243C42.2016 80.2902 42.2195 80.3561 42.2387 80.4207C42.3072 80.646 42.446 80.8995 42.6304 80.9334C42.736 80.9532 42.8588 80.902 42.9996 80.8431C43.15 80.7798 43.3145 80.7055 43.5155 80.71C44.0134 80.7145 44.382 81.1964 44.4505 81.6604C44.5152 82.0982 44.3737 82.5219 44.2598 82.8623C44.2284 82.9596 44.1388 83.0198 44.0428 83.0198Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M51.8738 88.1986C51.8194 88.1986 51.765 88.1794 51.7209 88.1403C51.6268 88.0558 51.6185 87.9112 51.7029 87.8171C51.9205 87.5739 52.1669 87.2981 52.2527 86.9762C52.3212 86.7195 52.2457 86.3662 52.0281 86.2702C51.9359 86.2299 51.813 86.2286 51.6818 86.2274C51.4841 86.2254 51.2594 86.2229 51.0559 86.0866C50.7749 85.8978 50.645 85.5125 50.7001 85.0286C50.7084 84.9563 50.7186 84.8827 50.7301 84.8098C50.7634 84.5864 50.7942 84.3752 50.741 84.1934C50.7007 84.0552 50.5983 83.9368 50.4601 83.8677C50.3365 83.8056 50.2002 83.7934 50.0863 83.8363C49.9692 83.8805 49.8361 83.821 49.7919 83.7019C49.7477 83.5835 49.8073 83.4517 49.9263 83.4069C50.158 83.3205 50.4281 83.3384 50.6655 83.4581C50.9177 83.5842 51.1052 83.8056 51.1814 84.0654C51.2626 84.3438 51.221 84.6274 51.1839 84.8776C51.1737 84.9454 51.1634 85.0133 51.1564 85.0805C51.1301 85.3147 51.157 85.6021 51.3125 85.707C51.4021 85.7672 51.5346 85.7685 51.6876 85.7698C51.8508 85.7717 52.0358 85.773 52.2137 85.8517C52.6694 86.0526 52.8165 86.6414 52.6962 87.0946C52.5823 87.5221 52.2847 87.8555 52.0454 88.123C51.9993 88.1723 51.9366 88.1986 51.8738 88.1986Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M52.254 95.723C52.1996 95.723 52.1452 95.7038 52.1011 95.6647C52.007 95.5803 51.9987 95.4356 52.0832 95.3415C52.3008 95.0983 52.5472 94.8225 52.6329 94.5006C52.7014 94.2439 52.6259 93.8907 52.4083 93.7947C52.3161 93.7543 52.1932 93.7531 52.0627 93.7518C51.8649 93.7499 51.6403 93.7473 51.4368 93.611C51.1558 93.4222 51.0259 93.0369 51.0803 92.5531C51.0886 92.4807 51.0988 92.4078 51.1104 92.3348C51.1436 92.1108 51.175 91.8996 51.1219 91.7179C51.0816 91.5796 50.9785 91.4612 50.8403 91.3915C50.7155 91.3287 50.5798 91.3172 50.4672 91.3601C50.35 91.4043 50.2169 91.3447 50.1728 91.2257C50.1286 91.1073 50.1881 90.9755 50.3072 90.9307C50.5388 90.8443 50.8076 90.8628 51.0457 90.9812C51.2979 91.1073 51.486 91.3287 51.5622 91.5886C51.6441 91.8676 51.6019 92.1511 51.5641 92.402C51.5539 92.4699 51.5443 92.5371 51.5366 92.6043C51.5104 92.8385 51.5372 93.1259 51.6934 93.2302C51.783 93.2903 51.9155 93.2916 52.0684 93.2935C52.2316 93.2955 52.4166 93.2967 52.5945 93.3755C53.0502 93.5764 53.1974 94.1652 53.0771 94.6183C52.9632 95.0459 52.6656 95.3793 52.4262 95.6468C52.3795 95.6974 52.3168 95.723 52.254 95.723Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M43.4446 90.0322C43.3518 90.0322 43.2647 89.9752 43.2301 89.8837C43.186 89.7653 43.2455 89.6335 43.3639 89.5887C43.6698 89.4741 44.0161 89.3442 44.2407 89.0984C44.4206 88.9026 44.5172 88.5551 44.3687 88.3688C44.306 88.2901 44.1978 88.2319 44.0826 88.1704C43.9079 88.077 43.7102 87.9708 43.5937 87.7557C43.4324 87.4581 43.4964 87.0562 43.7684 86.6536C43.8087 86.5935 43.8522 86.534 43.8957 86.4744C44.0295 86.2914 44.155 86.1186 44.1927 85.9324C44.2209 85.7916 44.185 85.6386 44.0948 85.5132C44.0135 85.3999 43.8977 85.3269 43.7786 85.3122C43.6532 85.2968 43.5642 85.1823 43.5796 85.0568C43.5949 84.9314 43.7095 84.8418 43.8349 84.8578C44.0807 84.8885 44.3105 85.03 44.466 85.2456C44.6311 85.4741 44.6945 85.7576 44.6414 86.0226C44.5844 86.3074 44.4148 86.5397 44.2657 86.7445C44.2254 86.7996 44.1857 86.8546 44.1473 86.9103C44.0154 87.1055 43.9066 87.373 43.9956 87.5375C44.0474 87.6322 44.1633 87.6949 44.2983 87.7672C44.4423 87.844 44.6049 87.9317 44.7265 88.084C45.0375 88.4738 44.8948 89.0639 44.578 89.4088C44.2791 89.7352 43.8606 89.892 43.5246 90.0181C43.499 90.0271 43.4714 90.0322 43.4446 90.0322Z'
          fill='white'
        />
      </g>
      <g opacity='0.4'>
        <path
          d='M53.1283 78.0628C53.1066 78.0628 53.0842 78.0596 53.0618 78.0526C52.9408 78.0155 52.873 77.8881 52.9095 77.7665C52.969 77.57 53.0311 77.3671 52.9984 77.1822C52.9735 77.0407 52.8506 76.9083 52.7699 76.9102C52.7245 76.9108 52.6611 76.9396 52.5952 76.9691C52.4768 77.0222 52.3296 77.0881 52.153 77.0606C51.9111 77.0222 51.7107 76.8155 51.6039 76.4948C51.5891 76.4507 51.5757 76.4052 51.5623 76.3591C51.5251 76.2324 51.4899 76.1127 51.424 76.0372C51.3805 75.9879 51.3114 75.9572 51.2397 75.9547C51.1648 75.9508 51.1181 75.9726 51.0816 76.0097C50.992 76.0999 50.8474 76.1006 50.7571 76.0116C50.6675 75.9227 50.6663 75.778 50.7552 75.6878C50.8813 75.5604 51.0528 75.4907 51.2531 75.4964C51.4535 75.5028 51.6416 75.5899 51.769 75.7351C51.9008 75.8862 51.9546 76.0686 52.0019 76.2292C52.0141 76.2695 52.0256 76.3099 52.0391 76.3489C52.0864 76.491 52.1632 76.5972 52.2259 76.6068C52.2688 76.6132 52.3354 76.5825 52.4077 76.5505C52.5037 76.507 52.6227 76.4532 52.7629 76.4507C53.1239 76.4468 53.3939 76.7764 53.4509 77.1015C53.5027 77.394 53.4176 77.6737 53.3491 77.8983C53.3178 77.9995 53.2269 78.0628 53.1283 78.0628Z'
          fill='white'
        />
      </g>
    </svg>
  );
}

export default NoStreamRequest;
