import React from 'react';
import MenuHeader from '../../MenuHeader';
import Box from '@mui/material/Box';
import api from '../../../../config/api';
import TopRequestCard from './TopRequestCard';
import Loading from '../../../../components/Loading/Loading';
import NoStreamRequestCard from './NoStreamRequestCard';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../routes';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';

const fetchTopRequests = async () => {
  const { data } = await api.get('/api/user/task?page=1&size=3');
  return data;
};

const TopRequestComponent = () => {
  const navigate = useNavigate();
  const { data: topRequests, isLoading } = useQuery({
    queryKey: ['topRequests'],
    queryFn: fetchTopRequests,
  });
  if (isLoading) return <Loading />;
  return (
    <Box mt={4}>
      <MenuHeader
        title={'Top Requests'}
        isViewAll={topRequests?.data?.totalCount > 0}
        onClick={() => navigate(`${ROUTES.DISCOVER.replace(':discoverType', 1)}`)}
      />
      <Stack spacing={1.5} mt={2}>
        {topRequests?.data?.totalCount > 0 ? (
          <>
            {topRequests?.data?.result?.map((topRequestCard) => (
              <TopRequestCard key={topRequestCard?._id} data={topRequestCard} />
            ))}
          </>
        ) : (
          <NoStreamRequestCard />
        )}
      </Stack>
    </Box>
  );
};

export default TopRequestComponent;
