import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import api from '../../config/api';
import TaskDetails from '../../components/TaskDetails/TaskDetails';
import getDateByLocalTimezone from '../../utils/getDateByLocalTimezone';
import Loading from '../../components/Loading/Loading';
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar';
import useGetProposal from '../../hooks/useGetProposal';
import useGetTask from '../../hooks/useGetTask';
import { toast } from 'react-toastify';
import ROUTES from '../../routes';

const ScheduleLivePage = () => {
  const { proposalId, taskId } = useParams();
  const { data: proposal, isLoading: isProposalLoading } = useGetProposal(proposalId);
  const { data: task, isLoading: isTaskLoading } = useGetTask(taskId);
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const acceptProposal = async () => {
    try {
      setIsLoading(true);
      await api.get(`/api/user/proposal/accept?id=${proposalId}`);
      setIsLoading(false);
      toast.success('Proposal Accepted Successfully');
      navigate(ROUTES.MY_TASK);
    } catch (error) {
      setIsLoading(false);
      toast.error('Error in accepting the proposal');
      console.log(error);
    }
  };
  const formattedDate = getDateByLocalTimezone(proposal?.data?.proposeAt, proposal?.data?.timeZone);
  if (isProposalLoading || isTaskLoading || isLoading) return <Loading />;
  return (
    <Box sx={{ pb: 8, position: 'relative', height: '100dvh' }}>
      <TopNavigation title={'My Task'} />
      <TopNavigationSpace />
      <Stack spacing={2} mt={2}>
        <Stack spacing={1}>
          <Typography variant='h4'>{task?.data?.title}</Typography>
          <Typography variant='h6'>{task?.data?.description}</Typography>
        </Stack>
        <TaskDetails
          date={formattedDate}
          amount={proposal?.data?.amount}
          duration={task?.data?.duration}
          timeZone={task?.data?.timeZone}
        />
        <Stack direction='row' alignItems='center'>
          <Typography variant='h6'>Requested by:</Typography>
          <Stack direction='row' alignItems='center' spacing={1} ml={1}>
            <CustomAvatar name={task?.data?.user?.name} avatarUrl={task?.data?.user?.profile?.avatarUrl} size={40} />
            <Typography variant='h6_dark'>{task?.data?.user?.name}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          position: 'absolute',
          bottom: 70,
          display: 'flex',
          width: '100%',
          gap: 1.5,
          justifyContent: 'center',
        }}
      >
        {task?.data?.requestStreamer ? (
          task?.data?.accepted ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 70,
                display: 'flex',
                width: '100%',
                gap: 1.5,
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
              }}
            >
              <Typography variant='h6_semibold'>Task already accepted</Typography>
            </Box>
          ) : (
            <>
              <Button fullWidth variant='outlined' sx={{ borderRadius: 50, color: '#000' }}>
                Decline
              </Button>
              <Button fullWidth variant='contained' sx={{ borderRadius: 50 }} onClick={acceptProposal}>
                Accept
              </Button>
            </>
          )
        ) : (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              p: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant='h6_semibold'>Proposal is pending for approval</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleLivePage;
