import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ClockIcon from '../../../../assets/icons/ClockIcon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import useInfiniteScrollUtils from '../../../../utils/useInfiniteScrollUtils';
import CoinIcon from '../../../../assets/icons/CoinIcon';
import CustomAvatar from '../../../../components/CustomAvatar/CustomAvatar';

dayjs.extend(relativeTime);

const TopRequestCard = ({ data, isLoading, isFetching, hasNextPage, fetchNextPage, index, totalCount }) => {
  const observer = useRef();
  const navigate = useNavigate();
  const lastElementRef = useInfiniteScrollUtils({ isLoading, isFetching, hasNextPage, fetchNextPage, observer });
  return (
    <Card
      sx={{ backgroundColor: (theme) => theme.palette.dark.light }}
      ref={index === totalCount - 1 ? lastElementRef : null}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack spacing={1}>
              <Typography variant='h5_bold'>{data.title}</Typography>
              <Stack spacing={0.5} direction='row' alignItems='center'>
                <ClockIcon />
                <Typography variant='h6'>{dayjs(data?.createdAt).fromNow()}</Typography>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Stack direction='row' spacing={0.5} alignItems='center'>
                <CoinIcon />
                <Typography variant='h6'>${data?.amount}</Typography>
              </Stack>
              <Stack direction='row' spacing={0.5} alignItems='center'>
                <ClockIcon />
                <Typography variant='h6'>{data?.duration} min</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box display='flex' justifyContent='space-between'>
            <Stack direction='row' spacing={1} alignItems='center'>
              <CustomAvatar avatarUrl={data?.user?.profile?.avatarUrl} name={data?.user?.name} />
              <Typography variant='h6_bold'>{data?.user?.name}</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ borderRadius: 50, fontSize: '12px' }}
              onClick={() => navigate(`/submit-proposal/${data?._id}`)}
            >
              Execute Request
            </Button>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TopRequestCard;
