import { configureStore } from '@reduxjs/toolkit';
import publishSettingsSlice from './publishSettingSlice';
import { webrtcPublishSlice } from './webrtcPublishSlice';

export const store = configureStore({
  reducer: {
    publishSettings: publishSettingsSlice,
    webrtcPublish: webrtcPublishSlice,
  },
});
