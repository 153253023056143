import * as React from 'react';

function LeftArrowIcon(props) {
  return (
    <svg
      width={28}
      height={29}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.165 7.718L4.083 14.8l7.082 7.081M14.957 14.8H4.083M23.718 14.8h-4.06"
        stroke="#111"
        strokeWidth={1.75}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LeftArrowIcon;
