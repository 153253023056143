import * as React from 'react';

function PlusIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={32} height={32} rx={16} fill="#FDCC0A" />
      <path
        d="M16 20.8v-9.6M19.2 16h1.6M11.2 16h4.528M16 20.8v-9.6"
        stroke="#191919"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusIcon;
