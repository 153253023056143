import React, { useEffect, useRef } from 'react';
import { hours, minutes } from './calendar';
import Stack from '@mui/material/Stack';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

const SelectTime = ({ dateAndTime, setDateAndTime }) => {
  const { options } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: { ...allTimezones, 'Asia/Kolkata': 'Asia/Calcutta' },
  });
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const timezoneRef = useRef(null);

  useEffect(() => {
    const hoursIndex = hours.findIndex((hour) => parseInt(hour) === dateAndTime.date.hour());
    hoursRef?.current?.swiper?.slideTo(hoursIndex);
    const minutesIndex = minutes.findIndex((minute) => parseInt(minute) === dateAndTime.date.minute());
    minutesRef?.current?.swiper?.slideTo(minutesIndex);

    if (dateAndTime.timeZone) {
      const timezoneIndex = options.findIndex(
        (option) => option.label.includes(dateAndTime.timeZone) || option.value.includes(dateAndTime.timeZone),
      );
      timezoneRef?.current?.swiper?.slideTo(timezoneIndex);
    } else {
      const timezoneIndex = options.findIndex((option) =>
        option.label.includes(Intl.DateTimeFormat().resolvedOptions().timeZone),
      );
      timezoneRef?.current?.swiper?.slideTo(timezoneIndex);
      setDateAndTime((prev) => ({
        ...prev,
        timeZone: options[timezoneIndex].value,
      }));
    }
  }, []);
  useEffect(() => {
    const hourElement = hoursRef?.current;
    const minuteElement = minutesRef?.current;
    const timeZoneElement = timezoneRef?.current;

    function setHour(e) {
      const [swiper] = e.detail;
      const hour = parseInt(hours[swiper.activeIndex]);
      setDateAndTime((prev) => ({
        ...prev,
        date: prev.date.set('hour', hour),
      }));
    }

    function setMinute(e) {
      const [swiper] = e.detail;
      const minute = parseInt(minutes[swiper.activeIndex]);
      setDateAndTime((prev) => ({
        ...prev,
        date: prev.date.set('minute', minute),
      }));
    }

    function setTimeZone(e) {
      const [swiper] = e.detail;
      const timeZone = options[swiper.activeIndex].value;
      console.log(timeZone);
      setDateAndTime((prev) => ({
        ...prev,
        timeZone,
      }));
    }

    hourElement?.addEventListener('swiperslidechange', setHour);
    minuteElement?.addEventListener('swiperslidechange', setMinute);
    timeZoneElement?.addEventListener('swiperslidechange', setTimeZone);
    return () => {
      hourElement.removeEventListener('swiperslidechange', setHour);
      minuteElement.removeEventListener('swiperslidechange', setMinute);
      timeZoneElement.removeEventListener('swiperslidechange', setTimeZone);
    };
  }, [options, setDateAndTime]);
  return (
    <Stack direction='row' spacing={2} mt={1}>
      <swiper-container
        class='swiper-container'
        ref={hoursRef}
        direction='vertical'
        slides-per-view={3}
        mousewheel={true}
        centered-slides='true'
      >
        {hours.map((hr) => (
          <swiper-slide class='swiper-slide' key={hr}>
            {hr}
          </swiper-slide>
        ))}
      </swiper-container>
      <swiper-container
        class='swiper-container'
        ref={minutesRef}
        direction='vertical'
        slides-per-view={3}
        mousewheel={true}
        centered-slides='true'
      >
        {minutes.map((min) => (
          <swiper-slide class='swiper-slide' key={min}>
            {min}
          </swiper-slide>
        ))}
      </swiper-container>
      <swiper-container
        class='swiper-container'
        ref={timezoneRef}
        direction='vertical'
        slides-per-view={3}
        mousewheel={true}
        centered-slides='true'
      >
        {options.map((timezone) => (
          <swiper-slide class='swiper-slide' key={timezone.value}>
            {timezone.label.substring(timezone.label.indexOf('(') + 1, timezone.label.indexOf(')'))}
          </swiper-slide>
        ))}
      </swiper-container>
    </Stack>
  );
};

export default SelectTime;
