import * as React from 'react';

function ShareIcon(props) {
  return (
    <svg width={18} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.379 3.253l-2.112-1.81c-.35-.3-.525-.45-.673-.454a.417.417 0 00-.334.154c-.093.115-.093.345-.093.806v3.58a8.055 8.055 0 00-6.667 7.93v.51a9.5 9.5 0 016.667-3.41v3.492c0 .46 0 .691.093.806.082.1.205.157.334.154.148-.004.323-.154.673-.454l7.06-6.05c.203-.175.304-.262.342-.366a.417.417 0 000-.282c-.038-.104-.14-.19-.343-.365L13.5 5'
        stroke={props.variant === 'dark' ? '#000' : '#fff'}
        strokeWidth={1.25}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ShareIcon;
