import * as React from 'react';

function LogoBlackIcon(props) {
  return (
    <svg
      width={198}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.174 28.256c0 4.477-3.636 8.112-8.13 8.112-4.494 0-8.13-3.635-8.13-8.112s3.636-8.113 8.13-8.113c4.494 0 8.13 3.636 8.13 8.113zM148.043 28.256c0 4.477-3.636 8.112-8.13 8.112-4.494 0-8.13-3.635-8.13-8.112s3.636-8.113 8.13-8.113c4.494 0 8.13 3.636 8.13 8.113z"
        stroke="#000"
        strokeWidth={4.143}
      />
      <path
        fill="#000"
        d="M56.842 8.511h4.164v20.783h-4.164zM129.711 28.048h4.164V48h-4.164z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.993 18.072h4.164v10.391h-.017c.11 3.235 2.772 5.834 6.055 5.834s5.946-2.6 6.055-5.833h-.017V18.072h4.164v10.392h-.002c-.111 5.528-4.635 9.976-10.2 9.976-5.565 0-10.089-4.448-10.2-9.976h-.002V18.072zM125.547 38.44h-4.164V28.048h.018c-.11-3.234-2.772-5.833-6.055-5.833s-5.946 2.6-6.056 5.833h.018V38.44h-4.164V28.048h.002c.111-5.528 4.635-9.976 10.2-9.976 5.564 0 10.088 4.448 10.199 9.976h.002V38.44zM157.592 28.463h.018V18.072h-4.164v10.392h.002c.11 5.528 4.635 9.976 10.199 9.976 5.565 0 10.089-4.448 10.2-9.977h.002V18.072h-4.164v10.391h.018c-.11 3.234-2.773 5.834-6.056 5.834s-5.945-2.6-6.055-5.834zM193.854 28.048h-.018V38.44H198V28.048h-.002c-.111-5.528-4.635-9.976-10.2-9.976-5.564 0-10.088 4.448-10.199 9.976h-.002V38.44h4.164V28.048h-.018c.11-3.234 2.772-5.833 6.055-5.833s5.946 2.6 6.056 5.833z"
        fill="#000"
      />
      <rect width={47.842} height={47.759} rx={11.657} fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.387 17.466c0-2.696 2.214-4.907 4.978-4.907s4.977 2.212 4.977 4.907a4.841 4.841 0 01-1.18 3.172l2.365 1.401c1.163-1.35 1.66-2.655 1.66-4.573 0-4.296-3.517-7.752-7.822-7.752-4.25 0-7.73 3.367-7.82 7.585h-.002v15.343c0 3.33 3.646 5.377 6.489 3.641l8.292-5.06c2.725-1.663 2.725-5.62 0-7.284l-8.292-5.06a4.208 4.208 0 00-3.645-.382v-1.031zm10.455 11.329a1.422 1.422 0 000-2.428l-8.292-5.06a1.422 1.422 0 00-2.163 1.213v10.122a1.422 1.422 0 002.163 1.214l8.292-5.061z"
        fill="#fff"
      />
    </svg>
  );
}

export default LogoBlackIcon;
