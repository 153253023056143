import React, { useEffect, useState } from 'react';
import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea';
import CustomReactSelect from '../../components/CustomReactSelect/CustomReactSelect';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomRadioButton from '../../components/CustomRadioButton/CustomRadioButton';
import { useForm, useWatch } from 'react-hook-form';
import useSetting from '../../hooks/useSetting';
import Loading from '../../components/Loading/Loading';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomCalendar from '../../components/CustomCalendar/CustomCalendar';
import CustomDateTimePicker from '../../components/CustomDateTimePicker/CustomDateTimePicker';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

const visibilityOptions = [
  {
    value: 'PUBLIC',
    title: 'Public',
    subtitle: 'Make the stream public and let everyone join',
  },
  {
    value: 'PRIVATE',
    title: 'Private',
    subtitle: 'Make the stream private and only for your viewing',
  },
];

const RequestVideoForm = ({ onSubmit, deadline, setDeadline }) => {
  const [open, setOpen] = useState(false);
  const [deadlineError, setDeadlineError] = useState({ error: false, message: '' });
  const [defaultValues, setDefaultValues] = useState({
    visible: 'PUBLIC',
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });
  const { search } = useLocation();
  const requestFormType = new URLSearchParams(search).get('type');
  useEffect(() => {
    const previousFormValue = JSON.parse(localStorage.getItem('requestVideoForm'));
    if (requestFormType === 'continue') {
      setDefaultValues(previousFormValue);
      setDeadline({
        date: previousFormValue?.deadline?.date ? dayjs(previousFormValue?.deadline?.date) : null,
        timeZone: previousFormValue?.deadline?.timeZone || '',
      });
      reset(previousFormValue);
    } else if (requestFormType === 'startAgain') {
      localStorage.removeItem('requestVideoForm');
      setDefaultValues({
        visible: 'PUBLIC',
      });
      setDeadline({
        date: null,
        timeZone: '',
      });
    }
  }, [requestFormType, reset, setDeadline]);
  // console.log(defaultValues);

  const [watchTitle, watchDescription, watchDuration, watchAmount, watchVisible] = useWatch({
    control,
    name: ['title', 'description', 'duration', 'amount', 'visible'],
  });

  useEffect(() => {
    const previousFormValue = JSON.parse(localStorage.getItem('requestVideoForm'));
    localStorage.setItem(
      'requestVideoForm',
      JSON.stringify({
        title: watchTitle || previousFormValue?.title,
        description: watchDescription || previousFormValue?.description,
        duration: watchDuration || previousFormValue?.duration,
        amount: watchAmount || previousFormValue?.amount,
        deadline: deadline?.date === null ? previousFormValue?.deadline : deadline,
      }),
    );
  }, [deadline, watchAmount, watchDescription, watchDuration, watchTitle, watchVisible]);
  const { data: setting, isLoading } = useSetting();
  const streamingDurationMinProposedAmount = setting?.data?.streamingDurationMinProposedAmount;
  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) return <Loading />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={1}>
          <Typography variant={'h3_dark'}>Request a Stream</Typography>
          <CustomTextField
            name={'title'}
            label={'Enter Subject'}
            control={control}
            rules={{ required: 'Heading is required' }}
            type={'text'}
          />
          <CustomTextArea
            name={'description'}
            label={'Enter Description'}
            control={control}
            rules={{ required: 'Description is required' }}
            type={'text'}
          />
          <CustomReactSelect
            name={'duration'}
            options={streamingDurationMinProposedAmount}
            control={control}
            rules={{ required: 'Duration is required' }}
            placeholder={'Streaming Duration'}
            isMulti={false}
            closeMenuOnSelect={true}
          />
          <CustomNumberField
            name={'amount'}
            label={'Proposed Amount'}
            control={control}
            rules={{
              required: 'Amount is required',
              min: {
                value: watchDuration?.minCost || 5,
                message: `Amount can not be less then $${watchDuration?.minCost || 5}`,
              },
              max: {
                value: 9000,
                message: 'Amount can not be greater then $9000',
              },
            }}
            startAdornment={
              <InputAdornment position='start'>
                <AttachMoneyIcon />
              </InputAdornment>
            }
          />
          <CustomDateTimePicker
            rules={{ required: 'Deadline is required' }}
            onClick={() => setOpen(true)}
            value={deadline?.date}
            error={deadlineError}
            placeholder='Deadline'
          />
          <CustomRadioButton name={'visible'} control={control} options={visibilityOptions} />
          <Button
            variant='contained'
            size='large'
            type='submit'
            onClick={() => {
              if (deadline?.date === null) {
                setDeadlineError({ error: true, message: 'Deadline is required' });
              } else {
                setDeadlineError({ error: false, message: '' });
              }
            }}
          >
            Continue
          </Button>
        </Stack>
      </form>
      <CustomDialog open={open} handleClose={handleClose} maxWidth={'xs'} fullWidth>
        <CustomCalendar dateAndTime={deadline} setDateAndTime={setDeadline} handleClose={handleClose} />
      </CustomDialog>
    </>
  );
};

export default RequestVideoForm;
