import api from '../config/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const sendLike = async (taskId) => {
  const { data } = await api.post(`/api/user/task/${taskId}/like`);
  return data;
};

const useSendLike = (taskId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendLike,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['task', taskId] });
      const previousTask = queryClient.getQueryData(['task', taskId]);
      queryClient.setQueriesData({ queryKey: ['task', taskId] }, (oldTask) => {
        return { ...oldTask, likes: oldTask.likes + 1 };
      });

      return { previousTask };
    },
    onError: (error, variables, context) => {
      // setIsLiked(false);
      queryClient.setQueryData(['task', taskId], context?.previousTask);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['task', taskId] });
      await queryClient.invalidateQueries({ queryKey: ['isUserLiked', taskId] });
    },
  });
};

export default useSendLike;
