import React from 'react';
import api from '../../../config/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import AcceptedTaskCard from './AcceptedTaskCard';
import Loading from '../../../components/Loading/Loading';
import { TASK_CARD_HEIGHT } from '../../../utils/constant';
import useInfiniteQueryReducedResult from '../../../utils/useInfiniteQueryReducedResult';

const AcceptedTaskComponent = ({ setAcceptedTaskDetails }) => {
  const getAcceptedTasks = async ({ pageParam }) => {
    const { data } = await api.get(`/api/user/profile/proposal?filter=accepted&page=${pageParam}&size=10`);
    setAcceptedTaskDetails((prev) => ({ ...prev, height: data.data.totalCount * TASK_CARD_HEIGHT }));
    return data;
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['acceptedTasks'],
    queryFn: getAcceptedTasks,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      const fetchedData = allPages.length * 10;
      const totalData = lastPage.data.totalCount;
      return fetchedData < totalData ? allPages.length + 1 : undefined;
    },
  });
  const acceptedTasks = useInfiniteQueryReducedResult(data);
  if (isLoading) return <Loading />;
  return (
    <Box width='100%'>
      {acceptedTasks
        ? acceptedTasks?.map((task, index) => (
            <AcceptedTaskCard
              key={task?._id}
              data={task}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              index={index}
              totalCount={acceptedTasks?.length}
            />
          ))
        : null}
    </Box>
  );
};

export default AcceptedTaskComponent;
