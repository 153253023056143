import React from 'react';
import { Button, Rating, Stack, Typography } from '@mui/material';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import StarFilled from '../../assets/icons/StarFilled';
import StarEmpty from '../../assets/icons/StarEmpty';
import { useForm } from 'react-hook-form';
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea';
import api from '../../config/api';
import { toast } from 'react-toastify';

const RatingModal = ({ open, handleClose, title, taskId, type = 'requester' }) => {
  const [value, setValue] = React.useState(0);
  const { control, handleSubmit } = useForm();

  const submitRating = async (data) => {
    const { comment } = data;
    try {
      await api.post(`/api/user/task/${taskId}/rating/${type}`, { comment, rating: value });
      toast.success('Rating submitted successfully');
    } catch (e) {
      toast.error('Something went wrong');
      return;
    }
    handleClose();
  };
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submitRating)}>
        <Stack spacing={3} alignItems={'center'} textAlign='center'>
          <Typography variant='h5_dark'>{title}</Typography>
          <Rating
            name='simple-controlled'
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            icon={<StarFilled />}
            emptyIcon={<StarEmpty />}
          />
          <CustomTextArea control={control} name='comment' label='Drop your comments here!' />
          <Button variant='contained' size='large' type='submit'>
            Happy bunpun!
          </Button>
        </Stack>
      </form>
    </CustomDialog>
  );
};

export default RatingModal;
