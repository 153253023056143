// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: "Urbanist", sans-serif !important;
}

html {
    height: 100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* This is for bottom drawer   */
    padding-right: 0 !important;

}
.swiper-pagination-bullet-active {
    color: #fff;
    background: #fae80a !important;
}
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active{
    background: #fae80a !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;AAClD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,mCAAmC;IACnC,kCAAkC;IAClC,gCAAgC;IAChC,2BAA2B;;AAE/B;AACA;IACI,WAAW;IACX,8BAA8B;AAClC;AACA;;IAEI,8BAA8B;AAClC","sourcesContent":["* {\n    font-family: \"Urbanist\", sans-serif !important;\n}\n\nhtml {\n    height: 100%;\n}\n\nbody {\n    margin: 0;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    /* This is for bottom drawer   */\n    padding-right: 0 !important;\n\n}\n.swiper-pagination-bullet-active {\n    color: #fff;\n    background: #fae80a !important;\n}\n.swiper-horizontal>.swiper-pagination-bullets,\n.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet-active{\n    background: #fae80a !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
