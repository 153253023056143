import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@mui/material';

const CustomCheckbox = ({ name, control, rules, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <Checkbox checked={value || false} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />
      )}
    />
  );
};

export default CustomCheckbox;
