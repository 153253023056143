import { Box } from '@mui/material';
import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import TopRequestComponent from '../TopRequestsPage/TopRequestComponent';
import TopStreamersComponent from '../TopStreamersPage/TopStreamersComponent';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomTab from '../../components/CustomTab/CustomTab';
import debounceFun from '../../utils/debounceFun';
import { useParams } from 'react-router-dom';

const DiscoverPage = () => {
  const [topRequestComponentHeight, setTopRequestComponentHeight] = React.useState(0);
  const [topStreamersComponentHeight, setTopStreamersComponentHeight] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState({ index: 0, query: '' });

  const { discoverType } = useParams();

  const handleSearchQuery = (value) => {
    setSearchQuery(value);
  };
  const optimisedSearchQuery = debounceFun(handleSearchQuery, 500);

  const tabs = [
    {
      label: 'Top Streamers',
      index: 1,
      height: topStreamersComponentHeight,
      panel: (
        <TopStreamersComponent
          setTopStreamersComponentHeight={setTopStreamersComponentHeight}
          searchQuery={searchQuery}
        />
      ),
    },
    {
      label: 'Top Requests',
      index: 0,
      height: topRequestComponentHeight,
      panel: (
        <TopRequestComponent setTopRequestComponentHeight={setTopRequestComponentHeight} searchQuery={searchQuery} />
      ),
    },
  ];
  return (
    <Box sx={{ position: 'relative', pb: 8 }}>
      <TopNavigation />
      <TopNavigationSpace />
      <CustomTab tabs={tabs} setSearchQuery={optimisedSearchQuery} initialActiveTab={parseInt(discoverType)} />
    </Box>
  );
};

export default DiscoverPage;
