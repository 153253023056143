import React from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import { Box, IconButton, Typography } from '@mui/material';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import Button from '@mui/material/Button';
import AddBankIcon from '../../assets/icons/AddBankIcon';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import useGetBanksDetails from '../../hooks/useGetBanksDetails';
import Loading from '../../components/Loading/Loading';
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon';
import Divider from '@mui/material/Divider';

const WalletManagePage = () => {
  const navigate = useNavigate();
  const { data: banks, isLoading } = useGetBanksDetails();
  const bank = banks.data[0];
  const len = bank?.accNo.toString().length;
  const maskedAccNo = 'X'.repeat(len - 4) + bank?.accNo.toString().slice(-4);
  if (isLoading) return <Loading />;
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <TopNavigation title='Manage' />
        <TopNavigationSpace />
        <Stack spacing={2}>
          <MenuHeader title={'Saved Bank Accounts'} />
          {bank ? (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: '12px',
                p: '16px 20px',
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={1.5}>
                <Typography variant='h6_bold'>{bank?.bankName}</Typography>
                <Divider orientation='vertical' sx={{ width: '1px', height: '13px', bgcolor: '#000' }} />
                <Typography variant='h6'>{maskedAccNo}</Typography>
              </Box>
              <IconButton onClick={() => navigate(ROUTES.EDIT_BANK_ACCOUNT.replace(':id', bank._id))}>
                <ShowPasswordIcon width={16} height={16} />
              </IconButton>
            </Box>
          ) : (
            <Button
              variant='contained'
              size='large'
              rounded
              endIcon={<AddBankIcon />}
              onClick={() => navigate(ROUTES.ADD_BANK_ACCOUNT.replace(':amount', null))}
            >
              Add Bank Account
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default WalletManagePage;
