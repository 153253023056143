import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import EmailVerificationImage from '../../assets/images/EmailVerificationImage';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import saveDataToDatabase from '../../utils/saveDataToDatabase';
import { toast } from 'react-toastify';
import { isNewUser, userDetails } from '../../store';
import api from '../../config/api';
import { useMutation } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import ROUTES from '../../routes';
import useSendOTP from '../../hooks/useSendOTP';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';
import SuccessModal from '../../components/SuccessModal/SuccessModal';

const verifyOTP = async ({ email, otp }) => {
  const { data } = await api.post(`/api/public/verification`, {
    email,
    otp,
  });
  return data;
};

const EmailVerificationPage = (props) => {
  const [openEmailVerificationModal, setOpenEmailVerificationModal] = React.useState(false);
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  console.log(userDetails.value);

  const { refetch, isSuccess } = useSendOTP({
    email: userDetails.value?.email,
    name: userDetails.value?.name,
  });
  useEffect(() => {
    if (userDetails.value === null) navigate(ROUTES.REGISTER);
    if (isSuccess) toast.success('Verification code has been sent to your email');
  }, [isSuccess, navigate]);
  console.log(isSuccess);

  const { mutate: verifyOTPMutate, isPending } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: async (data) => {
      // toast.success('OTP verified successfully');
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          userDetails.value.email,
          userDetails.value.password,
        );
        const { uid } = userCredential.user;
        userDetails.value.uid = uid;
        const token = await auth.currentUser.getIdToken();
        localStorage.setItem('token', token);
        localStorage.setItem('isNewUser', 'false');
        await saveDataToDatabase({ ...userDetails.value });
        setOpenEmailVerificationModal(true);
        // navigate(ROUTES.WELCOME);
      } catch (error) {
        toast.error('Email already exists');
        console.log(error);
      }
    },
    onError: (error) => {
      toast.error('Invalid OTP');
      console.log(error);
    },
  });
  const handleEmailVerification = async (value) => {
    verifyOTPMutate({ email: userDetails.value.email, otp: value.code });
  };

  if (isPending) return <Loading />;
  return (
    <>
      <Box pt={6} sx={{ position: 'relative', height: '100dvh', width: '100%' }}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <EmailVerificationImage />
        </Box>
        <Stack spacing={1}>
          <Typography variant='h3_dark' align={'center'}>
            Check your email
          </Typography>
          <Typography variant='h5' align={'center'}>
            We've sent the OTP on your registered email id
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(handleEmailVerification)}>
          <Stack spacing={3} sx={{ mt: 3, textAlign: 'center' }}>
            <CustomNumberField
              name={'code'}
              label={'Enter Code'}
              control={control}
              rules={{ required: 'Code is required' }}
            />
            <Button variant='contained' type='submit' size='large'>
              Next
            </Button>
            <Typography
              variant={'h5_bold'}
              onClick={() => refetch({ email: userDetails.value?.email, name: userDetails.value?.name })}
              textAlign={'center'}
              sx={{ cursor: 'pointer' }}
            >
              Resend Code
            </Typography>
          </Stack>
        </form>
        <Typography
          variant={'h5_dark'}
          component={Link}
          to={ROUTES.LOGIN}
          sx={{ position: 'absolute', bottom: 40, width: '100%' }}
          textAlign={'center'}
        >
          Back to log in
        </Typography>
      </Box>
      <SuccessModal
        open={openEmailVerificationModal}
        handleClose={() => setOpenEmailVerificationModal(false)}
        onClick={() => {
          setOpenEmailVerificationModal(false);
          isNewUser.value = true;
          navigate(ROUTES.REFERRAL_CODE_INPUT);
        }}
        title={'Success!'}
        message={'Your email has been verified successfully!'}
        btnText={'Continue'}
      />
    </>
  );
};

export default EmailVerificationPage;
