import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import CustomDialog from '../CustomDialog/CustomDialog';
import WarningIcon from '../../assets/icons/WarningIcon';

const WarningModal = ({ open = false, handleClose, message }) => {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <Stack spacing={3} alignItems={'center'}>
        <WarningIcon />
        <Typography variant='h6'>{message}</Typography>
        <Button variant='contained' sx={{ width: '70%' }} onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default WarningModal;
