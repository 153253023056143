import * as React from 'react';

function InsufficientWalletIcon(props) {
  return (
    <svg width={64} height={64} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        opacity={0.4}
        d='M51.947 34.533h5.386v-3.84c0-5.52-4.506-10.027-10.026-10.027H16.693c-5.52 0-10.026 4.507-10.026 10.027V48.64c0 5.52 4.506 10.026 10.026 10.026h30.614c5.52 0 10.026-4.506 10.026-10.026v-3.173h-5.066c-2.88 0-5.52-2.107-5.76-4.987a5.356 5.356 0 011.6-4.347 5.315 5.315 0 013.84-1.6zM17.6 54.88a2.066 2.066 0 01-2.827 0l-1.413-1.413-1.467 1.466c-.4.4-.906.587-1.413.587a1.978 1.978 0 01-1.413-.587 2.012 2.012 0 010-2.827l1.466-1.466-1.413-1.413a2.012 2.012 0 010-2.827 2.045 2.045 0 012.853 0l1.387 1.413 1.333-1.36a2.045 2.045 0 012.854 0c.773.8.773 2.053 0 2.853l-1.36 1.334 1.413 1.386c.773.8.773 2.054 0 2.854z'
        fill='#FDCC0A'
      />
      <path
        d='M39.6 10.534v10.133H16.693c-5.52 0-10.026 4.507-10.026 10.027v-9.787c0-3.173 1.946-6 4.906-7.12l21.174-8c3.306-1.227 6.853 1.2 6.853 4.747zM60.16 37.254v5.493c0 1.467-1.173 2.667-2.667 2.72h-5.226c-2.88 0-5.52-2.107-5.76-4.987a5.356 5.356 0 011.6-4.346 5.315 5.315 0 013.84-1.6h5.546c1.494.053 2.667 1.253 2.667 2.72zM37.333 34H18.667c-1.094 0-2-.907-2-2s.906-2 2-2h18.666c1.094 0 2 .907 2 2s-.906 2-2 2zM13.333 40c-2.506 0-4.826.88-6.666 2.347-2.454 1.946-4 4.96-4 8.32 0 2 .56 3.893 1.546 5.493a10.586 10.586 0 009.12 5.173c2.694 0 5.147-.986 7.014-2.666a9.583 9.583 0 002.106-2.507A10.455 10.455 0 0024 50.667C24 44.773 19.227 40 13.333 40zM9.12 49.227a2.012 2.012 0 010-2.827 2.045 2.045 0 012.853 0l1.387 1.413 1.333-1.36a2.045 2.045 0 012.854 0c.773.8.773 2.054 0 2.854l-1.36 1.333 1.413 1.387c.773.8.773 2.053 0 2.853a2.065 2.065 0 01-2.827 0l-1.413-1.413-1.467 1.466c-.4.4-.906.587-1.413.587a1.978 1.978 0 01-1.413-.587 2.012 2.012 0 010-2.826l1.466-1.467-1.413-1.413z'
        fill='#FDCC0A'
      />
    </svg>
  );
}

export default InsufficientWalletIcon;
