// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bp-logo {
  width: 64px;
  height: 64px;
}

.bp-or {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".bp-logo {\n  width: 64px;\n  height: 64px;\n}\n\n.bp-or {\n  font-weight: 500;\n  font-size: 15px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
