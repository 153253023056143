import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Routes from '../../routes';
import { useForm } from 'react-hook-form';
import ForgetPasswordCodeVerificationImage from '../../assets/images/ForgetPasswordCodeVerificationImage';
import api from '../../config/api';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import Loading from '../../components/Loading/Loading';
import useSendOTP from '../../hooks/useSendOTP';
import { userDetails } from '../../store';
import CustomNumberField from '../../components/CustomNumberField/CustomNumberField';

const verifyOTP = async ({ email, otp }) => {
  const { data } = await api.post(`/api/public/verification`, {
    email,
    otp,
  });
  return data;
};

const ForgetPasswordCodeVerificationPage = (props) => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { handleSubmit, control } = useForm();
  const { refetch, isSuccess } = useSendOTP({ email });
  useEffect(() => {
    if (isSuccess) toast.success('Verification code has been sent to your email');
  }, [isSuccess]);

  const { mutate: verifyOTPMutate, isPending } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: (data) => {
      toast.success('OTP verified successfully');
      navigate(Routes.RESET_PASSWORD.replace(':token', data.data.token));
    },
    onError: (error) => {
      toast.error('Invalid OTP');
    },
  });
  const handlePasswordReset = async (value) => {
    verifyOTPMutate({ email, otp: value.code });
  };
  if (isPending) return <Loading />;
  return (
    <Box pt={6} sx={{ position: 'relative', height: '100dvh', width: '100%' }}>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <ForgetPasswordCodeVerificationImage />
      </Box>
      <Stack spacing={1}>
        <Typography variant={'h3_dark'} align={'center'}>
          Enter 6 Digits Code
        </Typography>
        <Typography variant={'h5'} align={'center'}>
          Enter the 4 digits code that you received on your email
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(handlePasswordReset)}>
        <Stack spacing={3} sx={{ mt: 3, textAlign: 'center' }}>
          <CustomNumberField
            name={'code'}
            label={'Enter Code'}
            control={control}
            rules={{ required: 'Code is required' }}
          />
          <Button variant='contained' size='large' type='submit'>
            Next
          </Button>
          <Typography
            variant={'h5_bold'}
            textAlign={'center'}
            onClick={() => refetch({ email: userDetails.value?.email, name: userDetails.value?.name })}
            sx={{ cursor: 'pointer' }}
          >
            Resent Code
          </Typography>
        </Stack>
      </form>
      <Typography
        variant={'h5_dark'}
        component={Link}
        to={Routes.LOGIN}
        sx={{ position: 'absolute', bottom: 40, width: '100%' }}
        textAlign={'center'}
      >
        Back to log in
      </Typography>
    </Box>
  );
};

export default ForgetPasswordCodeVerificationPage;
