import * as React from 'react';

const StarEmpty = (props) => (
  <svg width={42} height={42} viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.0454 28C10.2379 27.1425 9.88786 25.9175 9.27536 25.305L5.02286 21.0525C3.69286 19.7225 3.16786 18.305 3.55286 17.08C3.95536 15.855 5.19786 15.015 7.05286 14.7L12.5129 13.79C13.3004 13.65 14.2629 12.95 14.6304 12.2325L17.6404 6.195C18.5154 4.4625 19.7054 3.5 21.0004 3.5C22.2954 3.5 23.4854 4.4625 24.3604 6.195L27.3704 12.2325C27.5979 12.6875 28.0704 13.125 28.5779 13.4225L9.73036 32.27C9.48536 32.515 9.06536 32.2875 9.13536 31.9375L10.0454 28Z'
      fill='#A9ABAD'
      fillOpacity={0.3}
    />
    <path
      d='M32.7254 25.3043C32.0954 25.9343 31.7454 27.1418 31.9554 27.9993L33.1629 33.2668C33.6704 35.4543 33.3554 37.0993 32.2704 37.8868C31.8329 38.2018 31.3079 38.3593 30.6954 38.3593C29.8029 38.3593 28.7529 38.0268 27.5979 37.3443L22.4704 34.2993C21.6654 33.8268 20.3354 33.8268 19.5304 34.2993L14.4029 37.3443C12.4604 38.4818 10.7979 38.6743 9.73039 37.8868C9.32789 37.5893 9.03039 37.1868 8.83789 36.6618L30.1179 15.3818C30.9229 14.5768 32.0604 14.2093 33.1629 14.4018L34.9304 14.6993C36.7854 15.0143 38.0279 15.8543 38.4304 17.0793C38.8154 18.3043 38.2904 19.7218 36.9604 21.0518L32.7254 25.3043Z'
      fill='#A9ABAD'
      fillOpacity={0.3}
    />
  </svg>
);
export default StarEmpty;
