import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentCheckout = ({ clientSecret, amount, callback, redirectUrl, handleClose }) => {
  const appearance = {
    theme: 'stripe',
    variables: { colorPrimary: '#fdcc0a', colorDanger: '#FF706C', borderRadius: '8px' },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance,
  };

  console.log(clientSecret);

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm amount={amount} callback={callback} redirectUrl={redirectUrl} handleClose={handleClose} />
        </Elements>
      )}
    </>
  );
};

PaymentCheckout.propTypes = {};

export default PaymentCheckout;
