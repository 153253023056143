import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const sendOTP = async ({ email, name }) => {
  let response = null;
  if (name) {
    response = await api.get(`/api/public/verification?email=${email}&name=${name}`);
  } else {
    response = await api.get(`/api/public/verification?email=${email}&type=resetPassword`);
  }
  return response?.data;
};

const useSendOTP = ({ email, name }) => {
  return useQuery({
    queryKey: ['sendOTP', email, name],
    queryFn: () => sendOTP({ email, name }),
    enabled: false,
    refetchOnWindowFocus: false,
  });
};

export default useSendOTP;
