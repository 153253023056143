import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import NoLiveStreamSvg from '../../../../assets/images/NoLiveStreamSvg';
import { useNavigate } from 'react-router-dom';

const NoLiveStreamCard = () => {
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1.5}
      sx={{
        borderRadius: '20px',
        border: (theme) => `1px solid ${theme.palette.border.main}`,
        p: '20px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ width: '160px', height: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <NoLiveStreamSvg />
      </Box>

      <Typography variant='h5_dark'>No live stream happening</Typography>
      {/*<Button*/}
      {/*  variant='contained'*/}
      {/*  size='large'*/}
      {/*  sx={{ borderRadius: 50, width: '140px' }}*/}
      {/*  onClick={() => navigate(ROUTES.LIVE_FORM)}*/}
      {/*>*/}
      {/*  Go Live*/}
      {/*</Button>*/}
    </Stack>
  );
};

export default NoLiveStreamCard;
