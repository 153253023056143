import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TopNavigation, { TopNavigationSpace } from '../../components/TopNavigation/TopNavigation';
import CustomTab from '../../components/CustomTab/CustomTab';
import PendingRequestComponent from './PendingRequest/PendingRequestComponent';
import AcceptedRequestComponent from './AcceptedRequest/AcceptedRequestComponent';
import CompletedRequestComponent from './CompletedRequest/CompletedRequestComponent';
import { useParams } from 'react-router-dom';
import { MY_REQUEST_TYPE } from '../../utils/constant';
import useGetActivity from '../../hooks/useGetActivity';
import Loading from '../../components/Loading/Loading';

const MyRequestPage = () => {
  const [acceptedRequestDetails, setAcceptedRequestDetails] = useState({ height: 0, totalCount: 0 });
  const [pendingRequestDetails, setPendingRequestDetails] = useState({ height: 0, totalCount: 0 });
  const [completedRequestDetails, setCompletedRequestDetails] = useState({ height: 0, totalCount: 0 });

  const { requestType } = useParams();
  function getInitialActiveTab() {
    switch (requestType) {
      case MY_REQUEST_TYPE.PENDING:
        return 0;
      case MY_REQUEST_TYPE.ACCEPTED:
        return 1;
      default:
        return 2;
    }
  }
  const { data: activity, isLoading } = useGetActivity();
  useEffect(() => {
    const taskActivity = activity?.data?.myRequest;
    const pending = taskActivity?.pending;
    const accepted = taskActivity?.accepted;
    setAcceptedRequestDetails((prev) => ({ ...prev, totalCount: accepted }));
    setPendingRequestDetails((prev) => ({ ...prev, totalCount: pending }));
  }, [activity?.data?.myRequest]);
  if (isLoading) return <Loading />;

  const tabs = [
    {
      label: 'Pending',
      index: 0,
      height: pendingRequestDetails.height,
      totalCount: pendingRequestDetails.totalCount,
      panel: <PendingRequestComponent setPendingRequestDetails={setPendingRequestDetails} />,
    },
    {
      label: 'Accepted',
      index: 1,
      height: acceptedRequestDetails.height,
      totalCount: acceptedRequestDetails.totalCount,
      panel: <AcceptedRequestComponent setAcceptedRequestDetails={setAcceptedRequestDetails} />,
    },
    {
      label: 'Completed',
      index: 2,
      height: completedRequestDetails.height,
      totalCount: completedRequestDetails.totalCount,
      panel: <CompletedRequestComponent setCompletedRequestDetails={setCompletedRequestDetails} />,
    },
  ];

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <TopNavigation title={'My Requests'} />
      <TopNavigationSpace />
      <CustomTab tabs={tabs} initialActiveTab={getInitialActiveTab()} />
    </Box>
  );
};

export default MyRequestPage;
