// ADD ALL IMAGES IMPORTS HERE
import BunpunLogo from '../assets/logo.svg';
import LiveShow1 from '../assets/images/live_show_1.png';
import InfluencerPng from '../assets/images/influencer.png';
import LogoTextPng from '../assets/images/logo_text.png';

// ADD ALL ICONS IMPORTS HERE
import ProfileIcon from '../assets/icons/ProfileCircleInactiveIcon';
import NotificationIcon from '../assets/icons/NotificationIcon';
import WalletIcon from '../assets/icons/WalletIcon';
import ArrowRightIcon from '../assets/icons/RightArrowIcon';
import EyeIcon from '../assets/icons/EyeIcon';
import PlayIcon from '../assets/icons/PlayIcon';
import HomeIcon from '../assets/icons/HomeActiveIcon';
import GallaryAdd from '../assets/icons/GalleryAddIcon';

export const GlobalImages = {
  BunpunLogo,
  LiveShow1,
  InfluencerPng,
  LogoTextPng,
};

export const GlobalIcons = {
  ProfileIcon,
  NotificationIcon,
  WalletIcon,
  ArrowRightIcon,
  EyeIcon,
  PlayIcon,
  HomeIcon,
  GallaryAdd,
};
