import api from '../config/api';
import { useQuery } from '@tanstack/react-query';

const getNotificationStatus = async () => {
  const { data } = await api.get('/api/user/notification/status');
  return data;
};

const useGetNotificationStatus = () => {
  return useQuery({
    queryKey: ['notificationStatus'],
    queryFn: getNotificationStatus,
    refetchOnWindowFocus: false,
  });
};

export default useGetNotificationStatus;
