import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const CustomNumberField = ({ name, control, label, rules, startAdornment, endAdornment, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <TextField
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          autoComplete='off'
          sx={{
            // mb: 1,
            '& input': {
              fontSize: '16px',
              fontWeight: 600,
              padding: '14px 20px',
            },
            '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            ...rest,
          }}
          InputProps={{
            style: {
              // color: "hsla(228, 9%, 11%, 0.4)",
              borderRadius: '8px',
              borderColor: rest.borderColor || 'rgba(0, 0, 0, 0.1)',
              color: rest.color || 'black',
            },
            startAdornment,
            endAdornment,
          }}
          variant='outlined'
          placeholder={label}
          type='number'
        />
      )}
    />
  );
};

export default CustomNumberField;
