import * as React from 'react';

function SuccessIcon(props) {
  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M32 58.667c14.727 0 26.666-11.94 26.666-26.667C58.666 17.273 46.727 5.334 32 5.334 17.272 5.334 5.333 17.273 5.333 32c0 14.728 11.94 26.667 26.667 26.667z"
        fill="#FDCC0A"
      />
      <path
        d="M28.215 41.547c-.534 0-1.04-.213-1.413-.587l-7.547-7.546a2.012 2.012 0 010-2.827 2.012 2.012 0 012.826 0l6.134 6.133 13.707-13.706a2.012 2.012 0 012.826 0 2.012 2.012 0 010 2.826l-15.12 15.12c-.373.374-.88.587-1.413.587z"
        fill="#FDCC0A"
      />
    </svg>
  );
}

export default SuccessIcon;
