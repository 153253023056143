import React from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles.css';
import { FormHelperText, InputAdornment, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { PatternFormat } from 'react-number-format';

const FormattedTextField = React.forwardRef(function FormattedTextField(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format='(###) ###-####'
      mask='_'
      allowEmptyFormatting
      valueIsNumericString
    />
  );
});

const PhoneInputComponent = ({ name, control, rules, setCountryData, countryData }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const isIndia = countryData.name === 'India';
        const placeholderText = isIndia ? 'Enter phone number' : 'Optional';
        return (
          <>
            <Stack direction='row' position='relative' className={`input-border ${error ? 'input-error' : ''}`}>
              <PhoneInput
                country='in'
                onChange={(phone, data) => {
                  setCountryData(data);
                  console.log(data);
                }}
                disableCountryCode={false}
                countryCodeEditable={false}
                placeholder={placeholderText}
                enableSearch={true}
              />
              <TextField
                variant='standard'
                size='small'
                onChange={onChange}
                value={value || ''}
                autoComplete='off'
                sx={{
                  height: '1.4375em',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 55,
                  zIndex: 9,
                  '& input': {
                    fontSize: '16px',
                    fontWeight: 600,
                    padding: '14px 20px',
                    paddingLeft: '0px',
                  },
                  '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    borderRadius: '8px',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    color: 'black',
                    border: 'none !important',
                  },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Typography sx={{ fontWeight: 600, color: '#000' }}>+{countryData.dialCode}</Typography>
                    </InputAdornment>
                  ),
                }}
                placeholder={placeholderText}
                type='number'
              />
            </Stack>
            {error && (
              <FormHelperText
                error
                sx={{
                  fontSize: '9px',
                  marginLeft: '14px !important',
                  marginTop: '4px !important',
                }}
              >
                {error.message}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

export default PhoneInputComponent;
